ACC.header = {

    _autoload: [
        'loadDynamicHeader',
    ],

    loadDynamicHeader() {
        const isLogoutLogicFeature = ACC.header.getAEMGlobalProperty('feature_pearsonplus-logout-logic-on') === 'true';

        if (!isLogoutLogicFeature) {
            $.get(`${ACC.config.encodedContextPath}/commerce-header-v2`, (data) => {
                $('#custom-header').html(data);

                if (ACC.config.isUserSignedIn) {
                    ACC.iamActions.bindLogoutAction();
                }
            });
        }
    },

    getAEMGlobalProperty(key) {
        const { globalProperties } = window;
        const GPNode = globalProperties && globalProperties.globalProperties.find((el) => el[key]);

        return GPNode && GPNode[key];
    },
};
