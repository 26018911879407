ACC.upfrontPaymentOptions = {
    _autoload: [
        'selectPaymentOptions',
    ],

    isSelected: 'isSelected',
    screenReadText: 'Subscription details were changed',

    objects: {
        perMonthLabel: document.querySelector('.summary-card__per-month-label'),
    },

    selectPaymentOptions() {
        const paymentOptions = document
            .querySelectorAll('.checkout__payment-option');

        if (!paymentOptions.length) { return; }
        paymentOptions.forEach((option) => {
            if (option.classList.contains('isSelected')) {
                ACC.upfrontPaymentOptions.handlePerMonthVisibility(option);
            }

            option.addEventListener('change', (event) => {
                event.preventDefault();
                paymentOptions.forEach((item) => item.classList.remove(this.isSelected));
                option.classList.add(this.isSelected);
                ACC.upfrontPaymentOptions.addReaderText(option);
                ACC.upfrontPaymentOptions.getCheckoutSubscriptionData(option);
            });
        });

        document.querySelectorAll('.c-radiobtn__input-upfront').forEach((input) => {
            input.addEventListener('blur', () => {
                ACC.upfrontPaymentOptions.removeReaderText(input);
            });
        });
    },

    addReaderText(block) {
        const container = block.querySelector('.screenreader-text-container');
        container.innerHTML = ACC.upfrontPaymentOptions.screenReadText;
    },

    removeReaderText(input) {
        const block = input.closest('.c-radiobtn');
        const container = block.querySelector('.screenreader-text-container');
        container.innerHTML = '';
    },

    getCheckoutSubscriptionData(option) {
        if (option.classList.contains('checkout__payment-option')) {
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/subscription/paymentOption/update`,
                data: {
                    isUpfront: option.dataset.upfrontSubscription,
                    duration: option.dataset.duration,
                    productCode: option.dataset.code,
                },
                dataType: 'json',
            }).done((data) => {
                $('.summary-card__subscription-price').html(data.subTotal.formattedValue);
                ACC.secureacceptance.recalculateOrder();
                ACC.cart.updateLegalText();
                ACC.upfrontPaymentOptions.handlePerMonthVisibility(option);
                ACC.upfrontPaymentOptions.updateDataLayer(data);
                ACC.silentorderpost.updateBillingAddressForChosenSavedCardAndRecalculateOrder();
            });
        }
    },

    updateDataLayer(data) {
        const upfrontPayment = 'upfront payment';
        const monthlyPayment = 'monthly payment';

        const ecommerceCart = dataLayer[0].ecommerce;
        if (ecommerceCart) {
            Object.keys(ecommerceCart).forEach((ecommerceNodeKey) => {
                const ecommerceNode = ecommerceCart[ecommerceNodeKey];
                ecommerceNode.price = data.subTotal.formattedValue;
                if (data.totalDiscounts.value !== 0) {
                    ecommerceNode.discountPrice = data.totalPrice.formattedValue;
                }
                ecommerceNode.pricingMode = ecommerceNode.pricingMode === upfrontPayment
                    ? monthlyPayment : upfrontPayment;
            });
        }
    },

    handlePerMonthVisibility(element) {
        if (!ACC.upfrontPaymentOptions.objects.perMonthLabel) {
            return;
        }

        const isMultiple = element.getAttribute('for').indexOf('multiple-month') !== -1;
        if (isMultiple) {
            ACC.upfrontPaymentOptions.objects.perMonthLabel.classList.add('display-none');
        } else {
            ACC.upfrontPaymentOptions.objects.perMonthLabel.classList.remove('display-none');
        }
    },
};
