ACC.upgradeSubscription = {

    _autoload: [
        'triggerUpgradeSubscriptionModal',
        ['checkUrlParam', document.querySelector('.page-manageSubscriptionLayout')
        && document.getElementById('upgrade-subscription')],
        ['showUpgradeSingleMultiModal', $('#single_multi_upgrade').length !== 0],
    ],

    options: {
        upgradeSubscriptionError: false,
        upgradeSubscriptionUrlParam: 'upgrade_subscription',
    },

    objects: {
        upgradeButton: document.querySelector('.js-upgrade-subscription-button'),
    },

    subscriptionData: {},

    showUpgradeSingleMultiModal() {
        const targetContainer = document.getElementById('popup_single_multi_upgrade');
        const duration = 800;

        setTimeout(() => {
            $modal.open(null, {
                keyboardInteraction: false,
                closeButton: false,
                inline: true,
                href: targetContainer,
            });
        }, duration);
    },

    checkUrlParam() {
        const urlParams = ACC.subscriptionHelpers.getUrlParam();

        if (urlParams && urlParams.has(ACC.upgradeSubscription.options.upgradeSubscriptionUrlParam)) {
            const params = ACC.subscriptionHelpers.getParams(ACC.upgradeSubscription.objects.upgradeButton);
            const { subscriptionCode } = params;

            ACC.upgradeSubscription.showUpgradeSubscriptionModal(subscriptionCode);
        }
    },

    triggerUpgradeSubscriptionModal() {
        $('.js-upgrade-subscription-button').on('click', function (event) {
            const params = ACC.subscriptionHelpers.getParams(this);
            const { subscriptionCode } = params;
            event.preventDefault();

            ACC.upgradeSubscription.showUpgradeSubscriptionModal(subscriptionCode);
        });
    },

    configureCostBreakdownFields(isCostBreakdownAvailable) {
        function setDisplay(selector, shouldBeDisplayed) {
            document.querySelectorAll(selector).forEach((element) => {
                element.style.display = shouldBeDisplayed ? 'block' : 'none';
            });
        }

        function setInline(selector, shouldBeDisplayed) {
            document.querySelectorAll(selector).forEach((element) => {
                element.style.display = shouldBeDisplayed ? 'inline' : 'none';
            });
        }

        setDisplay('.js-cost-break-down-available', isCostBreakdownAvailable);
        setDisplay('.js-cost-break-down-not-available', !isCostBreakdownAvailable);
        setInline('.js-cost-break-down-fallback-text', !isCostBreakdownAvailable);
    },

    configureFieldsForUpfrontSubscription(isUpfrontSubscription) {
        function setDisplay(selector, display) {
            const element = document.querySelector(selector);
            if (!element) {
                return;
            }

            element.style.display = display;
        }

        if (isUpfrontSubscription) {
            setDisplay('#dueTodayCopy', 'none');
            setDisplay('#dueTodayValue', 'none');
            setDisplay('#paymentDetailsCopy', 'none');
            setDisplay('#paymentDetailsValue', 'none');
            setDisplay('#nextBillDate', 'none');
            setDisplay('#nextBillDateValue', 'none');
        }
    },

    loadUpgradePromotion() {
        if (ACC.config.isApplyAutoRenewPromoCodeEnabled
            && ACC.subscriptionHelpers.lastLoadedPromo !== 'UPGRADE') {
            const productCodeValue = $('.js-upgrade-subscription-button').attr('data-mojo-subscription-tier');
            const selectedProductCode = $('.js-upgrade-subscription-button').attr('data-product-code');
            const url = `${ACC.config.encodedContextPath}/voucher/upgradeSubscription/promo/load`;
            const form = { productCode: productCodeValue, selectedProduct: selectedProductCode };
            $.ajax({
                url,
                data: form,
                async: false,
                type: 'POST',
                success(data) {
                    const modalString = $(`#upgradeSubscriptionTemplate_${productCodeValue}`).html();
                    const modalDom = $(new DOMParser().parseFromString(modalString, 'text/html'));
                    modalDom.find(`#upgradeSubscriptionTotals_${productCodeValue}`).html(data);
                    $(`#upgradeSubscriptionTemplate_${productCodeValue}`).html(modalDom.find('body').html());
                    if ($(`#upgradeSubscriptionTotals_${productCodeValue}`)) {
                        $(`#upgradeSubscriptionTotals_${productCodeValue}`).html(data);
                    }
                },
                error() {
                    ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
                    ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                    console.error('Failed to load promocode');
                },
            });
        }
    },

    showUpgradeSubscriptionModal(subscriptionCode) {
        const targetContainer = document.getElementById(`popup_upgrade_subscription_${subscriptionCode}`);
        let template = document.getElementById(`upgradeSubscriptionTemplate_${subscriptionCode}`).innerHTML;
        const urlParams = ACC.subscriptionHelpers.urlParam;
        let autoRenewPromo;

        if (!targetContainer && !template) {
            return;
        }

        $modal.open(null, {
            className: 'colorbox--upgrade-modal',
            inline: true,
            href: targetContainer,
            onOpen() {
                ACC.upgradeSubscription.loadUpgradePromotion();
            },
            onLoad() {
                template = document.getElementById(`upgradeSubscriptionTemplate_${subscriptionCode}`).innerHTML;
                if (ACC.upgradeSubscription.options.upgradeSubscriptionError
                    || ACC.subscriptionHelpers.lastLoadedPromo !== 'UPGRADE') {
                    targetContainer.innerHTML = template;
                    ACC.upgradeSubscription.getProratedAmount();
                    ACC.upgradeSubscription.processSubscriptionUpgradeRequest();
                    ACC.subscriptionHelpers.lastLoadedPromo = 'UPGRADE';
                }
                const cartVoucherItem = document
                    .querySelector('.popup_upgrade_subscription .c-cart-voucher__item');
                const cartVoucherBlock = document
                    .querySelector('.popup_upgrade_subscription .c-cart-voucher');
                if ((cartVoucherItem && cartVoucherBlock)
                    || (cartVoucherBlock && cartVoucherBlock.classList.contains('has-error'))) {
                    ACC.cartvoucher.openVoucherOnLoad(cartVoucherBlock);
                }
                ACC.upgradeSubscription.removeHiddenClass();
            },
            onCleanup() {
                if (urlParams && urlParams.has(ACC.upgradeSubscription.options.upgradeSubscriptionUrlParam)) {
                    ACC.subscriptionHelpers.deleteUrlParam(
                        urlParams,
                        ACC.upgradeSubscription.options.upgradeSubscriptionUrlParam,
                    );
                }
            },
            onClosed() {
                ACC.accessibilityHelpers.showContent();
                autoRenewPromo = document.querySelector('.popup_upgrade_subscription .c-cart-voucher');
                if (autoRenewPromo) {
                    autoRenewPromo.classList.add('hidden-block');
                }
            },
        });
    },

    removeHiddenClass() {
        // Removing hidden class after loading of modal window
        new Promise((resolve) => {
            resolve();
        }).then(() => {
            const autoRenewPromo = document
                .querySelector('#cboxLoadedContent .popup_upgrade_subscription .c-cart-voucher');
            if (autoRenewPromo) {
                autoRenewPromo.classList.remove('hidden-block');
            }
            $('#colorbox').scrollTop(0);
        });
    },

    getProratedAmount() {
        ACC.upgradeSubscription.configureCostBreakdownFields(ACC.config.subscriptionCostBreakdown);

        const { subscriptionCode, existingSubscriptionCode, productIsbn13 } = ACC.subscriptionHelpers.params;
        const url = `${ACC.config.encodedContextPath}/subscriptions/${existingSubscriptionCode}/cost-breakdown`
            + `?upgradeProductVariantCode=${subscriptionCode}`;
        const button = document.querySelector('.js-confirmation-button');
        const requestTimeout = ACC.config.getProratedAmountTimeout;
        if (button) {
            button.setAttribute('disabled', 'disabled');
        }
        const controller = new AbortController();
        setTimeout(() => {
            controller.abort();
        }, requestTimeout);

        fetch(url, { signal: controller.signal })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.dueToday) {
                    ACC.upgradeSubscription.showLoadedData(data);
                    button.removeAttribute('disabled');
                    const removalPromoCodeElement = $('#removalPromoCode');
                    if (removalPromoCodeElement.length) {
                        const voucherField = $('#cboxContent #js-voucher-code-text');
                        const removalPromoCode = removalPromoCodeElement.val();
                        if (removalPromoCode.length) {
                            voucherField.val(removalPromoCodeElement.val());
                            ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
                        }
                    }
                    ACC.subscriptionHelpers.validateVoucherCodeTextInput();
                    ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                    ACC.upgradeSubscription.subscriptionData = {
                        data,
                        subscriptionCode,
                        existingSubscriptionCode,
                        productIsbn13,
                    };
                    ACC.upgradeSubscription.options.upgradeSubscriptionError = false;
                } else {
                    ACC.upgradeSubscription.handlesUpgradeSubscriptionError(data);
                    ACC.subscriptionHelpers.validateVoucherCodeTextInput();
                    ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                }
            }).catch((error) => {
                console.error(error);
                ACC.subscriptionHelpers.validateVoucherCodeTextInput();
                ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                ACC.upgradeSubscription.handlesUpgradeSubscriptionError(ACC.profile.options.errorMsg);
            });
    },

    handlesUpgradeSubscriptionError(message) {
        $modal.close();
        ACC.upgradeSubscription.options.upgradeSubscriptionError = true;
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.callErrorGlobalMessage(message);
        ACC.subscriptionDataLayer.pushUpgradeErrorDataLayerEvent(message);
    },

    showLoadedData(data) {
        function showField(fieldSelector, fieldValue) {
            const targetElement = document.querySelector(fieldSelector);

            if (!targetElement) {
                return;
            }
            targetElement.innerHTML = fieldValue;
        }

        function setDisplay(selector, display) {
            const element = document.querySelector(selector);

            if (!element) {
                return;
            }
            element.style.display = display;
        }

        function getDefaultName(fieldSelector) {
            const targetElement = document.querySelector(fieldSelector);

            if (!targetElement) {
                return '';
            }
            return targetElement.dataset.name;
        }

        const isCostBreakdownAvailable = data.taxAmount;
        const isUserInternal = $('.js-upgrade-subscription-button').attr('is-internal');

        ACC.upgradeSubscription.configureCostBreakdownFields(isCostBreakdownAvailable);
        ACC.upgradeSubscription.configureFieldsForUpfrontSubscription(data.isUpfrontSubscription);
        if (data.isUpfrontSubscription) {
            if (data.isPreviewOrderCallFailed) {
                showField('.js-one-time-price', data.dueToday);
                showField('#totalProratedPriceAmountValue', data.proratedAmount);
                showField('#upfrontDueTodayAmountValue', data.dueToday);
                setDisplay('#taxCopy', 'none');
                setDisplay('#taxValue', 'none');
            } else {
                showField('.js-one-time-price', data.nextBillAmount);
                showField('#totalProratedPriceAmountValue', data.proratedAmount);
                showField('.order-total-list__price-text', data.nextBillAmount);
                setDisplay('#upfrontDueTodayCopy', 'none');
                setDisplay('#upfrontDueTodayValue', 'none');
                setDisplay('.js-text-info-value-discount', 'none');
                if (isCostBreakdownAvailable) {
                    showField('#taxValue', `+ ${data.taxAmount}`);
                }
            }
        } else {
            setDisplay('#totalProratedPrice', 'none');
            setDisplay('#totalProratedPriceValue', 'none');
            setDisplay('#upfrontDueTodayCopy', 'none');
            setDisplay('#upfrontDueTodayValue', 'none');
            setDisplay('.js-text-info-value-discount', 'none');
        }
        if (isCostBreakdownAvailable && !data.isUpfrontSubscription) {
            if (data.proratedAmount.includes('-')) {
                showField('#proratedAmountValue', `- ${data.proratedAmount.substring(1)}`);
            } else {
                showField('#proratedAmountValue', `+ ${data.proratedAmount}`);
            }
            showField('#taxValue', `+ ${data.taxAmount}`);
            showField('.order-total-list__price-text', data.nextBillAmount);
            setDisplay('#totalProratedPrice', 'none');
            setDisplay('#totalProratedPriceValue', 'none');
            setDisplay('#upfrontDueTodayCopy', 'none');
            setDisplay('#upfrontDueTodayValue', 'none');
            setDisplay('.js-text-info-value-discount', 'none');
        } else if (!data.isPromotionAppliedTillNextBillingDate) {
            showField('#totalPriceAmountValue', data.nextBillAmount);
        }

        if (data.discountAmount) {
            if (isUserInternal === 'true') {
                showField('#promoName', `${getDefaultName('#promoName')}`);
            } else {
                showField('#promoName', `${data.discountDescription || getDefaultName('#promoName')}:`);
            }
            showField('#promoPrice', `- ${data.discountAmount}`);
        } else {
            setDisplay('#promoName', 'none');
            setDisplay('#promoPrice', 'none');
        }
        showField('#dueTodayValue', data.dueToday);
        showField('#paymentDetailsValue', data.paymentDetails);
        if (data.nextBillingDate) {
            showField('#nextBillDateValue', data.nextBillingDate);
            showField('#nextPaymentDateValue', data.nextBillingDate);
        } else {
            setDisplay('#nextBillDate', 'none');
            setDisplay('#nextBillDateValue', 'none');
            setDisplay('.popup_upgrade_subscription__next-payment-date', 'none');
        }
        if (data.isPromotionAppliedTillNextBillingDate) {
            setDisplay('#totalPrice', 'none');
            setDisplay('#totalPriceValue', 'none');
            setDisplay('.popup_upgrade_subscription__promotion-till-next-billing-date-info', 'block');
        }
        if (!data.isFullUpgradePriceDifferenceCoveredByPromotion) {
            setDisplay('.popup_upgrade_subscription__billing-amount-info', 'block');
        }

        document.querySelector('.order-total-list').classList.add('loaded');
    },

    parseFirstFloat(str) {
        const FLOAT_PATTERN = /\d+\.*\d*/g;
        return parseFloat(str.match(FLOAT_PATTERN)[0]);
    },

    fetchUpgradeAmount(data) {
        return data.proratedAmount != null ? ACC.upgradeSubscription.parseFirstFloat(data.proratedAmount)
            : ACC.upgradeSubscription.parseFirstFloat(data.nextBillAmount);
    },

    processSubscriptionUpgradeRequest() {
        $('.js-confirmation-button').on('click', function () {
            const {
                data, subscriptionCode, existingSubscriptionCode, productIsbn13,
            } = ACC.upgradeSubscription.subscriptionData;
            ACC.buttonhelper.disable('.js-confirmation-button').withSpinner();
            const subscriptionTierId = this.dataset.mojoSubscriptionTierId;
            ACC.subscriptionDataLayer.pushUpgradeDataLayerEvent(subscriptionTierId);
            ACC.subscriptionDataLayer.pushEntitlementDataLayerEvent();
            const amount = ACC.upgradeSubscription.fetchUpgradeAmount(data);
            const discountedAmountBeforeTax = data.amountBeforeTax;
            const upgradeSubscriptionUrl = `${ACC.config.encodedContextPath}/account/subscriptions/upgrade`;
            $.post({
                url: upgradeSubscriptionUrl,
                data: {
                    existingSubscriptionId: existingSubscriptionCode,
                    upgradeProductVariantCode: subscriptionCode,
                    isbn13: productIsbn13,
                    amount,
                    discountedAmountBeforeTax,
                },
                success() {
                    ACC.upgradeSubscription.showUpgradeConfirmationModal(data, subscriptionCode);
                },
                error(errData) {
                    $modal.close();
                    if (errData.responseJSON === 'upfront.upgrade.payment.error') {
                        const link = `${ACC.config.encodedContextPath}/account/payment-methods?subscriptionNumber=`;
                        const subscriptionNumberInput = $('input[name=subscriptionNumber]');
                        const subscriptionNumber = subscriptionNumberInput.length !== 0
                            ? subscriptionNumberInput.val()
                            : $('.js-upgrade-subscription-button').attr('data-subscription-number');
                        ACC.globalmessages.callErrorGlobalMessageWithAttributes(
                            'upfront.upgrade.payment.error',
                            link + subscriptionNumber,
                        );
                    } else {
                        ACC.globalmessages.callErrorGlobalMessage(errData.responseJSON);
                        ACC.buttonhelper.disable('.js-upgrade-subscription-button');
                        ACC.subscriptionDataLayer.pushUpgradeErrorDataLayerEvent(errData.responseJSON);
                    }
                },
            });
        });
    },

    showUpgradeConfirmationModal(data, subscriptionCode) {
        const targetContainer = document.getElementById(`popup_upgrade_confirmation_${subscriptionCode}`);
        const template = document.getElementById(`upgradeConfirmationTemplate_${subscriptionCode}`);
        const urlParams = ACC.subscriptionHelpers.urlParam;

        $modal.open(null, {
            href: targetContainer,
            className: 'colorbox--upgrade-modal',
            inline: true,
            onLoad() {
                $(targetContainer).html($(template).tmpl(data));
                $('#colorbox').animate({
                    scrollTop: 0,
                }, 50);
                if (urlParams && urlParams.has(ACC.upgradeSubscription.options.upgradeSubscriptionUrlParam)) {
                    ACC.subscriptionHelpers.deleteUrlParam(
                        urlParams,
                        ACC.upgradeSubscription.options.upgradeSubscriptionUrlParam,
                    );
                }
            },
            onClosed() {
                window.location.reload();
            },
        });
    },
};
