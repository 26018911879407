ACC.paypalReviewOrder = {
    _autoload: [
        ['init', $('.js-paypal-review-order').length !== 0],
        ['bindCreditCardAddressForm', $('#billingPayPalCountrySelector').length !== 0],
    ],

    init() {
        $('.js-paypal-review-order').on('click', function () {
            $(this).addClass('button-is-processing');
            $(this).find('span').text('Processing payment…');
        });
        ACC.applePayHelpers.hideApplePayLegalText();
    },

    bindCreditCardAddressForm() {
        $('#billingPayPalCountrySelector :input').on('change', () => {
            ACC.paypalReviewOrder.updateBillingAddressForm();
        });
    },

    updateBillingAddressForm() {
        const countrySelection = $('#address\\.country option:selected').val();
        const options = {
            countryIsoCode: countrySelection,
        };
        ACC.paypalReviewOrder.displayPayPalAddressForm(options);
    },

    displayPayPalAddressForm(options) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/multi/cybs-paypal/billingAddressValidationForm`,
            async: true,
            data: options,
            dataType: 'html',
        }).done((data) => {
            $('#billingAddressValidationForm').html(data);
            ACC.paypalReviewOrder.bindCreditCardAddressForm();
        });
    },
};
