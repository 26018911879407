ACC.learnerconsents = {

    _autoload: [
        'bindAcceptConsentsLink',
    ],

    acceptConsents(username, password, escrowTicket, rememberMe, originalReferer, successMessage, orderGUID) {
        function onSuccess() {
            ACC.learnerlogin.signIn(username, password, rememberMe, originalReferer, successMessage, orderGUID);
        }
        $.ajax({
            type: 'POST',
            url: `${ACC.config.iamBaseUrl}/login/webacceptconsent`,
            data: { escrowTicket, client_id: ACC.config.iamClientId },
            xhrFields: {
                withCredentials: true,
            },
            success: onSuccess,
            error(jqXHR) {
                let messageKey;
                const form = $('#acceptConsentsForm');
                const btn = form.find('button[type=submit]');
                if (jqXHR.status === 404) {
                    messageKey = 'account.error.account.not.found';
                } else {
                    messageKey = 'account.error.generic';
                }
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                ACC.buttonhelper.enable(btn);
            },
        });
    },

    acceptMarketingConsent(username, consent) {
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/login/marketing`,
            data: { username, consent },
        });
    },

    bindAcceptConsentsLink() {
        $(document).on('submit', '#acceptConsentsForm', (e) => {
            e.preventDefault();
            ACC.learnerconsents.addDisabledButtonState(e);
            if ($('#marketingConsent').length) {
                ACC.learnerconsents.acceptMarketingConsent($('#username').val(), $('#marketingConsent').is(':checked'));
            }

            ACC.learnerconsents.acceptConsents(
                $('#username').val(),
                '',
                $('#escrowTicket').val(),
                $('#rememberMe').is(':checked'),
                $('#loginReferer').val(),
                'account.success.login',
                $('#orderCode').val(),
            );
        });
    },

    bindSsoAcceptConsentsLink(username, password, escrowTicket, rememberMe, loginReferer, successMessage, orderCode) {
        $(document).off('submit', '#acceptConsentsForm');
        $(document).on('submit', '#acceptConsentsForm', (e) => {
            e.preventDefault();
            ACC.learnerconsents.addDisabledButtonState(e);

            if ($('#marketingConsent').length) {
                ACC.learnerconsents.acceptMarketingConsent(username, $('#marketingConsent').is(':checked'));
            }

            ACC.learnerconsents.acceptConsents(
                username,
                password,
                escrowTicket,
                rememberMe,
                loginReferer,
                successMessage,
                orderCode,
            );
        });
    },

    addDisabledButtonState(event) {
        const btn = $(event.target).find('button[type=submit]');
        ACC.buttonhelper.disable(btn);
    },

    showSsoAcceptConsents(username, password, escrowTicket, rememberMe, loginReferer, successMessage, orderCode) {
        $.ajax({
            async: false,
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/consents/check`,
            data: {
                userName: username,
            },
            success: (response) => {
                if (response.showSsoAcceptConsentsFragment) {
                    ACC.learnerconsents.loadSsoAcceptConsents(
                        username,
                        password,
                        escrowTicket,
                        rememberMe,
                        loginReferer,
                        successMessage,
                        orderCode,
                    );
                } else {
                    if (response.marketingConsentPreselected) {
                        ACC.learnerconsents.acceptMarketingConsent(username, true);
                    }
                    ACC.learnerconsents.acceptConsents(
                        username,
                        password,
                        escrowTicket,
                        rememberMe,
                        loginReferer,
                        successMessage,
                        orderCode,
                    );
                }
            },
            error: () => {
                ACC.learnerconsents.loadSsoAcceptConsents(
                    username,
                    password,
                    escrowTicket,
                    rememberMe,
                    loginReferer,
                    successMessage,
                    orderCode,
                );
            },
        });
    },

    loadSsoAcceptConsents(username, password, escrowTicket, rememberMe, loginReferer, successMessage, orderCode) {
        const url = `${ACC.config.encodedContextPath}/consents/ssoacceptconsents`;
        const bodyPage = document.querySelector('body');
        $('#mainPageContent').load(url, { userName: username, escrowTicket }, () => {
            ACC.validation.bindFormValidation();
            ACC.learnerconsents.bindSsoAcceptConsentsLink(
                username,
                password,
                escrowTicket,
                rememberMe,
                loginReferer,
                successMessage,
                orderCode,
            );
            if (bodyPage.classList.contains('page-login')) {
                bodyPage.classList.remove('page-login');
                bodyPage.classList.add('page-login-welcome');
            }
        });
    },
};
