ACC.passwordValidation = {
    _autoload: [
        ['init', (document.getElementById('passwordRequirementsID') != null)],
    ],

    input: null,
    formGroup: null,
    passwordRequirements: null,
    textBlock: null,
    passwordBtn: null,
    timeout: null,
    isShow: 'isShow',
    isHide: 'isHide',

    init() {
        ACC.passwordValidation.createConfig();
        ACC.passwordValidation.addListeners();
        ACC.passwordValidation.addA11yAttr();
    },

    addA11yAttr() {
        ACC.passwordValidation.input
            .setAttribute('aria-describedby', 'screen-read-headline screen-read-list');
    },

    createConfig() {
        const passwordRequirements = document.getElementById('passwordRequirementsID');
        const formGroup = passwordRequirements.closest('.form-group');
        const input = formGroup.querySelector('.input');
        const textBlock = formGroup.querySelector('#screen-read-text');
        const passwordBtn = formGroup.querySelector('.js-handle-password-button');

        ACC.passwordValidation.input = input;
        ACC.passwordValidation.formGroup = formGroup;
        ACC.passwordValidation.passwordRequirements = passwordRequirements;
        ACC.passwordValidation.textBlock = textBlock;
        ACC.passwordValidation.passwordBtn = passwordBtn;
    },

    addListeners() {
        const {
            showPasswordRequirements,
            checkRequirements,
            checkPassword,
        } = ACC.passwordValidation;

        ACC.passwordValidation.input.addEventListener('keyup', checkRequirements);
        ACC.passwordValidation.input.addEventListener('focusin', showPasswordRequirements);
        ACC.passwordValidation.passwordBtn.addEventListener('focusin', showPasswordRequirements);
        ACC.passwordValidation.input.addEventListener('focusout', checkPassword);
        ACC.passwordValidation.passwordBtn.addEventListener('focusout', checkPassword);
    },

    checkRequirements() {
        ACC.passwordValidation.checkUppercaseRequirement();
        ACC.passwordValidation.checkNumberRequirement();
        ACC.passwordValidation.checkLengthRequirement();
        ACC.passwordValidation.checkCharacterRequirement();
        ACC.passwordValidation.checkRequirementsMet();
    },

    checkRequirementsMet() {
        const list = ACC.passwordValidation.passwordRequirements.classList;
        const { textBlock } = ACC.passwordValidation;

        if (list.contains('length-req-met')
            && list.contains('uppercase-req-met')
            && list.contains('number-req-met')
            && list.contains('character-req-met')) {
            textBlock.innerText = 'All requirements met';
        }
    },

    checkLengthRequirement() {
        const list = ACC.passwordValidation.passwordRequirements.classList;
        const { textBlock, input } = ACC.passwordValidation;
        const password = input.value;

        if (/^(.{8,120}$)/.test(password)) {
            if (!list.contains('length-req-met')) {
                list.add('length-req-met');
                textBlock.innerText = '8 characters requirement met';
            }
        } else if (list.contains('length-req-met')) {
            list.remove('length-req-met');
            textBlock.innerText = '8 characters requirement no longer met';
        }
    },

    checkUppercaseRequirement() {
        const list = ACC.passwordValidation.passwordRequirements.classList;
        const { textBlock, input } = ACC.passwordValidation;
        const password = input.value;

        if (/^(?=.*[A-Z])/.test(password)) {
            if (!list.contains('uppercase-req-met')) {
                list.add('uppercase-req-met');
                textBlock.innerText = '1 uppercase letter requirement met';
            }
        } else if (list.contains('uppercase-req-met')) {
            list.remove('uppercase-req-met');
            textBlock.innerText = '1 uppercase letter requirement no longer met';
        }
    },

    checkNumberRequirement() {
        const list = ACC.passwordValidation.passwordRequirements.classList;
        const { textBlock, input } = ACC.passwordValidation;
        const password = input.value;

        if (/^(?=.*[0-9])/.test(password)) {
            if (!list.contains('number-req-met')) {
                list.add('number-req-met');
                textBlock.innerText = '1 number requirement met';
            }
        } else if (list.contains('number-req-met')) {
            list.remove('number-req-met');
            textBlock.innerText = '1 number requirement no longer met';
        }
    },

    checkCharacterRequirement() {
        const list = ACC.passwordValidation.passwordRequirements.classList;
        const { textBlock, input } = ACC.passwordValidation;
        const password = input.value;

        if (/^[\w@\-,._]+$/.test(password)) {
            if (!list.contains('character-req-met')) {
                list.add('character-req-met');
                textBlock.innerText += ' Special characters requirement met';
            }
        } else if (list.contains('character-req-met')) {
            list.remove('character-req-met');
            textBlock.innerText += ' Special characters requirement no longer met';
        }
    },

    showPasswordRequirements() {
        clearTimeout(ACC.tooltipPlugin.timeout);
        ACC.passwordValidation.passwordRequirements.classList.remove(ACC.passwordValidation.isHide);
        ACC.passwordValidation.passwordRequirements.classList.add(ACC.passwordValidation.isShow);
    },

    checkPassword() {
        const passwordRequirementsSet = ACC.passwordValidation.passwordRequirements;
        const passwordField = ACC.passwordValidation.input;
        const list = passwordRequirementsSet.classList;

        if (passwordField && passwordField.value) {
            if (list.contains('length-req-met') && list.contains('uppercase-req-met')
                    && list.contains('number-req-met') && list.contains('character-req-met')) {
                ACC.passwordValidation.toggleBlockOnTimeOut();
            }
            if (!list.contains('length-req-met') || !list.contains('uppercase-req-met')
                || !list.contains('number-req-met') || !list.contains('character-req-met')) {
                list.add('isInvalid');
            }
        } else if (ACC.passwordValidation.formGroup.classList.contains('has-error')) {
            ACC.passwordValidation.toggleBlockOnTimeOut();
        }
    },

    toggleBlockOnTimeOut() {
        const passwordRequirementsSet = ACC.passwordValidation.passwordRequirements;
        const list = passwordRequirementsSet.classList;
        ACC.tooltipPlugin.timeout = setTimeout(() => {
            ACC.passwordValidation.textBlock.innerText = '';
            list.add(ACC.passwordValidation.isHide);
            list.remove(ACC.passwordValidation.isShow);
        }, 100);
    },
};
