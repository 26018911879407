ACC.checkout = {

    _autoload: [
        'initializePiSession',
        'initialize',
        'bindCheckO',
        'bindForms',
        'bindSavedPayments',
        'bindPaymentTypeSelect',
        'bindAccountVerificationTypeSelect',
        'bindDeliveryMethodChange', ['bindCardTypeIdentification', $('#card_accountNumber').length !== 0],
        ['bindContactPhoneValidation', $('#contactPhone').length !== 0],
        ['showBillAmountValue', document.querySelector('.js-next-bill-amount-value')
        && document.querySelector('.js-next-bill-amount-value').length !== 0],
        ['removeCvvError', document.querySelector('.page-mojo-checkout')
        || document.querySelector('.page-update-payment-method')],
    ],

    piSessionData: {},

    bindForms() {
        $(document).on('click', '#addressSubmit', (e) => {
            e.preventDefault();
            const addressTypeInput = $('input[type=radio][name=addressType]');
            if (addressTypeInput.length > 0) {
                const checkedButton = addressTypeInput.filter(':checked').val();
                if (checkedButton === 'oneAddress') {
                    $('#selectAddressForm').submit();
                } else if (checkedButton === 'searchAddress') {
                    $('#searchAddressForm').submit();
                } else if (checkedButton === 'addNewAddress') {
                    ACC.checkout.validateAndSubmitAddressForm();
                }
            } else {
                ACC.checkout.validateAndSubmitAddressForm();
            }
        });

        $(document).on('click', '#deliveryMethodSubmit', (e) => {
            e.preventDefault();
            $('#selectDeliveryMethodForm').submit();
        });

        $(document).on('click', '#qualificationUsersSubmit', (e) => {
            e.preventDefault();
            $('#qualifiedUsersForm').submit();
        });

        $(document).on('click', '#paymentTypeSubmit', (e) => {
            e.preventDefault();
            $('#selectPaymentTypeForm').submit();
        });

        $(document).on('click', '#accountVerificationSubmit', (e) => {
            e.preventDefault();
            $('#accountVerificationForm').submit();
        });

        $(document).on('click', '#toggleQualifiedUserAddEmail', (e) => {
            e.preventDefault();
            $('.checkoutQualifiedUserAddEmailSection').toggle();
            $('#toggleQualifiedUserAddEmail > span').toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
        });

        // eslint-disable-next-line max-len
        if ($('.checkoutQualifiedUserAddEmailSection') && $('.checkoutQualifiedUserAddEmailSection > .form-group').hasClass('has-error')) {
            $('.checkoutQualifiedUserAddEmailSection').show();
        }
    },

    validateAndSubmitAddressForm() {
        const isValidPhoneNumber = ACC.phonemasking.validate($('#address\\.phone'));
        ACC.checkout.scrollToFirstInvalidField();
        if (isValidPhoneNumber) {
            $('#addressForm').submit();
        }
    },

    processCheckoutRequest(targetUrl, updateCustomerUrl) {
        $.post({
            url: updateCustomerUrl,
            data: ACC.checkout.piSessionData,
            success() {
                window.location = targetUrl;
            },
            error() {
                const localizedBusinessError = $('#callIAMErrorMsg').val();
                $('.global-alerts').addClass('alert-danger');
                $('.alert-message').html(localizedBusinessError);
            },
        });
    },

    bindSavedPayments() {
        $(document).on('click', '.js-saved-payments', (e) => {
            e.preventDefault();

            const title = $('#savedpaymentstitle').html();

            $.colorbox({
                href: '#savedpaymentsbody',
                inline: true,
                opacity: 0.7,
                title,
                close: '<span class="glyphicon glyphicon-remove"></span>',
                onComplete() {},
            });
        });
    },

    bindCheckO() {
        // Alternative checkout flows options
        $('.doFlowSelectedChange').change(() => {
            if ($('#selectAltCheckoutFlow').val() === 'multistep-pci') {
                $('#selectPciOption').show();
            } else {
                $('#selectPciOption').hide();
            }
        });

        $('.expressCheckoutButton').click(() => {
            document.getElementById('expressCheckoutCheckbox').checked = true;
        });

        $(document).on('input', '.confirmGuestEmail,.guestEmail', () => {
            const orginalEmail = $('.guestEmail').val();
            const confirmationEmail = $('.confirmGuestEmail').val();

            if (orginalEmail === confirmationEmail) {
                $('.guestCheckoutBtn').removeAttr('disabled');
            } else {
                $('.guestCheckoutBtn').attr('disabled', 'disabled');
            }
        });

        $('.js-continue-checkout-button').click(function (event) {
            event.preventDefault();
            const targetUrl = $(this).data('target-url');
            const updateCustomerUrl = $(this).data('update-customer-url');
            ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
        });
    },

    redirectToCheckoutPage(checkoutUrl) {
        const cartEntriesError = ACC.pickupinstore.validatePickupinStoreCartEntires();
        if (!cartEntriesError) {
            const expressCheckoutObject = $('.express-checkout-checkbox');
            if (expressCheckoutObject.is(':checked')) {
                window.location = expressCheckoutObject.data('expressCheckoutUrl');
            } else {
                let flow = $('#selectAltCheckoutFlow').val();
                if (flow === undefined || flow === '' || flow === 'select-checkout') {
                    // No alternate flow specified, fallback to default behaviour
                    window.location = checkoutUrl;
                } else {
                    // Fix multistep-pci flow
                    if (flow === 'multistep-pci') {
                        flow = 'multistep';
                    }
                    const pci = $('#selectPciOption').val();

                    // Build up the redirect URL
                    const redirectUrl = `${checkoutUrl}/select-flow?flow=${flow}&pci=${pci}`;
                    window.location = redirectUrl;
                }
            }
        }
    },

    bindPaymentTypeSelect() {
        $("input:radio[name='paymentType']").change(() => {
            if (ACC.checkout.isAccountPayment()) {
                ACC.checkout.displayAccountPaymentSection(true);
            } else {
                ACC.checkout.displayAccountPaymentSection(false);
            }
            ACC.checkout.initialize();
        });
    },

    bindAccountVerificationTypeSelect() {
        const $checkboxes = $('[name="verificationType"]');
        const $submitBtn = $('#accountVerificationSubmit');

        function updateState() {
            if ($checkboxes.filter(':checked').length > 0) {
                $submitBtn.prop('disabled', false);
            } else {
                $submitBtn.prop('disabled', true);
            }
        }

        updateState();
        $checkboxes.on('change', updateState);
    },

    isNewAccount() {
        return $('input:radio[name=verificationType]:checked').val() === 'NewAccount';
    },

    initialize() {
        if (ACC.checkout.isAccountPayment()) {
            ACC.checkout.displayAccountPaymentSection(true);
        } else {
            ACC.checkout.displayAccountPaymentSection(false);
        }
    },

    initializePiSession() {
        if (!piSession) { return; }

        ACC.piSessionInitializer.addCallback(() => {
            piSession.getToken((status, token) => {
                ACC.checkout.piSessionData.userID = piSession.userId();
                ACC.checkout.piSessionData.token = token;
            });
        });
    },

    isAccountPayment() {
        return $("input:radio[name='paymentType']:checked").val() === 'ACCOUNT';
    },

    displayAccountPaymentSection(data) {
        ACC.checkout.displayElement($('#accountPaymentDivID'), data);
    },

    displayElement(element, flag) {
        if (flag) {
            element.show();
        } else {
            element.hide();
        }
    },

    bindCardTypeIdentification() {
        const $creditsBox = $('.js-credits-box');
        const $radiobtn = $('.c-radiobtn');
        const cardTypeToCardTypeCodeMap = {
            visa: '001',
            mastercard: '002',
            amex: '003',
            discover: '004',
        };

        /* global Cleave */
        // eslint-disable-next-line no-unused-vars
        const cleave = new Cleave('#card_accountNumber', {
            creditCard: true,
            onCreditCardTypeChanged(type) {
                const value = cardTypeToCardTypeCodeMap[type] || 'invalid';
                $('#card_cardType').val(value);

                $creditsBox.find('.card-logo').addClass('isDisabled');
                if (value !== 'invalid') {
                    $creditsBox.find(`[data-type="${type}"]`).removeClass('isDisabled');
                } else {
                    $radiobtn.find('.card-logo').removeClass('isDisabled');
                }
            },
        });
    },

    bindContactPhoneValidation() {
        function changeButtonView(isValidContactPhone) {
            if (isValidContactPhone) {
                $('#contactPhoneSubmit').prop('disabled', false);
            } else {
                $('#contactPhoneSubmit').prop('disabled', true);
            }
        }

        function validateContactPhone() {
            if (ACC.config.isIntlTelInputEnabled) {
                changeButtonView(!$('#phone\\.errors').length && $('#contactPhone').val());
            } else {
                const contactPhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                changeButtonView(contactPhoneRegex.test($('#contactPhone').val()));
            }
        }

        $('#contactPhone').on('change', validateContactPhone);
        validateContactPhone();
    },

    bindDeliveryMethodChange() {
        function showMessagesFromDropDown() {
            $('#estimatedDeliveryDescription').html($('#delivery_method').find(':selected').data('estimated-message'));
            $('#deliveryDescription').html($('#delivery_method').find(':selected').data('delivery-message'));
        }

        function showMessagesFromRadioBtn() {
            $('#estimatedDeliveryDescription')
                .html($("input[name='delivery_method']:checked").data('estimated-message'));
            $('#deliveryDescription')
                .html($("input[name='delivery_method']:checked").data('delivery-message'));
        }
        $('#delivery_method').change(showMessagesFromDropDown);
        $('#selectDeliveryMethodForm input').on('change', showMessagesFromRadioBtn);
        showMessagesFromDropDown();
        showMessagesFromRadioBtn();
    },

    scrollToFirstInvalidField() {
        const errorElements = $('.has-error');
        const errorVisible = $('.has-error').is(':visible');
        if (errorElements.length && errorVisible) {
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top,
            }, 500);
        }
    },

    showBillAmountValue() {
        const billAmountContainer = document.querySelector('.js-next-bill-amount-value');
        const orderCode = billAmountContainer.dataset.orderCode ? billAmountContainer.dataset.orderCode : '';
        const { message } = billAmountContainer.dataset;
        const url = `${ACC.config.encodedContextPath}/subscriptions/newSubscription/nextBillAmount`
        + `?orderCode=${orderCode}`;
        const controller = new AbortController();
        const requestTimeout = ACC.config.getBillAmountTimeout;

        setTimeout(() => {
            controller.abort();
        }, requestTimeout);

        fetch(url, { signal: controller.signal })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    billAmountContainer.innerHTML = data;
                } else {
                    billAmountContainer.innerHTML = message;
                }
            }).catch((error) => {
                billAmountContainer.innerHTML = message;
                console.error(error);
            });
    },

    removeCvvError() {
        const cvvField = document.getElementsByName('card_cvNumber');

        window.addEventListener('load', () => {
            if (cvvField) {
                cvvField.forEach((item) => {
                    item.addEventListener('input', () => {
                        item.closest('.form-group').classList.remove('has-error');
                    });
                });
            }
        });
    },
};
