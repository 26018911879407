ACC.unifiedWallet = {
    _autoload: [
        ['init', $('.account-section-content__profile.billing-info').length !== 0
        || document.querySelector('#savedPaymentCards')],
    ],

    init() {
        this.getPiSessionUserId();
        ACC.checkoutPreValid.addRadioHandler();
        ACC.checkoutPreValid.changeOptionDate();
        this.checkFormOnErrors();
        this.addSpinnerForWalletCards();
    },

    addSpinnerForWalletCards() {
        const unifiedWalletCard = document.querySelector('#preferredSavedPaymentCards');
        if (unifiedWalletCard) {
            ACC.unifiedWallet.spinner.show(unifiedWalletCard);
        }
    },

    checkFormOnErrors() {
        if ($('.form-group .has-error').length !== 0) {
            ACC.silentorderpost.removeCardMessageValidation();
            $('#savedCard-newCard').prop('checked', true);
            ACC.silentorderpost.showNewCardBlock();
            ACC.checkoutPreValid.checkPaymentMethod('newCard');
        }
    },

    getPiSessionUserId() {
        if (!piSession) {
            return;
        }
        ACC.piSessionInitializer.initialize();
        ACC.piSessionInitializer.addCallback(() => {
            piSession.getToken((status, token) => {
                const piSessionUserId = piSession.userId();
                if (ACC.config.isUnifiedWalletEnabled) {
                    const url = ACC.unifiedWallet.getUnifiedWalletUrl(piSessionUserId);
                    ACC.unifiedWallet.getSubscriptionSavedCardData(token, url);
                } else {
                    const unifiedWalletCard = document.querySelector('#preferredSavedPaymentCards');
                    ACC.unifiedWallet.hideSpinnerForUnifiedWalletCard(unifiedWalletCard);
                }
            });
        });
    },

    spinner: {
        SHOW_SPINNER: 'spinner',
        show: (element) => {
            element.classList.add(ACC.unifiedWallet.spinner.SHOW_SPINNER);
        },
        hide: (element) => {
            element.classList.remove(ACC.unifiedWallet.spinner.SHOW_SPINNER);
        },
    },

    setBillingAddressForSavedCard(cardsData, savedCardId) {
        const selectedCardData = cardsData.filter((filterCardData) => filterCardData.paymentId === savedCardId);
        const selectedCardDataBillingAddress = selectedCardData[0].billingAddress;
        const bilAddr = {
            billTo_city: selectedCardDataBillingAddress.city,
            billTo_country: selectedCardDataBillingAddress.countryISO,
            billTo_firstName: selectedCardDataBillingAddress.firstName,
            billTo_lastName: selectedCardDataBillingAddress.lastName,
            billTo_phoneNumber: selectedCardDataBillingAddress.phone,
            billTo_postalCode: selectedCardDataBillingAddress.postalCode,
            billTo_state: selectedCardDataBillingAddress.stateISO,
            billTo_street1: selectedCardDataBillingAddress.streetAddress,
            billTo_street2: selectedCardDataBillingAddress.streetNumber,
        };

        ACC.unifiedWallet.billingAddress = bilAddr;
        return bilAddr;
    },

    getPaymentCardMasked(cardsData, savedCardId) {
        const selectedCardData = cardsData.filter((filterCardData) => filterCardData.paymentId === savedCardId);
        return selectedCardData[0].paymentCardMasked;
    },

    getUnifiedWalletUrl(userId) {
        if (userId && document.querySelector('#savedPaymentCards')) {
            return `${ACC.config.unifiedWalletUrl}/pmc-services/wallet/v1/users/${userId}/payments`
                + `?hideExpired=${ACC.config.unifiedWalletShouldHideExpiredCards}`;
        }
        const subscriptionId = $('.account-info__action-block #command').find("input[name='subscriptionNumber']").val();
        if (userId && subscriptionId) {
            return `${ACC.config.unifiedWalletUrl}/pmc-services/wallet/v1/users/${userId}`
            + `/payments?externalProductId=${subscriptionId}`;
        }
        return false;
    },

    getSubscriptionSavedCardData(token, url) {
        const unifiedWalletCard = document.querySelector('#preferredSavedPaymentCards');

        if (unifiedWalletCard === null) {
            return;
        }

        if (url) {
            $.ajax({
                url,
                type: 'GET',
                dataType: 'json',
                async: true,
                headers: {
                    'X-Authorization': token,
                },
            })
                .done((data) => {
                    if (data && data.length) {
                        data.forEach((savedCardData) => {
                            ACC.unifiedWallet.setModifedPaymentType(savedCardData);
                        });
                        ACC.unifiedWallet.appendSavedCardData(data);
                        ACC.unifiedWallet.autoSelectDefaultCard();
                    }
                    ACC.unifiedWallet.hideSpinnerForUnifiedWalletCard(unifiedWalletCard);
                    ACC.silentorderpost.openCardAfterError();
                    ACC.unifiedWallet.openCreditCardBlockWithError();
                })
                .fail(() => {
                    ACC.unifiedWallet.hideSpinnerForUnifiedWalletCard(unifiedWalletCard);
                    ACC.silentorderpost.openCardAfterError();
                    const errorMessageCode = window.ApplePaySession
                        ? 'unified.wallet.applepay.checkout.payments.error.page.title'
                        : 'unified.wallet.checkout.payments.error.page.title';
                    const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(
                        [errorMessageCode],
                        'accErrorMsgs',
                    );
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                });
        }
    },

    createGlobalMessageRequest: (messages = [], messageHolder = '') => ({
        globalMessages: [{
            messageHolder,
            messages,
        }],
    }),

    setModifedPaymentType(savedCardData) {
        switch (savedCardData.paymentType) {
        default:
            // eslint-disable-next-line no-param-reassign
            savedCardData.paymentTypeModified = savedCardData.paymentType.toLowerCase();
            break;
        }
    },

    appendSavedCardData(data) {
        cardData = ACC.unifiedWalletSorting.getSavedCards(data);

        const currentPayment = document.querySelector('.account-info__current-payment-card');

        if (currentPayment) {
            ACC.unifiedWallet.spinner.hide(currentPayment);
        }

        if ($('.account-info').length !== 0) {
            $('.account-info')
                .find($('.account-info__current-payment-card'))
                .html($('#accountSubscriptionPaymentInfo').tmpl(cardData));
        }
        const savedPaymentCards = $('#savedPaymentCards');
        if (savedPaymentCards.length) {
            const savedPaymentCardTemplate = $('#savedPaymentCardTemplate');
            const savedDefaultPaymentCard = savedPaymentCards.find($('#preferredSavedPaymentCards'));
            PREFERED_PAYMENT_DATA = ACC.unifiedWalletSorting.filterSavedPaymentCards(data, true);
            ACC.unifiedWallet.appendMessageForToggle();
            if (PREFERED_PAYMENT_DATA.length) {
                savedDefaultPaymentCard
                    .addClass('default-card-active')
                    .append(savedPaymentCardTemplate.tmpl(PREFERED_PAYMENT_DATA));
            }
            SAVED_PATMENT_DATA = ACC.unifiedWalletSorting.filterSavedPaymentCards(data, false);
            GLOBAL_PAYMENT_DATA = data;
            savedPaymentCards.find($('.saved-card-error')).after(savedPaymentCardTemplate.tmpl(SAVED_PATMENT_DATA));
            ACC.silentorderpost.bindSavedCards();
            ACC.checkoutPreValid.addRadioHandler();
            ACC.tooltipPlugin.init();
        }
    },

    hideSpinnerForUnifiedWalletCard(unifiedWalletCard) {
        if (unifiedWalletCard) {
            ACC.unifiedWallet.spinner.hide(unifiedWalletCard);
        }
    },

    getSopForm() {
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            return $('#sopRequestForm');
        }
        return $('#sopRequestIframe').contents().find('#sopRequestForm');
    },

    appendMessageForToggle() {
        if (PREFERED_PAYMENT_DATA.length !== 0) {
            const checkoutWithCreditCardButton = $('#checkoutWithCreditCardButton');
            const message = checkoutWithCreditCardButton.data('another-card-message');
            checkoutWithCreditCardButton.text(message);
        }
    },

    autoSelectDefaultCard() {
        const checkoutPage = document.querySelector('.page-mojo-checkout');
        const defaultCard = document.querySelector('.card-info__preferred');
        if (defaultCard && checkoutPage) {
            defaultCard.closest('.c-radiobtn').click();
        }
    },

    openCreditCardBlockWithError() {
        const errorItem = document.querySelectorAll('#accountNumber .has-error');
        const checkoutWithCreditCardButton = document.getElementById('checkoutWithCreditCardButton');
        if (errorItem.length) {
            checkoutWithCreditCardButton.click();
        }
    },
};
