ACC.profile = {

    _autoload: [
        'initializePiSession',
        'initialize',
        'trackFormChanges',
        'showCancelProfileChangesConfirmation',
        'updateCustomerProfileOnSubscription',
        'switchSocialIcon',
    ],

    options: {
        errorMsg: 'text.subscription.upgrade.error',
    },

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    initialize() {
        if ($('#updateProfileToken').length > 0 && piSession) {
            piSession.getToken((status, token) => {
                $('#updateProfileToken').val(token);
                $('#updateProfileUserId').val(piSession.userId());
            });
        }
    },

    piSessionData: {},

    initializePiSession() {
        if (!piSession) { return; }
        ACC.piSessionInitializer.addCallback(() => {
            piSession.getToken((status, token) => {
                ACC.profile.piSessionData.userID = piSession.userId();
                ACC.profile.piSessionData.token = token;
            });
        });
    },

    trackFormChanges() {
        $('#updatePearsonProfileForm').on('change', () => {
            $('#updatePearsonProfileForm').data('changed', true);
        });
    },

    showCancelProfileChangesConfirmation() {
        $('.js-cancel-profile-changes-popup-button').on('click', function () {
            if ($('#updatePearsonProfileForm').data('changed')) {
                $modal.open(this);
            } else {
                window.location = $(this).data('cancelUrl');
            }
        });
    },

    updateCustomerProfileOnSubscription() {
        $('.js-add-subscription-button').on('click', function () {
            const updateCustomerUrl = $(this).data('update-customer-url');
            $.post({
                url: updateCustomerUrl,
                data: ACC.profile.piSessionData,
            });
        });
    },

    switchSocialIcon() {
        const socialButton = document.querySelectorAll('.social-avatar__item');

        if (socialButton.length) {
            socialButton.forEach((clickElement) => {
                clickElement.addEventListener('click', () => {
                    socialButton.forEach((item) => item.classList.remove('isSelected'));
                    clickElement.classList.add('isSelected');
                    document.querySelector('.social-avatar__value').value = clickElement.getAttribute('data-social');
                });

                if (!clickElement.getAttribute('data-social').match(/background/g)) {
                    document.querySelector('span.social-account__nickname').outerHTML = '';
                }
            });
        }
    },
};
