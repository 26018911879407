ACC.tooltipPlugin = {
    _autoload: [
        ['init', document.querySelector('.c-tooltip')],
    ],

    instances: {},
    timeout: {},

    positions: [
        {
            placement: 'bottom',
            offset: '0',
        },
        {
            placement: 'bottom-end',
            offset: '10',
        },
    ],

    init() {
        ACC.tooltipPlugin.loopThoughTooltips();
    },

    loopThoughTooltips() {
        const tooltips = document.querySelectorAll('.c-tooltip');
        tooltips.forEach((tooltip) => {
            ACC.tooltipPlugin.createTooltip(tooltip);
        });
    },

    createTooltip(tooltip) {
        if (tooltip.classList.contains('c-tooltip--initialized')) {
            return;
        }

        const icon = tooltip.querySelector('.c-tooltip__icon');
        const textWrapper = tooltip.querySelector('.c-tooltip__text');
        const id = textWrapper.getAttribute('id');

        ACC.tooltipPlugin.setPosition(textWrapper);
        ACC.tooltipPlugin.initPopper(icon, textWrapper, id);
        ACC.tooltipPlugin.addListeners(icon, tooltip, id);

        const msg = ACC.tooltipPlugin.setTooltipText(tooltip);
        if (msg) {
            ACC.tooltipPlugin.addMessage(textWrapper, msg);
        }
        ACC.tooltipPlugin.addArrow(textWrapper);
        ACC.tooltipPlugin.completeInit(tooltip);
    },

    completeInit(tooltip) {
        tooltip.classList.add('c-tooltip--initialized');
    },

    addArrow(container) {
        container.insertAdjacentHTML('beforeend', '<div class="c-tooltip__arrow" x-arrow></div>');
    },

    addMessage(container, text) {
        container.innerHTML = text;
    },

    setTooltipText(container) {
        const applePay = document.getElementById('applePayRadioBtn');
        const idTooltip = container.querySelector('#tooltip');
        if (applePay && idTooltip) {
            return ACC.config.applePayTooltipMessage;
        }

        const newCreditCard = container.closest('#newCreditCardFormId');
        if (newCreditCard) {
            const list = container.closest('.checkout-informal').classList;
            if ([].find.call(list, (className) => className.indexOf('card-number') !== -1)) {
                return ACC.config.tooltipMessage;
            }
            if ([].find.call(list, (className) => className.indexOf('cvv') !== -1)) {
                return ACC.config.tooltipMessageCVVCommon;
            }
        }

        const savedCard = container.closest('.c-saved-card');
        if (savedCard) {
            if (savedCard.querySelector('.card-info__payment').textContent.trim() === 'Amex') {
                return ACC.config.tooltipMessageCvvAmex;
            }
            return ACC.config.tooltipMessageCvvOther;
        }

        return false;
    },

    setPosition(element) {
        const dataPosition = element.dataset.position;
        const matchedPosition = ACC.tooltipPlugin.positions.find(({ placement }) => placement === dataPosition);

        // if no matches use default position
        ACC.tooltipPlugin.placement = matchedPosition ? matchedPosition.placement : 'bottom-start';
        ACC.tooltipPlugin.offset = matchedPosition ? matchedPosition.offset : '-10';
    },

    addListeners(icon, tooltip, id) {
        const { showTooltip, hideTooltip } = ACC.tooltipPlugin;
        tooltip.addEventListener('mouseenter', () => showTooltip(icon, id));
        tooltip.addEventListener('mouseleave', () => hideTooltip(icon, id));
        icon.addEventListener('blur', () => {
            icon.setAttribute('aria-expanded', 'false');
        });

        function toggleAriaExpanded() {
            const currentAriaExpanded = icon.getAttribute('aria-expanded');
            icon.setAttribute('aria-expanded', currentAriaExpanded === 'true' ? 'false' : 'true');
            ACC.tooltipPlugin.updateInstance(id);
        }

        icon.addEventListener('click', toggleAriaExpanded);
        icon.addEventListener('keydown', (e) => {
            e.stopPropagation();
            if (e.key === 'Escape') {
                icon.setAttribute('aria-expanded', 'false');
            }
            ACC.tooltipPlugin.updateInstance(id);
        });
    },

    initPopper(icon, content, id) {
        const { placement, offset } = ACC.tooltipPlugin;
        // eslint-disable-next-line no-undef,no-new
        ACC.tooltipPlugin.instances[id] = new Popper(icon, content, {
            placement,
            modifiers: {
                offset: {
                    enabled: true,
                    offset: `${offset}, 7`,
                },
            },
        });
    },

    updateInstance(id) {
        const instance = ACC.tooltipPlugin.instances[id];
        instance.update();
    },

    showTooltip(element, id) {
        clearTimeout(ACC.tooltipPlugin.timeout[id]);
        element.setAttribute('aria-expanded', 'true');
        ACC.tooltipPlugin.updateInstance(id);
    },

    hideTooltip(element, id) {
        ACC.tooltipPlugin.timeout[id] = setTimeout(() => {
            element.setAttribute('aria-expanded', 'false');
        }, 500);
    },
};
