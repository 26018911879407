(function (root, factory) {
    const pluginName = 'ShowMore';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    const config = {
        expander: '.c-show-more',
        expanderBtn: '.c-show-more button',
        expanderBtnText: '.c-show-more button span',
        showMoreText: 'Show more',
        minHeightText: window.matchMedia('(max-width: 767px)').matches ? 230 : 200,
        blockLabelShow: 'Show the rest of the text describing this product',
        blockLabelHide: 'Hide content that was previously shown',
    };

    const trigger = function () {
        const tab = document.querySelector('.current-tab-content');
        if (!tab) {
            return;
        }

        const expanderComp = tab.querySelector(config.expander);
        if (!expanderComp) {
            return;
        }

        const tabContent = tab.querySelector(config.expander).previousElementSibling;

        if (tabContent.offsetHeight < config.minHeightText) {
            expanderComp.style.display = 'none';
            return;
        }

        if (tabContent.offsetHeight > config.minHeightText) {
            if (tab.querySelector(config.expanderBtnText).innerHTML === config.showMoreText) {
                tabContent.setAttribute('style', `height: ${config.minHeightText}px; overflow: hidden;`);
            }
        }
    };

    const getStickyHeaderHeight = function () {
        const header = document.getElementsByClassName('nav-content-selector')[0];
        return header.offsetHeight || 0;
    };

    const scrollTopWithAnimate = function (scrollToElement, speed) {
        if (!scrollToElement) {
            return;
        }
        // Scroll to desired top offset
        $('html, body').animate({
            scrollTop: Math.round(scrollToElement.getBoundingClientRect().top)
                + window.scrollY - getStickyHeaderHeight(),
        }, speed);
    };

    const shouldScrollToTabs = function (tabs) {
        return Math.round(tabs.getBoundingClientRect().top) !== getStickyHeaderHeight();
    };

    const setVisibleHiddenContent = function (container, state) {
        const tabContent = container.querySelector(config.expander).previousElementSibling;
        if (state) {
            tabContent.setAttribute('style', `height: ${config.minHeightText}px; overflow: hidden;`);
        } else {
            tabContent.removeAttribute('style');
        }
    };

    const setA11yAttributes = function (target, state) {
        target.setAttribute('aria-expanded', state ? 'false' : 'true');
        target.firstChild.innerHTML = state ? 'Show more' : 'Show less';
        const button = target.closest('.c-show-more');
        if (state) {
            button.classList.add('collapsed');
        } else {
            button.classList.remove('collapsed');
        }
    };

    const addListener = function () {
        const showMore = document.querySelectorAll(config.expanderBtn);
        if (!showMore.length) {
            return;
        }

        showMore.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                const expander = e.currentTarget;
                const container = expander.closest('.current-tab-content');
                const targetScrollElement = document.getElementsByClassName('c-tabs-wrapper')[0];
                let timerID;
                const speedAnimation = 300;

                if (expander.getAttribute('aria-expanded') === 'true') {
                    setA11yAttributes(expander, true);

                    if (shouldScrollToTabs(targetScrollElement)) {
                        scrollTopWithAnimate(targetScrollElement, speedAnimation);
                        timerID = setTimeout(() => {
                            setVisibleHiddenContent(container, true);
                            clearTimeout(timerID);
                        }, speedAnimation);
                    } else {
                        setVisibleHiddenContent(container, true);
                    }
                } else {
                    setA11yAttributes(expander, false);
                    scrollTopWithAnimate(targetScrollElement, speedAnimation);
                    setVisibleHiddenContent(container, false);
                }
            });
        });
    };

    /**
     * Plugin Object
     * @constructor
     */
    function Plugin() {
        // Initialize show more component
        this.init();
    }

    /**
     * Plugin prototype
     * @public
     * @constructor
     */
    Plugin.prototype = {
        init() {
            addListener();
            trigger();
        },
        trigger,
    };

    return Plugin;
}));
