ACC.subsctiptionItems = {

    _autoload: [
        'init',
    ],

    init() {
        this.forceHeights();
        this.insertPaddingIfNoSale();
        this.checkUpfrontItems();
        this.bindAddToCartForm();
    },

    bindAddToCartForm() {
        const buttons = document.querySelectorAll('.js-add-to-cart-button');

        buttons.forEach((button) => {
            button.addEventListener('click', () => {
                const dataLayer = window.dataLayer || [];
                const analyticsBody = {
                    isbn: button.dataset.productIsbn,
                    quantity: 1,
                    ratePlanId: button.dataset.productRateplanid || 'not_subscription',
                };

                dataLayer.push({
                    event: 'processComplete',
                    processName: 'add to cart',
                    result: 'success',
                    data: analyticsBody,
                });
            });
        });
    },

    forceHeights() {
        const blocks = document.querySelectorAll('.c-subscription__price-detail');
        let minHeight = 0;

        blocks.forEach((el) => {
            if (el.offsetHeight > minHeight) {
                minHeight = el.offsetHeight;
            }
        });

        blocks.forEach((el) => {
            el.classList.add('js-setMinHeight');
            el.style.minHeight = `${minHeight}px`;
        });
    },

    insertPaddingIfNoSale() {
        const priceBlocks = document.querySelectorAll('.c-subscription__price-block');

        const paddingNeeded = this.isPaddingNeeded(priceBlocks);

        if (paddingNeeded) {
            priceBlocks.forEach((el) => {
                const price = el.querySelector('.c-subscription__discount-price');
                if (!price) {
                    el.classList.add('hasNoDiscount');
                }
            });
        }
    },

    isPaddingNeeded(elements) {
        const discounts = [];
        elements.forEach((el) => discounts.push(el.querySelector('.c-subscription__discount-price')));
        return discounts.some((el) => el);
    },

    checkUpfrontItems() {
        const subscriptionControllers = document.querySelectorAll('.c-subscription__type-chooser');
        const subscriptionType = document.querySelectorAll('.c-subscription__type');
        const subscriptionDetails = document.querySelectorAll('.c-subscription__details .c-subscription__wrap-upfront');
        const subscriptionButtons = document.querySelectorAll('.c-subscription__btn-container');
        const subscriptionDetailsABTesting = document
            .querySelector('[data-abtesting-bundles="true"] .c-subscription__details .c-subscription__wrap-upfront');
        const subscriptionDetailsMobile = document
            .querySelectorAll('.c-subscription__details-mobile .c-subscription__wrap-upfront');
        const isSelected = 'isSelected';
        const isHidden = 'isHidden';
        if (!subscriptionControllers.length || !subscriptionDetails.length || !subscriptionButtons.length) { return; }

        if (subscriptionDetailsABTesting) {
            subscriptionDetailsABTesting.classList.remove(isHidden);
        }

        subscriptionType.forEach((subs) => {
            subs.addEventListener('focusin', () => {
                subs.classList.add('c-subscription__type-focus');
                subs.querySelector('label input').focus();
            });

            subs.addEventListener('focusout', () => {
                if (subs.classList.contains('c-subscription__type-focus')) {
                    subs.classList.remove('c-subscription__type-focus');
                }
                subs.setAttribute('tabindex', 0);
            });

            subs.addEventListener('keydown', (event) => {
                if (event.shiftKey && event.keyCode === 9) {
                    subs.setAttribute('tabindex', -1);
                }
            });
        });

        subscriptionControllers.forEach((subscription, index) => {
            subscription.addEventListener('click', () => {
                subscriptionControllers.forEach((item) => item.classList.remove(isSelected));
                subscriptionButtons.forEach((item) => item.classList.remove(isHidden));
                subscriptionDetailsMobile.forEach((item) => item.classList.remove(isHidden));
                subscription.classList.add(isSelected);
                subscriptionDetails[index].classList.remove(isHidden);
                subscriptionType.forEach((item) => {
                    item.setAttribute('tabindex', -1);
                    item.classList.remove('c-subscription__type-focus');
                });
                subscriptionType[index].setAttribute('tabindex', 0);

                if (subscriptionDetails[index].previousElementSibling) {
                    subscriptionDetails[index].previousElementSibling.classList.add(isHidden);
                    subscriptionButtons[index - 1].classList.add(isHidden);
                } else {
                    subscriptionDetails[index].nextElementSibling.classList.add(isHidden);
                    subscriptionButtons[index + 1].classList.add(isHidden);
                }

                if (subscriptionDetailsMobile[index].previousElementSibling) {
                    subscriptionDetailsMobile[index].previousElementSibling.classList.add(isHidden);
                } else {
                    subscriptionDetailsMobile[index].nextElementSibling.classList.add(isHidden);
                }

                subscription.focus();
            });
        });
        const isActiveSubscription = $('#subscriptionPane').data('active-subscription');
        const isApplicableForSwitchingTitle = $('#subscriptionPane').data('switch-title-applicable');
        const activeControllerIndex = isActiveSubscription === true && !isApplicableForSwitchingTitle
                                        && subscriptionControllers.length > 1 ? 1 : 0;
        subscriptionControllers[activeControllerIndex].click();

        const logo = document.querySelector('#logo-header-pearsonplus');

        if (logo) {
            logo.focus();
            logo.blur();
        }
    },
};
