ACC.cancelSubscription = {
    _autoload: [
        ['triggerCancelSubscriptionModal', $('.js-handler-cancel-subscription-modal').length !== 0],
        ['closeCancelSubscriptionModal', $('.js-close-cancel-subscription-modal').length !== 0],
        ['enableCloseAndSubmitButtons', $('.js-post-cancel-subscription-modal').length !== 0
        && $('.js-close-cancel-subscription-modal').length !== 0],
        ['postCancelSubscriptionForm', !$('.js-post-cancel-subscription-modal').disabled],
        ['closeConfirmationModal', $('.js-cancel-confirmation-modal').length !== 0],
        ['cancelSubscriptionForInternal', $('.js-cancel-subscription-internal').length !== 0],
        ['disableSubmitButtonOnAction', $('.js-post-cancel-subscription-modal').length !== 0],
    ],

    triggerCancelSubscriptionModal() {
        $('.js-handler-cancel-subscription-modal').on('click', (event) => {
            event.preventDefault();
            const subscriptionNumber = event.target.getAttribute('data-subscription-number');
            ACC.cancelSubscription.showCancelSubscriptionModal(subscriptionNumber);
        });
    },

    showCancelSubscriptionModal(subscriptionNumber) {
        const cancelModal = $(`#cancelSubscriptionModal[data-subscription-number="${subscriptionNumber}"]`);
        $modal.open(null, {
            inline: true,
            href: cancelModal,
            className: 'colorbox--upgrade-modal',
            onClosed() {
                $('.cancel-reason').prop('checked', false);
                ACC.cancelSubscription.manageButtonsVisibility(true);
                document.querySelector('body').removeAttribute('style');
                document.querySelector('.js-handler-cancel-subscription-modal').focus();
            },
        });
    },

    closeCancelSubscriptionModal() {
        $('.js-close-cancel-subscription-modal').on('click', (event) => {
            event.preventDefault();
            $modal.close();
        });
    },

    enableCloseAndSubmitButtons() {
        $('.cancel-reason').on('click', () => {
            ACC.cancelSubscription.manageButtonsVisibility(false);
        });
    },

    disableSubmitButtonOnAction() {
        $('.js-post-cancel-subscription-modal').on('click', () => {
            $('.js-post-cancel-subscription-modal').addClass('processing');
            $('.js-post-cancel-subscription-modal').prop('disabled', true);
        });
    },

    addInDataLayerStatusOfTheRequest(status, subscriptionNumber, errorMessage) {
        const dataLayer = window.dataLayer || [];
        const eventName = 'cancelSubscription';
        if (status === 'success') {
            dataLayer.push(
                {
                    event: eventName,
                    result: status,
                    subscriptionId: subscriptionNumber,
                },
            );
        } else {
            dataLayer.push(
                {
                    event: eventName,
                    result: status,
                    message: errorMessage,
                    subscriptionId: subscriptionNumber,
                },
            );
        }
    },

    cancelSubscriptionForInternal() {
        $('.js-cancel-subscription-internal').on('click', (event) => {
            event.preventDefault();
            const subscriptionNumber = event.target.getAttribute('data-subscription-number');
            const cancelSubscriptionUrl = `${ACC.config.encodedContextPath}/account`
                + `/subscriptions/${subscriptionNumber}/cancel`;
            $.ajax({
                url: cancelSubscriptionUrl,
                type: 'DELETE',
                success() {
                    ACC.cancelSubscription.addInDataLayerStatusOfTheRequest('success', subscriptionNumber);
                    window.location.replace(`${ACC.config.encodedContextPath}/account`);
                },
                error(response) {
                    const message = response.responseJSON;
                    ACC.cancelSubscription.addInDataLayerStatusOfTheRequest('fail', subscriptionNumber, message);
                    ACC.globalmessages.callErrorGlobalMessage('text.cancel.subscription.esb.error.banner');
                },
            });
        });
    },

    postCancelSubscriptionForm() {
        $('.js-post-cancel-subscription-modal').on('click', (event) => {
            event.preventDefault();
            const subscriptionNumber = event.target.getAttribute('data-subscription-number');
            const cancelReason = $('.cancel-reason:checked').val();
            const cancelSubscriptionUrl = `${ACC.config.encodedContextPath}/account`
                + `/subscriptions/${subscriptionNumber}/cancel?cancelReason=${cancelReason}`;

            $.ajax({
                url: cancelSubscriptionUrl,
                type: 'DELETE',
                success() {
                    ACC.cancelSubscription.addInDataLayerStatusOfTheRequest('success', subscriptionNumber);
                    ACC.cancelSubscription.showConfirmationModal(subscriptionNumber);
                },
                error(response) {
                    const message = response.responseJSON;
                    ACC.cancelSubscription.addInDataLayerStatusOfTheRequest('fail', subscriptionNumber, message);
                    $modal.close();
                    ACC.globalmessages.callErrorGlobalMessage('text.cancel.subscription.esb.error.banner');
                },
            });
        });
    },

    showConfirmationModal(subscriptionNumber) {
        const confModal = $(`#cancelSubscriptionConfirmationModal[data-subscription-number="${subscriptionNumber}"]`);
        $modal.open(null, {
            inline: true,
            href: confModal,
            className: 'colorbox--upgrade-modal',
            onClosed() {
                window.history.go();
            },
        });
    },

    manageButtonsVisibility(disabled) {
        $('.js-close-cancel-subscription-modal').prop('disabled', disabled);
        $('.js-post-cancel-subscription-modal').prop('disabled', disabled);
    },

    closeConfirmationModal() {
        $('.js-cancel-confirmation-modal').on('click', (e) => {
            e.preventDefault();
            $modal.close();
        });
    },
};
