ACC.applePayHelpers = {

    handleSuccessValidateAddress(event, session) {
        if ($('#updatePaymentSOP').length !== 0) {
            ACC.applePay.updatePaymentMethodToApplePay(event, session);
        } else {
            ACC.applePay.placeApplePayOrder(event, session);
        }
    },

    handleOfacErrorApplePayMethod(data) {
        if ($('#updatePaymentSOP').length !== 0) {
            ACC.applePayHelpers.showOfacAlertApplePayMethod();
        } else {
            ACC.applePayHelpers.showOfacErrorApplePayMethod(data);
        }
    },

    showOfacErrorApplePayMethod(data) {
        ACC.applePayHelpers.failApplePaySession(ACC.applePay.applePaySession);

        ACC.globalmessages.createGlobalMessage({
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: ['text.address.ofac.error'],
                attributes: [data.supportLink],
            }],
        });
    },

    showOfacAlertApplePayMethod() {
        ACC.applePayHelpers.failApplePaySession(ACC.applePay.applePaySession);

        ACC.globalmessages.createGlobalMessage({
            globalMessages: [{
                messageHolder: 'accInfoMsgsInline',
                messages: ['cybersource.validation.payment.error'],
            }],
        });
    },

    completeApplePaySession(session) {
        session.completePayment({
            status: window.ApplePaySession.STATUS_SUCCESS,
        });
    },

    failApplePaySessionWithWrongBillingAddress(session) {
        session.completePayment({
            status: window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS,
        });
    },

    failApplePaySession(session) {
        session.completePayment({
            status: window.ApplePaySession.STATUS_FAILURE,
        });
    },

    showApplePayBlock() {
        if (!window.ApplePaySession) { return; }
        $('.applepay-block').fadeIn();
        ACC.applePayHelpers.showApplePayLegalText();
    },

    hideApplePayBlock() {
        if (!window.ApplePaySession) { return; }
        $('.applepay-block').hide();
        ACC.applePayHelpers.hideApplePayLegalText();
        const savedCardBillingAddressComponent = $('#newCardPlaceOrderForm')
            .find($('.place-order-form'))
            .find('#savedCardBillingAddress');
        savedCardBillingAddressComponent.after($('.c-terms-conditions-area'));
    },

    showApplePayLegalText() {
        $('#readTermsAndConditionsText').hide();
        $('#readTermsAndConditionsApplePayText').removeClass('js-hide-legal-text');
    },

    hideApplePayLegalText() {
        $('#readTermsAndConditionsApplePayText').addClass('js-hide-legal-text');
        $('#readTermsAndConditionsText').fadeIn();
    },

    populateApplePayMessage(component) {
        const applePayMessage = component.attr('data-applepay-message');
        component.text(applePayMessage);
    },

    sendDataLayer() {
        window.dataLayer.push({
            event: 'paymentType',
            payment_type: 'APPLEPAY',
        });
    },
};
