(function (root, factory) {
    const pluginName = 'PearsonPaginationAjax';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    /**
     * Default options
     */
    const defaults = {
        // Initial settings
        current: 0,
        itemsCount: 0,
        pageAmount: 0,
        pageNumber: 0,
        pageCount: 0,
        totalAmount: 0,
        activePageClass: 'isActive',
        mobileScreenSize: 768,
        // Pagination elements
        paginationComponentClass: 'c-paging-p',
        pagesClass: 'c-paging-p__page',
        paginationInfoClass: 'c-paging-p__info',
        previousBtn: 'c-paging-p__prev',
        nextBtn: 'c-paging-p__next',
        holderClass: '',
        dotsClass: 'dot',
        step: 1,
        getData: null,
    };

    let state = {};

    /**
     * Merge defaults with user options
     @param {Object} defaults Default settings
     @param {Object} options User options
     */
    // eslint-disable-next-line no-shadow
    const extend = function (defaults, options) {
        let prop;
        const extended = {};
        // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    /**
     * Set button element in enabled state
     @param {Element} button Button element
     */
    const enableButton = function (button) {
        if (!(button instanceof HTMLElement)) throw new Error('Please provide valid HTML element!');

        button.removeAttribute('disabled');
        button.removeAttribute('aria-disabled');
        button.removeAttribute('tabindex');
    };

    /**
     * Set Button in disabled state
     @param {Element} button Button element
     */
    const disableButton = function (button) {
        if (!(button instanceof HTMLElement)) throw new Error('Please provide valid HTML element!');

        button.setAttribute('disabled', 'disabled');
        button.setAttribute('aria-disabled', 'true');
        button.setAttribute('tabindex', '-1');
    };

    /**
     * Update Buttons state for current page
     @param {Number} currentPage Current page number
     */
    const updateButtons = function (currentPage) {
        const prevBtnSelector = `.${state.paginationComponentClass}>.${state.previousBtn}`;
        const nextBtnSelector = `.${state.paginationComponentClass}>.${state.nextBtn}`;
        const prevButton = document.querySelector(prevBtnSelector);
        const nextButton = document.querySelector(nextBtnSelector);

        switch (true) {
        case (state.pageCount === 1):
            // First page is active
            disableButton(prevButton);
            disableButton(nextButton);
            break;
        case (currentPage === 1):
            // First page is active
            disableButton(prevButton);
            enableButton(nextButton);
            break;
        case (currentPage === state.pageCount):
            // Last page is active
            disableButton(nextButton);
            enableButton(prevButton);
            break;
        default:
            // Previous and next buttons are enabled
            enableButton(prevButton);
            enableButton(nextButton);
        }
    };

    /**
     * Update Pagination Info text content for current page
     @param {Number} currentPage Current page number
     */
    const updatePaginationInfo = function (currentPage) {
        const paginationInfo = document.querySelector(`.${state.paginationInfoClass}`);
        if (!paginationInfo) throw new Error('Pagination info element missing!');

        paginationInfo.textContent = `${currentPage} of ${state.pageCount}`;
    };

    /**
     * Set page in active state
     @param {Number} currentPage Current page number
     */
    const updateActivePage = function (currentPage) {
        const pagesSelector = `.${state.paginationComponentClass} .${state.pagesClass}`;
        const pages = document.querySelectorAll(pagesSelector);
        const paginationIndex = document.querySelector('.paginationIndex');

        if (!pages.length) throw new Error('Page elements missing!');

        pages.forEach((el) => {
            if (Number(el.textContent) === currentPage) {
                if (paginationIndex) {
                    paginationIndex.innerHTML = Number(el.textContent);
                }
                el.classList.add(state.activePageClass);
                el.setAttribute('aria-current', 'true');
            } else {
                el.classList.remove(state.activePageClass);
                el.removeAttribute('aria-current');
            }
        });
    };

    /**
     * Set pagination visibility based on page number
     @param {Number} pageNumber Number of page
     */
    const goTo = function (pageNumber) {
        state.getData(pageNumber);
    };

    /**
     * Decrement current page
     */
    const previousPage = function () {
        let currentPage = state.current;
        // eslint-disable-next-line no-plusplus
        goTo(--currentPage);
    };

    /**
     * Increment current page
     */
    const nextPage = function () {
        let currentPage = state.current;
        // eslint-disable-next-line no-plusplus
        goTo(++currentPage);
    };

    /**
     * Create dots element - Used in truncated version of pagination
     */
    const createDots = function () {
        const listItem = document.createElement('li');
        const dots = document.createElement('span');
        dots.textContent = '...';
        dots.classList.add(state.pagesClass);
        dots.classList.add(state.dotsClass);
        listItem.appendChild(dots);
        return listItem;
    };

    /**
     * Create dots element - Used in truncated version of pagination
     */
    const createPaginationInfo = function () {
        const listItem = document.createElement('li');
        const info = document.createElement('span');
        info.textContent = '';
        info.classList.add(state.paginationInfoClass);
        listItem.appendChild(info);
        return listItem;
    };

    /**
     * Create page element with specific number
     * @param {Number} pageNumber Number of page
     */
    const createPage = function (pageNumber) {
        const startPage = pageNumber - 1;
        const page = document.createElement('li');
        const pageLink = document.createElement('a');
        pageLink.textContent = `${pageNumber}`;
        pageLink.classList.add(state.pagesClass);
        pageLink.href = `?page=${startPage}`;
        pageLink.ariaLabel = `Go to Page ${pageNumber}`;
        page.appendChild(pageLink);

        pageLink.addEventListener('click', (e) => {
            e.preventDefault();
            goTo(startPage);
        });

        return page;
    };

    /**
     * Set pagination and items visibility based on page number
     @param {Number} currentPage Current page number
     @param {Number} pageCount Total number of pages
     @param {Number} step Number of pages around active page
     */
    const createPages = function (currentPage, pageCount, step) {
        const paginationWrapper = document.querySelector(`.${state.paginationComponentClass}`);
        const pagesHolder = document.createDocumentFragment();
        const pageList = document.createElement('ol');
        pageList.classList.add('paginationList');

        let i;
        switch (true) {
        case pageCount < (step * 2 + 4):
            //  No need for truncation
            /*  Example
                 *  < 1 2 '3' 4 5 6 >
                 */

            for (i = 1; i <= pageCount; i += 1) {
                pageList.appendChild(createPage(i));
                pageList.appendChild(createPaginationInfo());
            }
            break;
        case currentPage < (step * 2 + 2):
            //  Somewhere at the beginning
            /*  Example
                 *  < 1 2 '3' ... 20 >
                 */
            for (i = 1; i <= (step * 2 + 1); i += 1) {
                pageList.appendChild(createPage(i));
            }

            pageList.appendChild(createDots());
            pageList.appendChild(createPage(pageCount));
            pageList.appendChild(createPaginationInfo());
            break;
        case currentPage > (pageCount - step * 2 - 1):
            /* Somewhere at the  end
                 *  Example
                 *  < 1 ...  16 17 '18' 19 20 >
                 */
            pageList.appendChild(createPage(1));
            pageList.appendChild(createDots());

            for (i = (pageCount - step * 2); i <= pageCount; i += 1) {
                pageList.appendChild(createPage(i));
            }
            pageList.appendChild(createPaginationInfo());
            break;
        default:
            /*  Somewhere in the middle
                 *  Example
                 *  < 1 ... 5 6 '7' 8 9 ... 20 >
                 */
            pageList.appendChild(createPage(1));
            pageList.appendChild(createDots());

            for (i = (currentPage - step); i <= (currentPage + step); i += 1) {
                pageList.appendChild(createPage(i));
            }

            pageList.appendChild(createDots());
            pageList.appendChild(createPage(pageCount));
            pageList.appendChild(createPaginationInfo());
            break;
        }

        pagesHolder.appendChild(pageList);

        paginationWrapper.insertBefore(
            pagesHolder,
            paginationWrapper.lastElementChild,
        );
    };

    /**
     * Create pagination prev button element
     */
    const createButtonPrev = function () {
        const button = document.createElement('a');
        button.classList.add(state.previousBtn);
        button.href = `?page=${state.current}`;
        button.ariaLabel = `Go to previous page`;

        button.addEventListener('click', (e) => {
            e.preventDefault();
            previousPage();
        });

        return button;
    };

    /**
     * Create pagination next button element
     */
    const createButtonNext = function () {
        const button = document.createElement('a');
        button.classList.add(state.nextBtn);
        button.href = `?page=${state.current + 1}`;
        button.ariaLabel = `Go to next page`;

        button.addEventListener('click', (e) => {
            e.preventDefault();
            nextPage();
        });

        return button;
    };

    /**
     * Create pagination prev and next buttons
     */
    const createButtons = function () {
        const prev = createButtonPrev();
        const next = createButtonNext();

        const paginationWrapper = document.querySelector(`.${state.paginationComponentClass}`);
        paginationWrapper.prepend(prev);
        paginationWrapper.append(next);
    };

    const createHeading = function () {
        const mainSpan = document.createElement('span');
        const paginationWrapper = document.querySelector(`.${state.paginationComponentClass}`);
        mainSpan.setAttribute('aria-live', 'polite');
        mainSpan.setAttribute('role', 'status');
        const heading = document.createElement('h4');
        heading.classList.add('screenreader');
        heading.innerHTML = 'Pagination - Page ';
        const innerSpan = document.createElement('span');
        innerSpan.classList.add('screenreader', 'paginationIndex');
        mainSpan.appendChild(heading).appendChild(innerSpan);
        paginationWrapper.prepend(mainSpan);
    }

    /**
     * Create pagination holder element
     */
    const creatPagingHolder = function () {
        const nav = document.createElement('nav');
        const wrapper = document.querySelector(`.${state.holderClass}`);
        nav.classList.add(state.paginationComponentClass);
        nav.setAttribute('aria-label', 'pagination');

        wrapper.appendChild(nav);
    };

    /**
     * Remove pagination holder element children's
     */
    const removePagingHolderChildren = function () {
        const nav = document.querySelector(`.${state.paginationComponentClass}`);

        nav.innerHTML = '';
    };

    /**
     * Set initial state
     @param {Object} options Merged default and user settings
     @param {Number} innerWidth Available screen width
     */
    const setState = function (options, innerWidth) {
        state = options;
        state.isMobile = options.mobileScreenSize >= innerWidth;
    };

    /**
     * Build dynamically pages, prev/next buttons, pagination info
     */
    const buildTemplates = function () {
        creatPagingHolder();
        createPages(state.pageNumber, state.pageCount, state.step);
        createButtons();
        createHeading();
        updateActivePage(state.pageNumber);
        updateButtons(state.pageNumber);
        updatePaginationInfo(state.pageNumber);
    };

    /**
     * Initialize pagination
     @param {Object} options Merged default and user settings
     */
    const initialize = function (options) {
        setState(options, window.innerWidth);
        buildTemplates();
    };

    /**
     * Plugin Object
     @param {Object} options User options
     @constructor
     */

    function Plugin(options) {
        this.options = extend(defaults, options);
        this.init(this.options);
    }

    /**
     * Plugin prototype
     @public
     */

    Plugin.prototype.init = function (options) {
        initialize(options);
    };

    Plugin.prototype.update = function (pageNumber) {
        state.pageNumber = pageNumber + 1;
        state.current = pageNumber;
        removePagingHolderChildren();
        createPages(state.pageNumber, state.pageCount, state.step);
        createButtons();
        createHeading();
        updateActivePage(state.pageNumber);
        updateButtons(state.pageNumber);
        updatePaginationInfo(state.pageNumber);
    };

    return Plugin;
}));
