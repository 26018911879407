ACC.applePay = {
    _autoload: [
        ['bindApplePay', $('#applePayRadioBtn').length !== 0],
        ['accessibilityApplePayBtnAccess', $('#pearsonApplePayButton').length !== 0],
    ],

    billingAddressForm: {},
    applePaySession: null,

    bindApplePay() {
        const applePayMethodComponent = $('.applePayMethod');
        if (window.ApplePaySession) {
            ACC.applePayHelpers.populateApplePayMessage($('.js-apply-pay-message'));
            ACC.applePay.bindApplePayButton();
            applePayMethodComponent.fadeIn();

            const component = $('#applePayRadioBtn');
            component.on('change', function () {
                if (component.is(':checked')) {
                    const selectedComponent = $(this);
                    $('.selected').removeClass('selected');
                    selectedComponent.parent().toggleClass('selected');
                    $('#applePayPlaceOrderForm').append($('.c-terms-conditions-area'));
                    $("[data-radio-child-group='verificationType']").hide();
                    $('#payPalType').prop('checked', false);
                }
            });
        } else {
            applePayMethodComponent.remove();
            ACC.applePayHelpers.hideApplePayLegalText();
        }
    },

    bindApplePayButton() {
        $('#pearsonApplePayButton').click(() => {
            ACC.applePayHelpers.sendDataLayer();
            const supportedCountries = ACC.config.applePaySupportedCountries.split(',');
            const paymentRequest = {};
            $.ajax({
                url: `${ACC.config.encodedContextPath}/applePayOrderInfo/initial`,
                dataType: 'json',
                async: false,
            })
                .then((data) => {
                    paymentRequest.countryCode = ACC.config.applePayCountryCode;
                    paymentRequest.currencyCode = ACC.config.currentCurrency;
                    paymentRequest.lineItems = [
                        {
                            label: ACC.config.applePaySubtotalMessage,
                            amount: data.orderTotals.monthlyPlan,
                        },
                        {
                            label: ACC.config.applePayDiscountsMessage,
                            amount: data.orderTotals.discountAmount,
                        },
                        {
                            label: ACC.config.applePayEstimatedTaxMessage,
                            amount: data.orderTotals.tax,
                        },
                    ];
                    paymentRequest.total = {
                        label: ACC.config.applePayTotalMessage,
                        amount: data.orderTotals.totalPriceWithTax,
                    };
                    if (supportedCountries.length > 0 && supportedCountries[0]) {
                        paymentRequest.supportedCountries = supportedCountries;
                    }
                    paymentRequest.requiredBillingContactFields = ['postalAddress'];
                    paymentRequest.requiredShippingContactFields = ['email', 'phone'];
                    paymentRequest.supportedNetworks = data.supportedNetworks;
                    paymentRequest.merchantCapabilities = ['supports3DS'];

                    if (ACC.config.shouldAddRecurringInfoApplePay) {
                        paymentRequest.recurringPaymentRequest = {
                            paymentDescription: ACC.config.applePayRecurringPaymentDescription,
                            regularBilling: {
                                paymentTiming: 'recurring',
                            },
                            managementURL: ACC.config.applePayRecurringPaymentManagementUrl,
                        };

                        if (!data.orderTotals.totalPrice) {
                            const label = ACC.config.applePayRecurringUpdatePaymentLabel;
                            const billingAgreement = ACC.config.applePayRecurringUpdatePaymentBillingAgreement;

                            paymentRequest.recurringPaymentRequest.billingAgreement = billingAgreement;
                            paymentRequest.recurringPaymentRequest.regularBilling.label = label;
                            paymentRequest.recurringPaymentRequest.regularBilling.amount = 0;
                        } else if (data.isUpfrontCheckout) {
                            ACC.applePay.setUpRecurringPaymentForUpfront(paymentRequest, data);
                        } else {
                            const regularBillingLabel = ACC.config.applePayRecurringPaymentRecurringLabel;

                            paymentRequest.recurringPaymentRequest.regularBilling.label = regularBillingLabel;
                            paymentRequest.recurringPaymentRequest.regularBilling.amount = data.orderTotals.monthlyPlan;

                            if (data.isPaidTierSubscription) {
                                ACC.applePay.setUpRecurringPaymentForPaidTier(paymentRequest, data);
                            }
                            if (data.isAutoRenewUpgradeCheckout && !data.promotionData) {
                                ACC.applePay.setUpRecurringPaymentAutoRenewUpgradeWithoutPromo(paymentRequest, data);
                            }
                            if (data.promotionData) {
                                ACC.applePay.setUpRecurringPaymentPromotion(paymentRequest, data);
                            }
                        }
                    }

                    const session = new ApplePaySession(3, paymentRequest);
                    ACC.applePay.applePaySession = session;

                    session.oncancel = (event) => {
                        console.log('apple pay session oncancel called');
                        console.log(event);
                    };

                    ACC.applePay.onValidateMerchant(session);

                    ACC.applePay.onPaymentMethodSelected(session);

                    ACC.applePay.onPaymentAuthorized(session);

                    session.begin();
                });
        });
    },

    getSubscriptionEndDate(date, months) {
        const currentDate = date.getDate();

        date.setMonth(date.getMonth() + +months);
        // Adjust for edge cases like February in leap years and shorter months
        if (date.getDate() !== currentDate) {
            date.setDate(0);
        }

        return date;
    },

    setUpRecurringPaymentForUpfront(paymentRequest, data) {
        const subscriptionEndDate = ACC.applePay.getSubscriptionEndDate(new Date(), data.subscriptionDuration);
        const oneTimeBillingLabel = ACC.config.applePayRecurringPaymentOnePaymentLabel;

        paymentRequest.recurringPaymentRequest.regularBilling.label = oneTimeBillingLabel;
        paymentRequest.recurringPaymentRequest.regularBilling.amount = data.orderTotals.totalPrice;
        paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentEndDate = subscriptionEndDate;
        paymentRequest.recurringPaymentRequest.regularBilling.amount = data.orderTotals.totalPrice;
        paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentIntervalCount = data.subscriptionDuration;
    },

    setUpRecurringPaymentForPaidTier(paymentRequest, data) {
        paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentIntervalCount = data.subscriptionDuration;
    },

    setUpRecurringPaymentAutoRenewUpgradeWithoutPromo(paymentRequest, data) {
        const currentSubEndDate = new Date(data.currentSubscriptionEndDate);
        paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentStartDate = currentSubEndDate;
    },

    setUpRecurringPaymentPromotion(paymentRequest, data) {
        const { getSubscriptionEndDate, setTrialRecurringPaymentIntervalCount } = ACC.applePay;
        const { applePayRecurringPaymentDiscountedLabel } = ACC.config;
        const {
            promotionData,
            isAutoRenewUpgradeCheckout,
            isPaidTierSubscription,
            orderTotals,
            currentSubscriptionEndDate,
        } = data;
        const { duration } = promotionData;

        paymentRequest.recurringPaymentRequest.trialBilling = {
            label: applePayRecurringPaymentDiscountedLabel,
            paymentTiming: 'recurring',
            amount: orderTotals.totalPrice,
            recurringPaymentStartDate: '',
            recurringPaymentEndDate: '',
        };

        const { regularBilling, trialBilling } = paymentRequest.recurringPaymentRequest;

        let recurringPaymentStartDate = new Date();

        if (isAutoRenewUpgradeCheckout) {
            recurringPaymentStartDate = new Date(currentSubscriptionEndDate);
            trialBilling.recurringPaymentStartDate = new Date(currentSubscriptionEndDate);
        }

        recurringPaymentStartDate = getSubscriptionEndDate(recurringPaymentStartDate, duration);
        regularBilling.recurringPaymentStartDate = recurringPaymentStartDate;

        if (isPaidTierSubscription) {
            setTrialRecurringPaymentIntervalCount(paymentRequest, data);
            trialBilling.recurringPaymentEndDate = recurringPaymentStartDate;
        }
    },

    setTrialRecurringPaymentIntervalCount(paymentRequest, data) {
        paymentRequest.recurringPaymentRequest.trialBilling.recurringPaymentIntervalCount = data.subscriptionDuration;
    },

    onValidateMerchant(session) {
        session.onvalidatemerchant = (event) => {
            const { validationURL } = event;
            $.ajax(`${ACC.config.encodedContextPath}/applePay/createSession`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                data: {
                    validationURL,
                    CSRFToken: $('input[name="CSRFTokvalidate merchanten"]').val(),
                },
                dataType: 'text',
            }).then((res) => {
                session.completeMerchantValidation(JSON.parse(res));
            }).catch(() => {
                session.abort();
                ACC.globalmessages.callErrorGlobalMessage('apple.pay.place.order.payment.error');
            });
        };
    },

    onPaymentMethodSelected(session) {
        session.onpaymentmethodselected = (event) => {
            const redactBillingContact = event.paymentMethod.billingContact;
            if (redactBillingContact !== undefined) {
                ACC.applePay.collectRedactBillingAddressForm(redactBillingContact);
                ACC.applePay.validateBillingAddress(
                    () => ACC.applePay.getCurrentOrderInfo(session),
                );
            } else {
                ACC.applePay.getCurrentOrderInfo(session);
            }
        };
    },

    onPaymentAuthorized(session) {
        session.onpaymentauthorized = (event) => {
            const paymentBillingContact = event.payment.billingContact;
            const paymentShippingContact = event.payment.shippingContact;
            ACC.applePay.fillBillingAddressForm(paymentBillingContact, paymentShippingContact);
            ACC.applePay.validateBillingAddress((response) => {
                if ($(response).find('#isSuccess').val() === 'true') {
                    ACC.applePay.fillFormWithMaskedPaymentCart(event);
                    ACC.secureacceptance.cartValidation(
                        () => ACC.applePayHelpers.handleSuccessValidateAddress(event, session),
                        ACC.applePayHelpers.handleOfacErrorApplePayMethod,
                        ACC.applePay.billingAddressForm,
                    );
                } else {
                    ACC.applePayHelpers.failApplePaySessionWithWrongBillingAddress(session);
                }
            });
        };
    },

    updatePaymentMethodToApplePay(event, session) {
        // eslint-disable-next-line max-len
        $.ajax(`${ACC.config.encodedContextPath}/paymentmethod/updatePaymentMethodToApplePay?subscriptionNumber=${ACC.config.currentSubscriptionNumber}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                CSRFToken: ACC.config.CSRFToken,
            },
            data: JSON.stringify(event.payment),
            dataType: 'text',
        }).then(() => {
            ACC.applePayHelpers.completeApplePaySession(session);
            const successURL = document.getElementById('pearsonApplePayButton').getAttribute('data-success-url');
            window.location = successURL;
        }).catch(() => {
            ACC.applePayHelpers.failApplePaySession(session);
            ACC.globalmessages.callErrorGlobalMessage('apple.pay.place.order.payment.error');
        });
    },

    placeApplePayOrder(event, session) {
        const io_bb_value = $('#io_blackbox').length ? $('#io_blackbox').val() : '';

        const authorizationData = JSON.parse(JSON.stringify(event.payment));
        authorizationData.deviceId = io_bb_value;

        $.ajax(`${ACC.config.encodedContextPath}/applePay/placeOrder`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                CSRFToken: ACC.config.CSRFToken,
            },
            data: JSON.stringify(authorizationData),
            dataType: 'json',
        }).then((res) => {
            if (res.orderCode && res.decision === 'ACCEPT') {
                ACC.applePayHelpers.completeApplePaySession(session);
                window.location = `${ACC.config.encodedContextPath}/checkout/orderConfirmation/${res.orderCode}`;
            } else {
                ACC.applePayHelpers.failApplePaySession(session);
                window.location = `${ACC.config.encodedContextPath}/checkout/multi/cybs-applePay/payment/error`;
            }
        }).catch((err) => {
            console.log(err);
            ACC.applePayHelpers.failApplePaySession(session);
            window.location = `${ACC.config.encodedContextPath}/checkout/multi/cybs-applePay/payment/error`;
        });
    },

    fillFormWithMaskedPaymentCart(event) {
        const cardName = event.payment.token.paymentMethod.displayName;
        ACC.applePay.billingAddressForm.cartNumberMasked = `************${cardName.substring(cardName.length - 4)}`;
    },

    fillBillingAddressForm(billingContact, shippingContact) {
        if (!billingContact) { return; }
        const form = ACC.applePay.billingAddressForm;
        form.billTo_firstName = billingContact.givenName;
        form.billTo_lastName = billingContact.familyName;
        const billingAddressLines = billingContact.addressLines;
        form.billTo_street1 = billingAddressLines[0] || '';
        form.billTo_street2 = billingAddressLines[1] || '';
        form.billTo_country = billingContact.countryCode;
        form.billTo_city = billingContact.locality;
        form.billTo_postalCode = billingContact.postalCode;
        form.billTo_state = billingContact.administrativeArea;

        if (shippingContact) {
            form.billTo_email = shippingContact.emailAddress || '';
            form.billTo_phoneNumber = shippingContact.phoneNumber || '';
        }
    },

    collectRedactBillingAddressForm(billingContact) {
        if (!billingContact) { return; }
        const form = ACC.applePay.billingAddressForm;

        form.billTo_country = billingContact.countryCode;
        form.billTo_city = billingContact.locality;
        form.billTo_postalCode = billingContact.postalCode;
        form.billTo_state = billingContact.administrativeArea;
    },

    validateBillingAddress(onThenFunction) {
        $.ajax(`${ACC.config.encodedContextPath}/checkout/validate/applepay/billingAddress`, {
            method: 'post',
            headers: {
                CSRFToken: ACC.config.CSRFToken,
            },
            data: ACC.applePay.billingAddressForm,
            dataType: 'html',
            async: false,
        }).then(onThenFunction);
    },

    getCurrentOrderInfo(session) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/applePayOrderInfo/current`,
            dataType: 'json',
            async: false,
            success(data) {
                const newPaymentTotal = {
                    newLineItems: [
                        {
                            label: ACC.config.applePaySubtotalMessage,
                            amount: data.orderTotals.monthlyPlan,
                        },
                        {
                            label: ACC.config.applePayDiscountsMessage,
                            amount: data.orderTotals.discountAmount,
                        },
                        {
                            label: ACC.config.applePayEstimatedTaxMessage,
                            amount: data.orderTotals.tax,
                        },
                    ],
                    newTotal: {
                        label: ACC.config.applePayTotalMessage,
                        amount: data.orderTotals.totalPriceWithTax,
                    },
                };
                session.completePaymentMethodSelection(newPaymentTotal);
            },
        });
    },

    accessibilityApplePayBtnAccess() {
        if (window.ApplePaySession) {
            const applePayBtn = document.getElementById('pearsonApplePayButton');
            applePayBtn.addEventListener('keypress', (event) => {
                const code = event.keyCode;
                if (code === 13 || code === 32) {
                    applePayBtn.click();
                }
            });
        }
    },
};
