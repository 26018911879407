ACC.modal = {

    _autoload: [
        'modalAutoOpen',
    ],

    modalAutoOpen() {
        document.querySelectorAll('.js-modal-auto-opened').forEach((element) => {
            if (element.getAttribute('is-modal-auto-opened') === 'true') {
                element.click();
            }
        });
    },
};
