ACC.cart = {

    _autoload: [
        'bindHelp',
        'cartRestoration',
        'bindCartPage',
        'bindMultiDEntryRemoval',
        'bindMultidCartProduct',
        'bindApplyVoucherUpgradeSubscription',
        'bindApplyVoucherAutoRenewSubscription',
        'bindRemoveVoucherUpgradeSubscription',
        'bindRemoveVoucherAutoRenewSubscription',
        ['bindApplyVoucher', $('#js-voucher-apply-btn')],
        ['bindToReleaseVoucher', $('#js-applied-vouchers')],
        ['bindQuoteCreate', $('.js-create-quote-button').length !== 0],
        ['bindApplyPostalCode', $('#js-apply-postal-code-btn').length !== 0],
    ],

    bindHelp() {
        $(document).on('click', '.js-cart-help', function (e) {
            e.preventDefault();
            const title = $(this).data('help');
            ACC.colorbox.open(title, {
                html: $('.js-help-popup-content').html(),
            });
        });
    },

    cartRestoration() {
        $('.cartRestoration').click(function () {
            const sCartUrl = $(this).data('cartUrl');
            window.location = sCartUrl;
        });
    },

    bindCartPage() {
        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.js-show-editable-grid', function (event) {
            ACC.cart.populateAndShowEditableGrid(this, event);
        });
    },

    bindMultiDEntryRemoval() {
        $(document).on('click', '.js-submit-remove-product-multi-d', function () {
            const itemIndex = $(this).data('index');
            const $form = $(`#updateCartForm${itemIndex}`);
            const initialCartQuantity = $form.find('input[name=initialQuantity]');
            const cartQuantity = $form.find('input[name=quantity]');

            cartQuantity.val(0);
            initialCartQuantity.val(0);

            const method = $form.attr('method') ? $form.attr('method').toUpperCase() : 'GET';
            $.ajax({
                url: $form.attr('action'),
                data: $form.serialize(),
                type: method,
                success() {
                    window.location.reload();
                },
                error(xht, textStatus, ex) {
                    alert(`Failed to remove quantity. Error details [${xht}, ${textStatus}, ${ex}]`);
                },

            });
        });
    },

    populateAndShowEditableGrid(element) {
        const readOnly = $(element).data('readOnlyMultidGrid');
        const itemIndex = $(element).data('index');
        const grid = $(`#ajaxGrid${itemIndex}`);

        const gridEntries = $(`#grid${itemIndex}`);
        const strSubEntries = gridEntries.data('sub-entries');
        const arrSubEntries = strSubEntries.split(',');
        const firstVariantCode = arrSubEntries[0].split(':')[0];

        $(element).toggleClass('open');

        const targetUrl = gridEntries.data('target-url');

        const mapCodeQuantity = {};
        for (let i = 0; i < arrSubEntries.length; i += 1) {
            const arrValue = arrSubEntries[i].split(':');
            const val0 = arrValue[0];
            const val1 = arrValue[1];
            mapCodeQuantity[val0] = val1;
        }

        if (grid.children('#cartOrderGridForm').length > 0) {
            grid.slideToggle('slow');
        } else {
            const method = 'GET';
            $.ajax({
                url: targetUrl,
                data: { productCode: firstVariantCode, readOnly },
                type: method,
                success(data) {
                    // eslint-disable-next-line no-undef
                    grid.html(DOMPurify.sanitize(data));
                    $('#ajaxGrid').removeAttr('id');
                    const $gridContainer = grid.find('.product-grid-container');
                    const numGrids = $gridContainer.length;
                    for (let i = 0; i < numGrids; i += 1) {
                        ACC.cart.getProductQuantity($gridContainer.eq(i), mapCodeQuantity, i);
                    }
                    grid.slideDown('slow');
                    ACC.cart.coreCartGridTableActions(element, mapCodeQuantity);
                    ACC.productorderform.coreTableScrollActions(grid.children('#cartOrderGridForm'));
                },
                error(xht, textStatus, ex) {
                    alert(`Failed to get variant matrix. Error details [${xht}, ${textStatus}, ${ex}]`);
                },

            });
        }
    },

    coreCartGridTableActions(element, mapCodeQuantity) {
        ACC.productorderform.bindUpdateFutureStockButton('.update_future_stock_button');
        ACC.productorderform.bindVariantSelect($('.variant-select-btn'), 'cartOrderGridForm');
        const itemIndex = $(element).data('index');
        const skuQuantityClass = '.sku-quantity';

        let quantityBefore = 0;
        const grid = $(`#ajaxGrid${itemIndex} .product-grid-container`);

        grid.on('focusin', skuQuantityClass, function () {
            quantityBefore = jQuery.trim(this.value);

            $(this).parents('tr').next('.variant-summary').remove();
            if ($(this).parents('table').data(ACC.productorderform.selectedVariantData)) {
                ACC.productorderform.selectedVariants = $(this)
                    .parents('table')
                    .data(ACC.productorderform.selectedVariantData);
            } else {
                ACC.productorderform.selectedVariants = [];
            }

            if (quantityBefore === '') {
                quantityBefore = 0;
                this.value = 0;
            }
        });

        grid.on('focusout keypress', skuQuantityClass, function (event) {
            const code = event.keyCode || event.which || event.charCode;

            if (code !== 13 && code !== undefined) {
                return;
            }

            let quantityAfter = 0;
            let gridLevelTotalPrice = '';

            const indexPattern = '[0-9]+';
            const currentIndex = parseInt($(this).attr('id').match(indexPattern), 10);

            this.value = ACC.productorderform.filterSkuEntry(this.value);

            quantityAfter = jQuery.trim(this.value);
            const variantCode = $(`input[id='cartEntries[${currentIndex}].sku']`).val();

            if (Number.isNaN(jQuery.trim(this.value))) {
                this.value = 0;
            }

            if (quantityAfter === '') {
                quantityAfter = 0;
                this.value = 0;
            }

            const $gridTotalValue = grid.find(`[data-grid-total-id=total_value_${currentIndex}]`);
            const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();

            if (quantityAfter > 0) {
                gridLevelTotalPrice = ACC.productorderform
                    .formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantityAfter, 10));
            }

            $gridTotalValue.html(gridLevelTotalPrice);

            const _this = this;
            const priceSibling = $(this).siblings('.price');
            const propSibling = $(this).siblings('.variant-prop');
            const currentSkuId = $(this).next('.td_stock').data('sku-id');
            const currentBaseTotal = $(this).siblings('.data-grid-total');

            if (this.value !== quantityBefore) {
                let newVariant = true;
                ACC.productorderform.selectedVariants.forEach((item, index) => {
                    if (item.id === currentSkuId) {
                        newVariant = false;

                        if (_this.value === '0' || _this.value === 0) {
                            ACC.productorderform.selectedVariants.splice(index, 1);
                        } else {
                            ACC.productorderform.selectedVariants[index].quantity = _this.value;
                            ACC.productorderform.selectedVariants[index].total = ACC.productorderform
                                .updateVariantTotal(priceSibling, _this.value, currentBaseTotal);
                        }
                    }
                });

                if (newVariant && this.value > 0) {
                    // update variantData
                    ACC.productorderform.selectedVariants.push({
                        id: currentSkuId,
                        size: propSibling.data('variant-prop'),
                        quantity: _this.value,
                        total: ACC.productorderform.updateVariantTotal(priceSibling, _this.value, currentBaseTotal),
                    });
                }
            }
            ACC.productorderform.showSelectedVariant($(this).parents('table'));
            if (this.value > 0 && this.value !== quantityBefore) {
                $(this).parents('table').addClass('selected');
            } else if (ACC.productorderform.selectedVariants.length === 0) {
                $(this).parents('table').removeClass('selected').find('.variant-summary')
                    .remove();
            }

            if (quantityBefore !== quantityAfter) {
                const method = 'POST';
                $.ajax({
                    url: `${ACC.config.encodedContextPath}/cart/updateMultiD`,
                    data: { productCode: variantCode, quantity: quantityAfter, entryNumber: -1 },
                    type: method,
                    success(data) {
                        ACC.cart.refreshCartData(data, -1, quantityAfter, itemIndex);
                        // eslint-disable-next-line no-param-reassign
                        mapCodeQuantity[variantCode] = quantityAfter;
                    },
                    error(xhr) {
                        const redirectUrl = xhr.getResponseHeader('redirectUrl');
                        const connection = xhr.getResponseHeader('Connection');
                        // check if error leads to a redirect
                        if (redirectUrl !== null) {
                            window.location = redirectUrl;
                            // check if error is caused by a closed connection
                        } else if (connection === 'close') {
                            window.location.reload();
                        }
                    },
                });
            }
        });
    },

    refreshCartData(cartData, entryNum, quantity, itemIndex) {
        // if cart is empty, we need to reload the whole page
        if (cartData.entries.length === 0) {
            window.location.reload();
        } else {
            let form;

            if (entryNum === -1) {
                let qnt = quantity;
                form = $(`.js-qty-form${itemIndex}`);
                const productCode = form.find('input[name=productCode]').val();

                qnt = 0;
                let entryPrice = 0;
                for (let i = 0; i < cartData.entries.length; i += 1) {
                    const entry = cartData.entries[i];
                    if (entry.product.code === productCode) {
                        qnt = entry.quantity;
                        entryPrice = entry.totalPrice;
                        ACC.cart.updateEntryNumbersForCartMenuData(entry);
                        break;
                    }
                }

                if (qnt === 0) {
                    window.location.reload();
                } else {
                    form.find('.qtyValue').html(qnt);
                    form.parent().parent().find('.js-item-total').html(entryPrice.formattedValue);
                }
            }

            ACC.cart.refreshCartPageWithJSONResponse(cartData);
        }
    },

    refreshCartPageWithJSONResponse(cartData) {
        // refresh mini cart
        ACC.minicart.updateMiniCartDisplay();
        $('.js-cart-top-totals').html($('#cartTopTotalSectionTemplate').tmpl(cartData));
        $('div .cartpotproline').remove();
        $('div .cartproline').remove();
        $('.js-cart-totals').remove();
        $('#ajaxCartPotentialPromotionSection').html($('#cartPotentialPromotionSectionTemplate').tmpl(cartData));
        $('#ajaxCartPromotionSection').html($('#cartPromotionSectionTemplate').tmpl(cartData));
        $('#ajaxCart').html($('#cartTotalsTemplate').tmpl(cartData));
        ACC.quote.bindQuoteDiscount();
    },

    updateEntryNumbersForCartMenuData(entry) {
        let entryNumbers = '';
        $.each(entry.entries, (index, subEntry) => {
            if (index !== 0) {
                entryNumbers += ';';
            }
            entryNumbers += subEntry.entryNumber;
        });
        $('.js-execute-entry-action-button').data('actionEntryNumbers', entryNumbers);
    },

    getProductQuantity(gridContainer, mapData) {
        const tables = gridContainer.find('table');

        $.each(tables, (i, currentTable) => {
            const skus = jQuery.map($(currentTable).find("input[type='hidden'].sku"), (o) => o.value);
            const quantities = jQuery.map($(currentTable).find("input[type='textbox'].sku-quantity"), (o) => o);
            const selectedVariants = [];

            $.each(skus, (index, skuId) => {
                const quantity = mapData[skuId];
                if (quantity !== undefined) {
                    quantities[index].value = quantity;

                    const indexPattern = '[0-9]+';
                    const currentIndex = parseInt(quantities[index].id.match(indexPattern), 10);
                    const gridTotalValue = gridContainer.find(`[data-grid-total-id=total_value_${currentIndex}]`);
                    let gridLevelTotalPrice = '';
                    const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();
                    if (quantity > 0) {
                        gridLevelTotalPrice = ACC.productorderform
                            .formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantity, 10));
                    }
                    gridTotalValue.html(gridLevelTotalPrice);

                    selectedVariants.push({
                        id: skuId,
                        size: $(quantities[index]).siblings('.variant-prop').data('variant-prop'),
                        quantity,
                        total: gridLevelTotalPrice,
                    });
                }
            });

            if (selectedVariants.length !== 0) {
                $.tmpl(ACC.productorderform.$variantSummaryTemplate, {
                    variants: selectedVariants,
                }).appendTo($(currentTable).addClass('selected'));
                $(currentTable)
                    .find('.variant-summary .variant-property')
                    .html($(currentTable).find('.variant-detail').data('variant-property'));
                $(currentTable).data(ACC.productorderform.selectedVariantData, selectedVariants);
            }
        });
    },

    bindMultidCartProduct() {
        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.showQuantityProduct', function (event) {
            ACC.multidgrid.populateAndShowGrid(this, event, true);
        });

        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.showQuantityProductOverlay', function (event) {
            ACC.multidgrid.populateAndShowGridOverlay(this, event);
        });
    },

    bindApplyVoucher() {
        $(document).on('submit', '#applyVoucherForm', function (e) {
            e.preventDefault();
            const form = $(this);
            const url = form.attr('action');
            const voucherCode = $.trim($('#js-voucher-code-text').val());
            if (voucherCode !== '' && voucherCode.length > 0) {
                ACC.cart.handleApplyVoucher(url, form.serialize());
            }
        });
    },

    bindApplyVoucherUpgradeSubscription() {
        $(document).on('submit', '#applyVoucherFormUpgradeSubscription', function (e) {
            e.preventDefault();
            const form = this;
            const url = form.getAttribute('action');
            const voucherCode = document.getElementById('js-voucher-code-text').value.trim();
            if (voucherCode !== '' && voucherCode.length > 0) {
                ACC.cart.handleApplyVoucherUpgradeSubscription(url, $(form));
            }
        });
    },

    bindApplyVoucherAutoRenewSubscription() {
        ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
        $(document).on('submit', '#cboxContent #applyVoucherFormAutoRenewSubscription', function (e) {
            e.preventDefault();
            const form = this;
            const url = form.getAttribute('action');
            const voucherCode = document.querySelector('#cboxContent #js-voucher-code-text-renew').value.trim();
            if (voucherCode !== '' && voucherCode.length > 0) {
                ACC.cart.handleApplyVoucherAutoRenewSubscription(url, $(form));
                $('#cboxContent #js-voucher-code-text-renew').val('');
            }
        });
    },

    handleApplyVoucherUpgradeSubscription(url, form) {
        // eslint-disable-next-line no-undef
        const productCode = DOMPurify.sanitize($("[name='productCode']", form).val());
        ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(true);
        ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(true);
        $.ajax({
            url,
            data: form.serialize(),
            type: 'POST',
            success(data) {
                $(`#upgradeSubscriptionTotals_${productCode}`).html(data);
                $(`#cboxLoadedContent #upgradeSubscriptionTotals_${productCode}`).html(data);
                const toggleBlock = document.querySelector('.popup_upgrade_subscription .c-cart-voucher');
                if (toggleBlock) {
                    toggleBlock.classList.remove('hidden-block');
                }
                ACC.cartvoucher.toggleBlock();
                ACC.upgradeSubscription.getProratedAmount();
            },
            error() {
                ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
                ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                //               Error message to be determined, will be added in scope of story in future sprint
                console.error('Failed to apply promocode');
            },
        });
    },

    performAutorenewVoucherCall(urlValue, form) {
        $.ajax({
            url: urlValue,
            async: false,
            data: form,
            type: 'POST',
            success(data) {
                ACC.cartvoucher.toggleBlock();
                const cartVoucher = $('.c-cart-voucher');
                const validationContainer = $('.js-voucher-validation-container');
                const voucherDetails = $('.c-cart-voucher__applied');
                const voucherField = $('#cboxContent #js-voucher-code-text-renew');
                const promoCodeTopDescription = $('.auto-renew-modal__top-text');
                const promoCodeBottomDescription = $('.auto-renew-modal__bottom-text');
                const hasErrorClass = 'has-error';
                const isSuccess = 'isSuccess';
                if ($(data).find('.help-block__error').html().trim()) {
                    cartVoucher.addClass(hasErrorClass);
                } else {
                    cartVoucher.removeClass(hasErrorClass);
                }
                if ($(data).find('.help-block__success').html().trim()) {
                    voucherField.addClass(isSuccess);
                } else {
                    voucherField.removeClass(isSuccess);
                }
                validationContainer.html($(data).find('.js-voucher-validation-container').html());
                voucherDetails.html($(data).find('#js-applied-vouchers-renew').html());
                promoCodeTopDescription.html($(data).find('.auto-renew-modal__top-text').html());
                promoCodeBottomDescription.html($(data).find('.auto-renew-modal__bottom-text').html());
                ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                const discountFormattedValue = $(data).find('#discountFormattedValue').html();
                const totalPrice = $(data).find('#cartTotal').html();
                const discountDuration = $(data).find('#discountDuration').html();
                const appliedVoucher = $(data).find('#appliedVoucher').html();
                const promoMask = $(data).find('#promoMask').html();
                const autoRenewConfirmationPopup = $('#popup_auto_renew_on_confirmation');
                if (discountFormattedValue && discountDuration && appliedVoucher) {
                    if (promoMask && autoRenewConfirmationPopup) {
                        $('#discountName').html(ACC.config.autoRenewConfirmationEmbededPromoCodeValue);
                    } else if (promoMask) {
                        $('#discountName').html(`${promoMask}:`);
                    } else {
                        $('#discountName').html(`${appliedVoucher}:`);
                    }
                    $('#discountName').removeAttr('hidden');
                    const discountValue = ACC.config.autoRenewConfirmationDiscountValue
                        .replaceAll('{0}', discountFormattedValue)
                        .replaceAll('{1}', discountDuration);
                    $('#discountValue').html(discountValue);
                    $('#discountValue').removeAttr('hidden');
                    $('#discountDurationLegalText').html(discountDuration);
                    $('#cartTotalPrice').html(totalPrice);
                    $('#discountedLegalText').removeAttr('hidden');
                    $('#standardLegalText').attr('hidden', 'hidden');
                } else {
                    $('#discountName').attr('hidden', 'hidden');
                    $('#discountValue').attr('hidden', 'hidden');
                    if (!appliedVoucher) {
                        $('#standardLegalText').removeAttr('hidden');
                    }
                }
                voucherField.focus();
            },
            error() {
                ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
                ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(false);
                console.error('Failed to apply promocode');
            },
        });
    },

    handleApplyVoucherAutoRenewSubscription(url, form) {
        ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(true);
        ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(true);
        ACC.cart.performAutorenewVoucherCall(url, form.serialize());
    },

    handleReleaseVoucherAutoRenewSubscription(url, form) {
        ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
        ACC.subscriptionHelpers.handleVoucherRemoveActionDisabledState(true);
        ACC.cart.performAutorenewVoucherCall(url, form.serialize());
    },

    bindRemoveVoucherUpgradeSubscription() {
        $(document).on('submit', '.remove-voucher-upgrade-subscription', function (e) {
            e.preventDefault();
            const form = $(this).closest('form');
            const url = form.attr('action');
            ACC.cart.handleApplyVoucherUpgradeSubscription(url, form);
        });
    },

    bindRemoveVoucherAutoRenewSubscription() {
        $(document).on('submit', '.remove-voucher-autorenew-subscription', function (e) {
            e.preventDefault();
            const form = $(this).closest('form');
            const url = form.attr('action');
            const voucherField = $('#cboxContent #js-voucher-code-text-renew');
            const voucherCode = $('#cboxContent #voucherCode').val();
            voucherField.val(voucherCode);
            ACC.cart.handleReleaseVoucherAutoRenewSubscription(url, form);
        });
    },

    isTaxCalculated() {
        return !ACC.secureacceptance.isCardSaved() && ACC.secureacceptance.shouldPlaceOrderButtonBeEnabled();
    },

    handleApplyVoucher(url, formData) {
        $.ajax({
            url,
            data: formData,
            type: 'POST',
            success(data) {
                $('.orderTotals').html(data);
                if (ACC.cart.isTaxCalculated()) {
                    $('.taxMessage').html('Tax');
                } else {
                    $('.taxMessage').html('Estimated tax');
                }
                ACC.cart.updateLegalText();
                ACC.secureacceptance.reloadSopForm();
                document.getElementById('js-voucher-code-text').value = '';

                const errorField = document.getElementById('promo-code-error');
                const successField = document.getElementById('promo-code-success');
                const promoInput = document.querySelector('.js-voucher-code');
                const voucherApplyBtn = document.getElementById('js-voucher-apply-btn');
                if (errorField.innerHTML.trim()) {
                    promoInput.setAttribute('aria-describedby', errorField.id);
                    promoInput.setAttribute('aria-invalid', 'true');
                } else if (successField.innerHTML.trim()) {
                    promoInput.setAttribute('aria-describedby', successField.id);
                    promoInput.setAttribute('aria-invalid', 'false');
                } else {
                    const block = document.querySelector('.c-cart-voucher.toggle-block');
                    block.classList.add('active');
                    promoInput.setAttribute('aria-invalid', 'false');
                }
                ACC.cartvoucher.toggleBlock();
                ACC.cartvoucher.toggleAriaExpandedAttr('.toggle-block-opener');
                voucherApplyBtn.focus();
            },
            error(data) {
                $('.orderTotals').html(data);
                ACC.secureacceptance.reloadSopForm();
            },
        });
    },

    removePromoMessageA11YWithTimeout(target) {
        let count = 0;

        count = setTimeout(() => {
            if (target) {
                // eslint-disable-next-line no-param-reassign
                target.textContent = '';
                clearTimeout(count);
            }
        }, 3000);
    },

    updateLegalText() {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/legal-text`,
            type: 'GET',
            success(data) {
                $('.c-terms-conditions-area').html(data);
                const applePayComponent = $('#applePayRadioBtn');
                if (applePayComponent.length !== 0 && applePayComponent.is(':checked')) {
                    ACC.applePayHelpers.showApplePayLegalText();
                } else {
                    ACC.applePayHelpers.hideApplePayLegalText();
                }
            },
        });
    },

    validateEmptyInput(btn) {
        let empty = false;

        if ($(this).val().trim() === '') { empty = true; }

        if (empty) {
            btn.attr('disabled', 'disabled');
        } else {
            btn.removeAttr('disabled');
        }
    },

    bindApplyPostalCode() {
        const btn = $('#js-apply-postal-code-btn');
        const input = $('#js-cart-apply-postal-code-text');
        const formPostalCode = $('#applyCartPostalCode');
        const component = $('.c-cart-tax');

        // Validate input
        input.on('input', function () {
            ACC.cart.validateEmptyInput.call(this, btn);
        });

        formPostalCode.on('submit', function (e) {
            e.preventDefault();
            // Prevent many ajax calls
            btn.attr('disabled', 'disabled');

            const form = $(this);
            const postalCode = $.trim(input.val());

            $.ajax({
                url: ACC.config.encodedContextPath + form.attr('action'),
                data: { postalCode },
                type: form.attr('method'),
                success(data) {
                    const responseData = data;
                    if (responseData.validationResult !== null) {
                        component.find('.help-block__error').html(responseData.validationResult);
                        component.addClass('has-error active');
                    } else {
                        component.find('.c-cart-tax__wrap').addClass('is-estimated');
                        component.find('.toggle-block-area').slideUp();
                        component.removeClass('has-error active');
                        component.find('.c-cart-tax__text-label').html('Edit');
                        component.find('.c-cart-tax__title').html('Estimated tax for');
                        component.find('.c-cart-tax__estimated-tax').html(responseData.totalTax);
                        component.find('.c-cart-tax__postal-code').html((responseData.postalCode));
                        $('.js-cart-totals').find('.js-total-price')
                            .html((responseData.totalPriceWithTax));
                    }

                    btn.removeAttr('disabled');
                },
                error(response) {
                    // eslint-disable-next-line no-undef
                    component.find('.help-block__error').html(DOMPurify.sanitize((response.responseText)));
                    btn.removeAttr('disabled');
                },
            });
        });
    },

    bindToReleaseVoucher() {
        const selector = '.c-cart-voucher__form-remove:not(.remove-voucher-autorenew-subscription, '
            + '.remove-voucher-upgrade-subscription)';
        $(document).on('submit', selector, function (e) {
            e.preventDefault();
            const form = $(this).closest('form');
            const url = form.attr('action');
            ACC.cart.handleReleaseVoucher(url, form.serialize());
        });
    },

    handleReleaseVoucher(url, formData) {
        $.ajax({
            url,
            data: formData,
            type: 'POST',
            success(data) {
                $('.orderTotals').html(data);
                if (ACC.cart.isTaxCalculated()) {
                    $('.taxMessage').html('Tax');
                } else {
                    $('.taxMessage').html('Estimated tax');
                }
                const block = document.querySelector('.c-cart-voucher.toggle-block');
                block.classList.add('active');

                ACC.cart.updateLegalText();
                ACC.secureacceptance.reloadSopForm();
                ACC.cartvoucher.toggleBlock();
                ACC.cartvoucher.toggleAriaExpandedAttr('.toggle-block-opener');
                ACC.cart.setPromoCodeRemovedMsg();

                const input = document.querySelector('.js-voucher-code');
                input.setAttribute('aria-invalid', 'false');
                input.focus();
            },
            error(data) {
                $('.orderTotals').html(data);
                ACC.secureacceptance.reloadSopForm();
            },
        });
    },

    setPromoCodeRemovedMsg() {
        const promoRemoveMsgHolder = document.getElementById('promo-action-msg');
        if (promoRemoveMsgHolder) {
            promoRemoveMsgHolder.textContent = promoRemoveMsgHolder.dataset.removedMessage;
        }
    },

    bindQuoteCreate() {
        ACC.cart.handlePromotionModal({
            actionButtonSelector: '.js-create-quote-button',
            modalWindowSelector: '#js-quote-promotion-modal',
            modalTitleDataAttributeName: 'quote-modal-title',
            actionButtonUrlDataAttributeName: 'target-url',
            actionButtonCustomerUrlDataAttributeName: 'update-customer-url',
            modalExpirationDataAttributeName: 'quote-promotion-expiration',
            cancelButtonSelector: '#js-quote-promotion-modal #cancelButton',
            continueButtonSelector: '#js-quote-promotion-modal #continueButton',
        });
    },

    handlePromotionModal(options) {
        const modalWindow = $(options.modalWindowSelector);
        const targetUrl = $(options.actionButtonSelector).data(options.actionButtonUrlDataAttributeName);
        const updateCustomerUrl = $(options.actionButtonSelector)
            .data(options.actionButtonCustomerUrlDataAttributeName);

        $(options.actionButtonSelector).click(function (e) {
            e.preventDefault();
            const expiration = modalWindow.data(options.modalExpirationDataAttributeName);
            if (expiration) {
                if (options.initializeCallback) {
                    options.initializeCallback();
                }
                $modal.open(this);
            } else {
                ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
            }
        });

        $(options.cancelButtonSelector).click($modal.close);

        $(options.continueButtonSelector).click((event) => {
            event.preventDefault();
            $modal.close();
            ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
        });
    },
};
