ACC.autoRenewSubscription = {

    _autoload: [
        ['checkUrlParamForAutoRenew', document.querySelector('.page-subscriptionDetailsLayout')],
        ['showAutoRenewModal', $('.js-handler-auto-renew-modal').length !== 0],
        ['closeAutoRenewModal', $('.js-close-auto-renew-modal').length !== 0],
    ],

    options: {
        autoRenewSubscriptionUrlParam: 'autorenew_subscription',
        promoCodeUrlParam: 'promoCode',
    },

    checkUrlParamForAutoRenew() {
        const urlParams = ACC.subscriptionHelpers.getUrlParam();

        if (urlParams && urlParams.has(ACC.autoRenewSubscription.options.autoRenewSubscriptionUrlParam)
            && $('#product-code-renew').val() && $('#subscription-number-renew').val()) {
            ACC.autoRenewSubscription.showAutoRenewModal(true);
        }
    },

    loadAutoRenewPromotion() {
        const productCodeValue = $('#product-code-renew').val();
        const subscriptionNumberValue = $('#subscription-number-renew').val();
        if (ACC.config.isApplyAutoRenewPromoCodeEnabled
            && ACC.subscriptionHelpers.lastLoadedPromo !== 'AUTORENEW'
            && productCodeValue) {
            const url = `${ACC.config.encodedContextPath}/voucher/autorenew/promo/load`;
            const form = { productCode: productCodeValue, subscriptionNumber: subscriptionNumberValue };
            ACC.cart.performAutorenewVoucherCall(url, form);
            ACC.subscriptionHelpers.lastLoadedPromo = 'AUTORENEW';
            ACC.subscriptionHelpers.validateVoucherCodeTextInput();
        }
    },

    showAutoRenewModal(hasURLParams) {
        const target = document.getElementsByClassName('js-handler-auto-renew-modal');
        const formButton = $('#updateSubscriptionAutoRenewalForm button').not('.js-close-auto-renew-modal');
        const elements = Array.prototype.slice.call(target);
        const urlParams = ACC.subscriptionHelpers.getUrlParam();

        if (hasURLParams) {
            $modal.open(null, {
                inline: true,
                href: '#autoRenewOn',
                onOpen() {
                    formButton.prop('disabled', true);
                    ACC.autoRenewSubscription.loadAutoRenewPromotion();
                    formButton.prop('disabled', false);
                },
                onLoad() {
                    ACC.autoRenewSubscription.removeHiddenClass();
                    if (urlParams.has(ACC.autoRenewSubscription.options.promoCodeUrlParam)) {
                        document.querySelector('#js-voucher-code-text-renew')
                            .value = urlParams.get(ACC.autoRenewSubscription.options.promoCodeUrlParam);
                        ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
                        setTimeout(() => document.getElementsByClassName('toggle-block-opener')[0].click());
                    }
                },
            });
        }

        elements.forEach((el) => {
            el.addEventListener('click', function () {
                $modal.open(this, {
                    onOpen() {
                        formButton.prop('disabled', true);
                        ACC.autoRenewSubscription.loadAutoRenewPromotion();
                        formButton.prop('disabled', false);
                    },
                    onLoad() {
                        ACC.autoRenewSubscription.removeHiddenClass();
                        setTimeout(() => { // In order to wait for appearance of elements on modal
                            const cartVoucherBlock = document
                                .querySelector('#autoRenewOn .c-cart-voucher:not(.hidden-block)');
                            let cartVoucherItem;
                            if (cartVoucherBlock) {
                                cartVoucherItem = cartVoucherBlock.querySelector('.c-cart-voucher__item');
                            }
                            if ((cartVoucherItem && cartVoucherBlock)
                                || (cartVoucherBlock && cartVoucherBlock.classList.contains('has-error'))) {
                                ACC.cartvoucher.openVoucherOnLoad(cartVoucherBlock);
                            }
                        });
                    },
                });
            });
        });

        const form = $('#updateSubscriptionAutoRenewalForm');
        const button = form.find('button').not('.js-close-auto-renew-modal');
        const autorenew = form.find("input[name='autorenew']").val();
        button.off('click').on('click', function (e) {
            e.preventDefault();
            $(this).prop('disabled', true);
            const bookstoreAutorenew = $(this).data('is-bookstore-autorenew');
            const isPaidTier = $(this).data('is-paid-tier');
            if (!bookstoreAutorenew) {
                $(this).addClass('processing');
            }

            $.post({
                url: form.attr('action'),
                data: form.serialize(),
                success(data) {
                    if (isPaidTier) {
                        $modal.close();
                        window.location.reload();
                    }
                    if (bookstoreAutorenew) {
                        window.location.href = ACC.config.encodedContextPath + data;
                    } else if (autorenew === 'true') {
                        ACC.autoRenewSubscription.showAutoRenewOnConfirmationModal(data);
                    } else {
                        ACC.autoRenewSubscription.dataLayerPush();
                        window.history.go();
                    }
                },
                error(data) {
                    $modal.close();
                    ACC.globalmessages.callErrorGlobalMessage(data.responseJSON);
                },
            });
        });
    },

    dataLayerPush() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
            { event: 'auto_renew', cancel: 'success' },
        );
    },

    removeHiddenClass() {
        // Removing hidden class after loading of modal window
        new Promise((resolve) => {
            resolve();
        }).then(() => {
            const autoRenewPromo = document
                .querySelector('#cboxLoadedContent #autoRenewOn .c-cart-voucher');
            if (autoRenewPromo) {
                autoRenewPromo.classList.remove('hidden-block');
            }
        });
    },

    closeAutoRenewModal() {
        $('.js-close-auto-renew-modal').click((event) => {
            event.preventDefault();
            $modal.close();
        });
    },

    showAutoRenewOnConfirmationModal() {
        $modal.open(null, {
            html: $('#popup_auto_renew_on_confirmation'),
            className: 'colorbox--upgrade-modal',
            onLoad() {
                $('#colorbox').animate({
                    scrollTop: 0,
                }, 50);
            },
            onClosed() {
                window.location.reload();
            },
        });
    },
};
