ACC.abtesting = {
    handleABFlag(name, callback) {
        let abFlag = false;

        const setABFlag = (newValue) => {
            abFlag = newValue;
            if (callback) {
                callback(abFlag);
            }
        };

        const handleCustomEvent = () => {
            if (typeof window === 'undefined') return;
            if (window.abtesting && window.abtesting[name] !== undefined) {
                setABFlag(window.abtesting[name]);
            }
        };

        // Listen for the custom event
        window.addEventListener('abFlagChanged', handleCustomEvent);

        // Clean up the event listener
        const cleanup = () => {
            window.removeEventListener('abFlagChanged', handleCustomEvent);
        };

        // Initial check
        handleCustomEvent();

        return { value: abFlag, cleanup };
    },
};
