ACC.applePayMethod = {
    // For CI testing
    sendOrderPlaceAdditionalData() {
        const isConsentCheckboxChecked = $('#consentCheckBox').is(':checked');
        const io_bb_value = $('#io_blackbox').length ? $('#io_blackbox').val() : '';
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/sendOrderPlaceAdditionalData`,
            data: {
                consent: isConsentCheckboxChecked,
                deviceId: io_bb_value,
            },
            cache: false,
        });
    },
};
