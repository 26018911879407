ACC.silentorderpost = {

    _autoload: [
        'bindUseDeliveryAddress',
        'bindSubmitSilentOrderPostForm',
        'bindCreditCardAddressForm',
        ['bindSavedCards', $('.savedCardRadioBtn').length !== 0],
        'bindSavedCardAddYourCardAgain',
        'selectErrorCard',
        'selectedCard',
        'preselectCard',
        'bindSilentOrderPostForm',
        ['validateUpdatePaymentForm', $('#updatePaymentSOP').length !== 0],
        ['updateBillingAddressForm', $('#billingCountrySelector').length && !$('#useDeliveryAddress').length]],

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    selectErrorCard() {
        const selectedCardId = $('#selectedCardId').val();
        if (typeof selectedCardId === 'undefined' || selectedCardId === '') return;

        const card = $(`#savedCard-${selectedCardId}`);
        ACC.silentorderpost.checkRadioBlock(card);
    },

    selectedCard() {
        const radioBtn = $('.savedCardRadioBtn');
        if (radioBtn.is(':checked')) {
            const checkedRadio = $('.creditCardDetails').find('input:checked');
            ACC.silentorderpost.checkRadioBlock(checkedRadio);
        }
    },

    bindSilentOrderPostForm() {
        $('#placeOrder').click(() => {
            window.dataLayer.push(
                {
                    event: 'paymentType',
                    payment_type: 'CARD',
                },
            );
            if (typeof PREFERED_PAYMENT_DATA !== 'undefined' && PREFERED_PAYMENT_DATA) {
                const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
                const card = PREFERED_PAYMENT_DATA.find((el) => el.paymentId === savedCardId);
                if (card) {
                    const sopForm = ACC.secureacceptance.getSopForm();
                    sopForm.find('#merchant_defined_data97').val(true);
                }
            }
        });
    },

    checkRadioBlock(checkedRadio) {
        if (checkedRadio.attr('id') === 'savedCard-newCard') {
            ACC.silentorderpost.showPaymentForm();
            // check if shipping address is present
            if ($('.checkout-order-summary-list-heading').length) {
                const useDeliveryAddress = $('#useDeliveryAddress');
                ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
            }
        } else {
            const savedCardId = checkedRadio.data('savedcardid');
            const card = $(`#savedCard-${savedCardId}`);

            $(`#cardCvv-${savedCardId}`).css('display', 'flex');
            $(`#removeEntry_${savedCardId}`).show();
            $('#savedCardBillingAddressData').html(card.data('cardbillingaddress'));

            ACC.silentorderpost.showSavedCardBillingAddress();
            ACC.silentorderpost.addEventToRemoveCard(savedCardId);
        }

        checkedRadio
            .prop('checked', true)
            .closest('.c-radiobtn')
            .addClass('selected');
        ACC.silentorderpost.showPlaceOrderBtn();
    },

    bindUseDeliveryAddress() {
        const component = $('#useDeliveryAddress');
        if (!component.length) return;
        component.on('change', () => {
            ACC.silentorderpost.enableSaveCardCheckBox();
            if (component.is(':checked')) {
                ACC.silentorderpost.hideBillingAddressForm();
                ACC.silentorderpost.preFillBillingForm();
            } else {
                ACC.silentorderpost.showBillingAddressForm();
                ACC.silentorderpost.clearAddressForm();
                ACC.silentorderpost.enableAddressForm();
            }
        });
        ACC.silentorderpost.toggleBillingAddressForm(component);
    },

    bindSavedCards() {
        const component = $('.savedCardRadioBtn');
        component.on('change', function () {
            if (component.is(':checked')) {
                const selectedComponent = $(this);
                const wrapper = $('.checkout__payment-option-wrapper');
                ACC.silentorderpost.removeCardMessageValidation();
                $('.selected').removeClass('selected');
                wrapper.removeClass('isSelected');
                selectedComponent.parent().toggleClass('selected');
                selectedComponent.closest('.checkout__payment-option-wrapper').toggleClass('isSelected');

                if (selectedComponent.attr('id') === 'savedCard-newCard'
                    || selectedComponent.attr('id') === 'updateCard-newCard') {
                    ACC.silentorderpost.showNewCardBlock(selectedComponent.attr('id'));
                } else {
                    ACC.silentorderpost.showSavedCardBlock(selectedComponent);
                }

                $("[data-radio-child-group='verificationType']").hide();
                $('#payPalType').prop('checked', false);
                if ($('.applePayMethod').length !== 0) {
                    $('#applePayRadioBtn').prop('checked', false);
                    ACC.applePayHelpers.hideApplePayBlock();
                }

                $('#payinternType').prop('checked', false);
                ACC.silentorderpost.showPlaceOrderBtn();
            }
        });
    },

    showNewCardBlock(id) {
        const useDeliveryAddress = $('#useDeliveryAddress');

        $('#newCardPlaceOrderForm').append($('.place-order-form'));
        ACC.silentorderpost.hideSavedCardBillingAddress();
        ACC.silentorderpost.hideSavedCardCvvInput();
        ACC.silentorderpost.hideRemoveCardButton();
        ACC.silentorderpost.showPaymentForm();

        if (id === 'savedCard-newCard') {
            ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
        }
    },

    showSavedCardBlock(selectedComponent) {
        const savedCardId = selectedComponent.data('savedcardid');
        $(`#savedCardPlaceOrderForm-${savedCardId}`).append($('.place-order-form'));
        $('#savedCardBillingAddressData').html(selectedComponent.data('cardbillingaddress'));
        ACC.silentorderpost.showSavedCardBillingAddress();
        ACC.silentorderpost.hideSavedCardCvvInput();
        ACC.silentorderpost.hideRemoveCardButton();
        $(`#cardCvv-${savedCardId}`).css('display', 'flex');
        $(`#removeEntry_${savedCardId}`).show();
        ACC.silentorderpost.addEventToRemoveCard(savedCardId);
        ACC.silentorderpost.hidePaymentForm();
        ACC.silentorderpost.updateBillingAddressFromSavedCard(savedCardId, selectedComponent.data('countryisocode'));
        if ($('#updatePaymentSOP').length !== 0) {
            ACC.silentorderpost.attachUpdatePaymentHandlers(selectedComponent);
        }
        $('.js-track-checkoutstep').removeAttr('data-alternate-message');
    },

    updateBillingAddressForChosenSavedCardAndRecalculateOrder() {
        const checkedCardRadioBtn = $('.savedCardRadioBtn:checked');

        if (checkedCardRadioBtn.length === 0 || checkedCardRadioBtn.attr('id') === 'savedCard-newCard'
            || checkedCardRadioBtn.attr('id') === 'updateCard-newCard') {
            return;
        }
        const savedCardId = checkedCardRadioBtn.data('savedcardid');
        const countryIsoCode = checkedCardRadioBtn.data('countryisocode');

        ACC.silentorderpost.updateBillingAddressFromSavedCard(savedCardId, countryIsoCode);
    },

    updateBillingAddressFromSavedCard(savedCardId, countryIsoCode) {
        ACC.secureacceptance.wrapWithOneByOneExecutionHandling((deferred) => {
            const options = {
                countryIsoCode,
                useDeliveryAddress: false,
                savedCardId,
            };

            ACC.silentorderpost.displayCreditCardAddressForm(options, () => {
                ACC.silentorderpost.fillCountrySelectBox(countryIsoCode);
                ACC.silentorderpost.updateBillingAddressForSavedCardAndRecalculateOrder(deferred, savedCardId);
            });
        })();
    },

    updateBillingAddressForSavedCardAndRecalculateOrder(recalculateOrderDeferred, savedCardId) {
        const isUpdatePaymentPage = document.querySelector('.page-update-payment-method');
        const form = ACC.config.isUnifiedWalletEnabled
            ? ACC.unifiedWallet.setBillingAddressForSavedCard(cardData, savedCardId)
            : ACC.secureacceptance.collectBillingAddressForm();
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}`
            + '/checkout/multi/validate/updateBillingAddressForSubscriptionSavedCard',
            data: form,
            dataType: 'html',
            async: true,
            success(data) {
                // update order totals displayed to the customer
                $('.orderTotals').html(data);
                if (!isUpdatePaymentPage) {
                    ACC.secureacceptance.reloadSopForm();
                }
                recalculateOrderDeferred.resolve();
            },
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        });
    },

    bindSavedCardAddYourCardAgain() {
        $('.savedCardAddYourCardAgain').on('click', (e) => {
            e.preventDefault();
            $('.savedCardRadioBtn').prop('checked', false);
            $('#savedCard-newCard').prop('checked', true);
            $('.selected').removeClass('selected');
            $('.isSelected').removeClass('isSelected');
            $('#savedCard-newCard').parent().toggleClass('selected');
            $('#savedCard-newCard').closest('.checkout__payment-option-wrapper').toggleClass('isSelected');
            $('#newCardPlaceOrderForm').append($('.place-order-form'));
            ACC.silentorderpost.hideSavedCardBillingAddress();
            ACC.silentorderpost.hideSavedCardCvvInput();
            ACC.silentorderpost.hideRemoveCardButton();
            ACC.silentorderpost.showPaymentForm();
            const useDeliveryAddress = $('#useDeliveryAddress');
            ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
            ACC.checkoutPreValid.init();
        });
    },

    addEventToRemoveCard(savedCardId) {
        const component = $(`#removeCardButton_${savedCardId}`);
        if (!component.length) return;
        component.on('click', (e) => {
            e.preventDefault();

            // Ajax call to remove the card
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/${savedCardId}/removeCard`,
                async: false,
                error() {
                    const messageKey = 'checkout.multi.summary.card.remove.error';
                    const globalMessageRequest = {
                        globalMessages: [{
                            messageHolder: 'accErrorMsgs',
                            messages: [messageKey],
                        }],
                    };
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                },
                success() {
                    // used window replace in order not to allow the user to go back
                    window.location.replace(`${ACC.config.encodedContextPath}/checkout/multi/summary/enter`);
                },
                timeout: 3000, // sets request timeout to 3 seconds
            });
        });
    },

    preFillBillingForm() {
        const options = {
            countryIsoCode: $('#useDeliveryAddressData').data('countryisocode'),
            useDeliveryAddress: true,
        };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.useDeliveryAddressSelected);
        ACC.silentorderpost.disableAddressForm();
    },

    preFillBillingFormForQuoteCheckout() {
        const options = {
            countryIsoCode: $('#useDeliveryAddressData').data('countryisocode'),
            useDeliveryAddress: false,
        };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
    },
    preFillBillingFormWhenPaymentError() {
        const options = {
            countryIsoCode: $('#useBillingAddressData').data('countryisocode'),
            useDeliveryAddress: false,
            hasPaymentErrors: true,
        };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
    },

    bindSubmitSilentOrderPostForm() {
        $('.submit_silentOrderPostForm').click(function () {
            ACC.common.blockFormAndShowProcessingMessage($(this));
            $('.billingAddressForm').filter(':hidden').remove();
            ACC.silentorderpost.enableAddressForm();
            $('#silentOrderPostForm').submit();
        });
    },

    bindCycleFocusEvent() {
        $('#lastInTheForm').blur(() => {
            $('#silentOrderPostForm [tabindex$="10"]').focus();
        });
    },

    isEmpty(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === '') return true;
        return false;
    },

    disableAddressForm() {
        $('input[id^="address\\."]').prop('disabled', true);
        $('select[id^="address\\."]').prop('disabled', true);
        $('select.form-control').prev().addClass('disabled');
    },

    enableAddressForm() {
        $('input[id^="address\\."]').prop('disabled', false);
        $('select[id^="address\\."]').prop('disabled', false);
        $('select.form-control').prev().removeClass('disabled');
    },

    clearAddressForm() {
        $('input[id^="address\\."]').val('');
        $('select[id^="address\\."]').filter(function () {
            return !this.id.match(/country/);
        }).val('');
    },

    fillCountrySelectBox(countryIsoCode) {
        const code = countryIsoCode || $('#useDeliveryAddressData').data('countryisocode');
        $('#address\\.country').val(code);
    },

    useDeliveryAddressSelected() {
        if ($('#useDeliveryAddress').is(':checked')) {
            ACC.silentorderpost.fillCountrySelectBox();
            ACC.silentorderpost.disableAddressForm();
        } else {
            ACC.silentorderpost.clearAddressForm();
            ACC.silentorderpost.enableAddressForm();
        }
    },

    enableSaveCardCheckBox() {
        const isWorldwideCountrySelected = $('#worldwideCountrySelected').data('worldwide-country-selected');
        const cardSaveCheckBox = $('#card_save_payment_info');
        if (isWorldwideCountrySelected === true) {
            cardSaveCheckBox.prop('checked', false);
            cardSaveCheckBox.attr('disabled', 'disabled');
            $('.js-card-save-payment-info-alert').show();
        } else {
            cardSaveCheckBox.removeAttr('disabled');
            $('.js-card-save-payment-info-alert').hide();
        }
    },

    bindCreditCardAddressForm() {
        $('#billingCountrySelector :input').on('change', ACC.silentorderpost.updateBillingAddressForm);
    },

    validateUpdatePaymentForm() {
        const countrySelection = $('#address\\.country option:selected').val();
        const options = {
            countryIsoCode: countrySelection,
            useDeliveryAddress: false,
        };
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.updatePayment.validateForm);
    },

    updateBillingAddressForm() {
        if ($('#updatePaymentSOP').length) { return; }
        const countrySelection = $('#address\\.country option:selected').val();
        const options = {
            countryIsoCode: countrySelection,
            useDeliveryAddress: false,
        };
        ACC.silentorderpost.displayCreditCardAddressForm(options);
    },

    displayCreditCardAddressForm(options, callback) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/multi/sop/billingaddressform`,
            async: true,
            data: options,
            dataType: 'html',
            beforeSend() {
                $('#billingAddressForm').html(ACC.silentorderpost.spinner);
            },
        }).done((data) => {
            $('#billingAddressForm').html(data);
            if (typeof callback === 'function') {
                callback.call();
            }
            ACC.silentorderpost.enableSaveCardCheckBox();
            if ($('#silentOrderPostForm').length) {
                ACC.secureacceptance.bindBillingAddressListeners();
                ACC.updatePayment.addCvvHandler();
            }
            ACC.phonemasking.bind($('#address\\.phone'), options.countryIsoCode);
        });
    },

    toggleBillingAddressForm(component) {
        const hasPaymentErrors = $('#useBillingAddressData').data('haspaymenterrors');
        const deliveryAddressId = $('#useDeliveryAddressData').data('address-id');
        const billingAddressId = $('#useBillingAddressData').data('address-id');
        if (component.data('is-quote-checkout') || hasPaymentErrors) {
            component.prop('checked', false);
            ACC.silentorderpost.showBillingAddressForm();
            if (hasPaymentErrors && (deliveryAddressId !== billingAddressId)) {
                ACC.silentorderpost.preFillBillingFormWhenPaymentError();
            } else if (hasPaymentErrors && (deliveryAddressId === billingAddressId)) {
                component.prop('checked', true);
                ACC.silentorderpost.hideBillingAddressForm();
                ACC.silentorderpost.preFillBillingForm();
            } else {
                ACC.silentorderpost.preFillBillingFormForQuoteCheckout();
            }
        } else if ($('.floated-sidebar').length !== 0) {
            component.prop('checked', true);
            ACC.silentorderpost.showBillingAddressForm();
        } else {
            component.prop('checked', true);
            ACC.silentorderpost.hideBillingAddressForm();
            ACC.silentorderpost.preFillBillingForm();
        }
    },

    showBillingAddressForm() {
        $('#ccBillingAddressForm').fadeIn();
        $('#ccDeliveryAddress').hide();
    },

    hideBillingAddressForm() {
        $('#ccBillingAddressForm').hide();
        $('#ccDeliveryAddress').fadeIn();
    },

    showPaymentForm() {
        $('.checkout-paymentmethod').fadeIn();
    },

    hidePaymentForm() {
        $('.checkout-paymentmethod').hide();
    },

    showPlaceOrderBtn() {
        $('.place-order-form').fadeIn();
    },

    showSavedCardBillingAddress() {
        $('#savedCardBillingAddress').fadeIn();
    },

    hideSavedCardBillingAddress() {
        $('#savedCardBillingAddress').hide();
    },

    hideSavedCardCvvInput() {
        $('#savedPaymentCards .c-saved-card .saved-card-cvv').removeClass('show-cvv-error-spacing');
        $('.saved-card-cvv').hide();
    },

    removeCardMessageValidation() {
        $('.saved-card-cvv').removeClass('has-error');
        $('.saved-card-cvv .help-block').remove();
        $('.checkout-with-card').removeClass('has-error');
    },
    hideRemoveCardButton() {
        $('.js-cartItemDetailBtn').hide();
    },

    preselectCard() {
        const reorderType = 'REORDER';
        if ($('#checkoutType').val() === reorderType) {
            const previouslySelectedCard = $('.savedCardRadioBtn[data-checked="true"]');

            if (previouslySelectedCard.length) {
                previouslySelectedCard.click();
            } else {
                $('.payment-info').html('');
            }
        }
    },

    unifiedWalletButtonClickHandler(e) {
        if (e.target.offsetParent.classList.contains('unifiedWallet')) {
            e.preventDefault();
            ACC.buttonhelper.disable('.unifiedWallet #updatePaymentBtn').withSpinner();
            ACC.updatePayment.updatePaymentAddress(
                ACC.updatePayment.validatePaymentMethodBySift(ACC.updatePayment.submitSOPForm(false)),
                ACC.updatePayment.updateBillingAddressForm,
                true,
            );
        }
    },

    attachUpdatePaymentHandlers(component) {
        ACC.updatePayment.addCvvHandler();

        const container = component[0].parentElement;

        if (container.classList.contains('unifiedWallet')) {
            const cvv = container.querySelector('.card_cvv');
            const btn = container.lastElementChild.querySelector('button');

            btn.classList.add('button-disabled');
            cvv.value = '';

            btn.addEventListener('click', ACC.silentorderpost.unifiedWalletButtonClickHandler);

            cvv.addEventListener('input', (e) => {
                if (e.target.value.length) {
                    btn.classList.remove('button-disabled');
                } else {
                    btn.classList.add('button-disabled');
                }
            });
        }
    },

    openCardAfterError() {
        const isUpdatePaymentPage = document.querySelector('.page-update-payment-method');
        const newCardField = isUpdatePaymentPage ? 'updateCard' : 'savedCard';
        const id = ACC.config.checkedCardId;
        const errorWrapper = document.querySelector('.checkout .global-alerts, .account-section .global-alerts');

        if (id && errorWrapper) {
            const isSavedPaymentId = /^[0-9]+$/.test(id);
            const selector = isSavedPaymentId ? `#savedCard-${id}` : `#${newCardField}-${id}`;
            const block = document.querySelector('#checkoutWithCreditCardButton');
            const cardBox = document.querySelector(selector);
            cardBox.click();
            if (id !== 'newCard') {
                const cvvCodeField = cardBox.parentElement.querySelector('input[name="card_cvNumber"]');
                cvvCodeField.parentElement.classList.add('has-error');
            }
            if (cardBox.parentElement.parentElement.id !== 'preferredSavedPaymentCards') {
                block.click();
            }

            const url = `${ACC.config.encodedContextPath}/paymentmethod/save/card/id`;
            ACC.silentorderpost.fetchPaymentId(url, '');
        }
    },

    sendPaymentIdBeforeSubmit() {
        const url = `${ACC.config.encodedContextPath}/checkout/multi/summary/save/card/id`;
        const checked = document.querySelector('.c-radiobtn.selected');
        let id;
        if (checked) {
            const idParts = checked.getAttribute('for').split('-');
            id = idParts[idParts.length - 1];
        }

        ACC.silentorderpost.fetchPaymentId(url, id);
    },

    fetchPaymentId(url, id) {
        $.ajax({
            type: 'POST',
            url,
            data: { checkedCardId: id },
        });
    },
};
