ACC.address = {
    formatCanadaPostalCode() {
        if ((ACC.utils.isHedTheme() || ACC.utils.isMojoTheme())
            && ACC.config.checkoutZipChangesEnabled) {
            $('#address\\.postcode').on('keyup', (event) => {
                const postcodeField = event.target;
                const countryField = document.querySelector('#address\\.country');
                const countryValue = countryField.value;
                if (countryValue === 'CA') {
                    if (postcodeField.value.length >= 4 && event.keyCode !== 8 && postcodeField.value[3] !== ' ') {
                        ACC.address.addSpaceOnSpecifiedPosition(postcodeField, 3);
                        return;
                    }
                    if (postcodeField.value.length === 4 && postcodeField.value[3] === ' ' && event.keyCode === 8) {
                        postcodeField.value = postcodeField.value.substring(0, 3);
                        // eslint-disable-next-line no-useless-return
                        return;
                    }
                }
            });
        }
    },

    addSpaceOnSpecifiedPosition(field, spacePosition) {
        const array = field.value.split('');
        array.splice(spacePosition, 0, ' ');
        field.value = array.join('');
    },
};
