ACC.collapse = {
    windowExists() {
        return typeof window !== 'undefined';
    },

    removeTransition(el) {
        el.style.transition = null;
        el.style.backfaceVisibility = null;
        el.style.overflow = null;
    },

    addTransition(el) {
        el.style.transition = 'height ease-out .3s';
        el.style.backfaceVisibility = 'hidden';
        el.style.overflow = 'hidden';
    },

    getAfterExpandCallback(el, done) {
        el.style.height = 'auto';
        el.style.overflow = null;

        if (done) {
            done();
        }
    },

    getAfterCollapseCallback(done) {
        if (done) {
            done();
        }
    },

    expand(el, done) {
        const that = ACC.collapse;
        const afterExpandCallback = that.getAfterExpandCallback(el, done);
        const dest = el.scrollHeight;

        that.removeTransition(el);
        el.style.height = 'auto';

        // eslint-disable-next-line no-unused-expressions
        that.windowExists() && requestAnimationFrame(() => {
            el.addEventListener('transitionend', afterExpandCallback, { once: true });
            el.style.height = '0px';
            el.style.transitionTimingFunction = 'ease-out';

            that.addTransition(el);
            requestAnimationFrame(() => {
                el.style.height = `${dest}px`;
            });
        });
    },

    collapse(el, done) {
        const that = ACC.collapse;
        const afterCollapseCallback = that.getAfterCollapseCallback(done);
        const original = el.scrollHeight;

        that.removeTransition(el);

        // eslint-disable-next-line no-unused-expressions
        that.windowExists() && requestAnimationFrame(() => {
            el.addEventListener('transitionend', afterCollapseCallback, { once: true });
            el.style.height = `${original}px`;
            el.style.transitionTimingFunction = 'ease-in';

            that.addTransition(el);
            requestAnimationFrame(() => {
                el.style.height = '0px';
            });
        });
    },
};
