ACC.upcConsents = {

    _autoload: [
        ['setDateOfBirthStep2', document.querySelector('.js-user-dob-month-year')],
        ['updateAgeGatingStep2', document.querySelector('#yearData')],
        ['showInstitution', (!document.querySelector('.js-study-level-select')
        && document.querySelector('.js-profile-institution'))
            || (!document.querySelector('.js-user-date-of-birth')
                && document.querySelector('.js-profile-institution'))],
        ['linkBothConsentsCheckbox', document.querySelector('#bothConsents')],
        ['handleConsentState', document.querySelector('#marketingOptIn, #thirdPartyMarketing, #bothConsents')],
    ],

    consentsState: {
        marketingOptIn: null,
        thirdPartyMarketing: null,
        bothConsentsCheckBox: null,
    },

    showInstitution() {
        const profileInstitution = document.querySelector('.js-profile-institution');
        profileInstitution.classList.remove('display-none');
    },

    showUpcMarketingConsents(onDone) {
        const iesUserId = piSession.userId();
        const userToken = ACC.upcConsents.getPiSessionToken();
        piSession.getToken((status, token) => {
            $.ajax({
                async: false,
                type: 'POST',
                headers: {
                    'X-UserId': piSession.userId(),
                    'X-Authorization': token,
                },
                url: `${ACC.config.encodedContextPath}/upcconsents/check`,
                data: {
                    iesUserId,
                    userToken,
                },
                success: (response) => {
                    if (response.showUpcNotificationFragment) {
                        window.location = `${ACC.config.encodedContextPath}`
                            + '/registration/update-info-step?customizeExperience=true'
                            + '&showGlobalMarketingConsent=true&showThirdPartyConsent=true';
                        return;
                    }
                    onDone();
                },
                error: () => {
                    onDone();
                },
            });
        });
    },

    loadNotificationFragment(iesUserId, userToken, onDone) {
        const bodyPage = document.querySelector('body');
        $('#mainPageContent').load(`${ACC.config.encodedContextPath}/upcconsents`, () => {
            const btn = $('.js-upc-consent-btn');
            bodyPage.removeAttribute('style');
            btn.on('click', (e) => {
                e.preventDefault();
                ACC.buttonhelper.disable(btn);
                const preferenceValue = $('#preferenceValue').prop('checked');
                ACC.upcConsents.sendUpcConsents(preferenceValue, iesUserId, userToken, onDone);
            });
        });
        if (bodyPage.classList.contains('page-login')) {
            bodyPage.classList.remove('page-login');
            bodyPage.classList.add('page-login-notification');
        }
    },

    sendUpcConsents(preferenceValue, customerUid, token, onDone, optOutReasonId) {
        $.ajax({
            type: 'POST',
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            url: `${ACC.config.encodedContextPath}/upcconsents/send`,
            data: JSON.stringify([{
                customerUid,
                preferenceItemId: ACC.config.thirdPartyMarketingPreferenceId,
                preferenceValue,
                countryCode: ACC.config.gcpCountryCode,
                token,
                optOutReasonId,
            }]),
            success: () => {
                onDone();
            },
            error: () => {
                onDone();
            },
        });
    },

    sendUpcConsentsAdditional(preferenceValue, customerUid, token, onDone, optOutReasonId) {
        const preferenceItemIds = [ACC.config.thirdPartyMarketingPreferenceId,
            ACC.config.userProfilePreferenceId, ACC.config.marketingConsentGlobalPreferenceId];
        const request = {
            customerUid,
            preferenceValue,
            countryCode: ACC.config.gcpCountryCode,
            token,
            optOutReasonId,
        };
        const data = JSON.stringify(preferenceItemIds.map((preferenceItemId) => {
            return { ...request, preferenceItemId };
        }));
        $.ajax({
            type: 'POST',
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            url: `${ACC.config.encodedContextPath}/upcconsents/send`,
            data,
            success: () => {
                onDone();
            },
            error: () => {
                onDone();
            },
        });
    },

    setDateOfBirthStep2() {
        const element1 = document.querySelector('#dobMonthID');
        const element2 = document.querySelector('#dobYearID');
        const inputDOB = document.querySelector('#dobID');
        const monthField = document.querySelector('#monthData');
        const yearField = document.querySelector('#yearData');

        [element1, element2].map((element) => element.addEventListener('change', (event) => {
            if (event.target.id === 'dobMonthID') {
                const index = element.selectedIndex;
                monthField.textContent = `0${index}`;
            }
            if (event.target.id === 'dobYearID') {
                yearField.textContent = event.target.value;
            }

            inputDOB.value = `${monthField.innerHTML}-${yearField.innerHTML}`;
        }));
    },

    updateAgeGatingStep2() {
        if (ACC.config.isAgeGatingEnabled) {
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            const monthField = document.querySelector('#monthData');
            const yearField = document.querySelector('#yearData');
            const optInCheckBox = document.querySelector('#marketingOptIn');
            const thirdPartyCheckBox = document.querySelector('#thirdPartyMarketing');
            const bothConsentsCheckBox = document.querySelector('#bothConsents');
            const minorMessage = document.querySelector('#minor-message');
            const studyLevel = document.querySelector('.js-study-level-select');
            const profileInstitution = document.querySelector('.js-profile-institution');
            const { ageGatingYearsLimit } = ACC.config;
            const dateFields = document.querySelectorAll('#dobMonthID, #dobYearID');
            const hiddenClass = 'hidden';
            const {
                uncheckAndDisableCheckbox,
                checkAndEnableCheckbox,
                enableCheckbox,
                handleConsentStateRoleAndYearSwitch,
            } = ACC.upcConsents;
            const consents = [optInCheckBox, thirdPartyCheckBox, bothConsentsCheckBox];
            dateFields.forEach((field) => {
                field.addEventListener('change', (event) => {
                    if (event.target.value) {
                        const yearOfBirth = yearField.innerHTML;
                        const monthOfBirth = monthField.innerHTML;
                        const amountOfYears = currentYear - yearOfBirth;
                        if (!yearOfBirth) return;
                        if (amountOfYears < +ageGatingYearsLimit
                            || (amountOfYears === +ageGatingYearsLimit && currentMonth <= monthOfBirth)) {
                            minorMessage.classList.remove(hiddenClass);
                            uncheckAndDisableCheckbox(consents);
                        } else {
                            minorMessage.classList.add(hiddenClass);
                            handleConsentStateRoleAndYearSwitch(consents);
                        }
                    } else {
                        minorMessage.classList.add(hiddenClass);
                        enableCheckbox(consents);
                    }

                    if (ACC.additionalCustomerInfo.educatorRole) {
                        minorMessage.classList.add(hiddenClass);
                        checkAndEnableCheckbox(consents);
                    }
                });
            });
            if (studyLevel) {
                studyLevel.addEventListener('change', (event) => {
                    const submitButton = document
                        .querySelector('#pearsonRegistrationSecondStepForm button[type="submit"]');
                    submitButton.removeAttribute('disabled');

                    if (event.target.selectedOptions[0].getAttribute('data-is-educator') === 'true') {
                        handleConsentStateRoleAndYearSwitch(consents);
                        profileInstitution.classList.remove('display-none');
                    } else if (!minorMessage.classList.contains(hiddenClass)) {
                        uncheckAndDisableCheckbox(consents);
                    }
                });
            }
        }
    },

    disableCheckbox(elements = []) {
        elements.forEach((element) => {
            if (element) {
                element.setAttribute('disabled', 'disabled');
                element.parentElement.classList.add('c-checkbox--disabled');
            }
        });
    },

    enableCheckbox(elements = []) {
        elements.forEach((element) => {
            if (element) {
                element.removeAttribute('disabled');
                element.parentElement.classList.remove('c-checkbox--disabled');
            }
        });
    },

    uncheckAndDisableCheckbox(elements = []) {
        elements.forEach((element) => {
            if (element) {
                ACC.upcConsents.disableCheckbox([element]);
                element.removeAttribute('checked');
                element.setAttribute('value', 'false');
                element.checked = false;
            }
        });
    },

    checkAndEnableCheckbox(elements = []) {
        elements.forEach((element) => {
            if (element) {
                ACC.upcConsents.enableCheckbox([element]);
                element.setAttribute('checked', 'checked');
                element.setAttribute('value', 'true');
                element.checked = true;
            }
        });
    },

    checkCheckBox(element) {
        if (!element) {
            return;
        }
        element.setAttribute('checked', 'checked');
        element.setAttribute('value', 'true');
    },

    uncheckCheckBox(element) {
        if (!element) {
            return;
        }
        element.removeAttribute('checked');
        element.setAttribute('value', 'false');
    },

    getPiSessionToken: () => {
        let currentToken;
        piSession.getToken((status, token) => {
            currentToken = token;
        });
        return currentToken;
    },

    linkBothConsentsCheckbox() {
        const optInCheckBox = document.querySelector('#marketingOptIn');
        const thirdPartyCheckBox = document.querySelector('#thirdPartyMarketing');
        const bothConsentsCheckBox = document.querySelector('#bothConsents');

        if (bothConsentsCheckBox.checked) {
            ACC.upcConsents.checkCheckBox(optInCheckBox);
            ACC.upcConsents.checkCheckBox(thirdPartyCheckBox);
        } else {
            ACC.upcConsents.uncheckCheckBox(optInCheckBox);
            ACC.upcConsents.uncheckCheckBox(thirdPartyCheckBox);
        }

        $(bothConsentsCheckBox).change(function () {
            if (this.checked) {
                ACC.upcConsents.checkCheckBox(optInCheckBox);
                ACC.upcConsents.checkCheckBox(thirdPartyCheckBox);
            } else {
                ACC.upcConsents.uncheckCheckBox(optInCheckBox);
                ACC.upcConsents.uncheckCheckBox(thirdPartyCheckBox);
            }
        });
    },

    setInitialConsentState() {
        const consentInputs = document.querySelectorAll('#marketingOptIn, #thirdPartyMarketing, #bothConsents');

        consentInputs.forEach((input) => {
            ACC.upcConsents.consentsState = {
                ...ACC.upcConsents.consentsState,
                [input.name]: input.getAttribute('checked'),
            };
        });
    },

    setContentState(consent, state) {
        ACC.upcConsents.consentsState = { ...ACC.upcConsents.consentsState, [consent]: state };
    },

    handleConsentState() {
        const consentInputs = document.querySelectorAll('#marketingOptIn, #thirdPartyMarketing, #bothConsents');
        const { setInitialConsentState, setContentState } = ACC.upcConsents;
        setInitialConsentState();
        consentInputs.forEach((input) => {
            input.addEventListener('change', ({ currentTarget }) => {
                setContentState(currentTarget.name, currentTarget.checked ? 'checked' : null);
            });
        });
    },

    handleConsentStateRoleAndYearSwitch(elements = []) {
        const { consentsState, checkAndEnableCheckbox, enableCheckbox } = ACC.upcConsents;
        elements.forEach((element) => {
            if (element) {
                if (consentsState[element.name]) {
                    checkAndEnableCheckbox([element]);
                } else {
                    enableCheckbox([element]);
                }
            }
        });
    },
};
