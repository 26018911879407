ACC.subscriptionDataLayer = {
    _autoload: [
        ['init', $('.c-subscription').length > 0],
    ],

    init() {
        const subscriptionBlock = $('.c-subscription');
        const buttons = subscriptionBlock.find('.c-subscription__submit');
        buttons.on('click', this.updateSubscriptionDataLayer);
    },

    updateSubscriptionDataLayer(event) {
        const position = event.target.getAttribute('data-position');
        const subscription = subscriptionDataLayer[0].subscriptions[position - 1];
        dataLayer.push({
            Subscriptions: {
                duration: subscription.duration,
                ecomProductFormat: subscription.ecomProductFormat,
                ecomProductType: subscription.ecomProductType,
                name: subscription.name,
                position: subscription.position,
                price: subscription.price,
                program: subscription.program,
                quantity: subscription.quantity,
                discountPrice: subscription.discountedPrice,
            },
        });
        dataLayer.push({
            billing_period: subscription.billingPeriod,
        });
    },

    pushEntitlementDataLayerEvent() {
        const { pageInfo } = dataLayer[0];
        if (!pageInfo) {
            return;
        }

        const { productIsbn } = pageInfo;
        const { productName } = pageInfo;

        if (productIsbn !== null) {
            dataLayer.push({
                event: 'entitlement',
                isbn: productIsbn,
                name: productName,
            });
        }
    },

    pushUpgradeDataLayerEvent(subscriptionTierId) {
        dataLayer.push({
            event: 'upgrade',
            subscriptionTierID: subscriptionTierId,
        });
    },

    pushUpgradeErrorDataLayerEvent(errorCode) {
        fetch(`${ACC.config.encodedContextPath}/global-message-text`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            body: JSON.stringify(errorCode),
        })
            .then((response) => response.json())
            .then((data) => {
                dataLayer.push({
                    event: 'error_upgradePayment',
                    error_message: data,
                    error_code: 'Not applicable',
                });
            });
    },
};
