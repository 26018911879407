ACC.subscriptionTitle = {

    _autoload: [
        'bindSubmitAddEntitlementForm',
        ['showAddTitleSinglePlaneRateModal', document.getElementById('addTitleSingleRatePlanModal')],
        'showSwitchSubscriptionConfirmation',
    ],

    bindSubmitAddEntitlementForm() {
        $('.js-add-entitlement-button')
            .on('click', function (event) {
                event.preventDefault();
                const btn = this;
                const addEntitlementUrl = btn.getAttribute('data-add-entitlement-url');
                const productIsbn = btn.getAttribute('data-product-isbn');
                const subscriptionNumber = btn.getAttribute('data-subscription-number');

                function addGoToButton(cookieVal, messageAttributes, text) {
                    if (!cookieVal) {
                        return `
                        <a href="${messageAttributes}" class="button--tertiary--inverted">
                            ${text}
                        </a>
                    `;
                    }
                    return '';
                }

                btn.setAttribute('disabled', 'disabled');
                $.post({
                    url: addEntitlementUrl,
                    data: {
                        productIsbn,
                        subscriptionNumber,
                    },
                    success(data) {
                        const {
                            message,
                            userHasRedeemedSubscription,
                            userHasReactivatedSubscription,
                            messageAttributes,
                            globalMessage,
                        } = data;

                        const goToMyListButtonText = ACC.config.goToMyListButton;
                        const eTextCookie = ACC.cookie.getCookie('hide-navigation');
                        document.querySelector('.subscription__add-to-list').innerHTML = `
                        ${addGoToButton(eTextCookie, messageAttributes, goToMyListButtonText)}
                        <p class="subscription-add-label">${message}</p>
                    `;

                        if (globalMessage.length !== 0) {
                            const globalMessageRequest = {
                                globalMessages: [{
                                    messageHolder: 'accConfMsgs',
                                    messages: [globalMessage],
                                    attributes: messageAttributes,
                                }],
                            };

                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        }
                        ACC.subscriptionDataLayer.pushEntitlementDataLayerEvent();

                        if (userHasRedeemedSubscription || userHasReactivatedSubscription) {
                            $modal.close();

                            if (!(eTextCookie) && messageAttributes && messageAttributes.length !== 0) {
                                const [href] = messageAttributes;
                                window.location.href = href;
                            }
                        }
                    },
                    error(data) {
                        const {
                            responseJSON: {
                                productAlreadyAdded,
                                message,
                                messageAttributes,
                                userHasRedeemedSubscription,
                                userHasReactivatedSubscription,
                            },
                        } = data;

                        if (productAlreadyAdded) {
                            const globalMessageRequest = {
                                globalMessages: [{
                                    messageHolder: 'accErrorMsgs',
                                    messages: [message],
                                    attributes: messageAttributes,
                                }],
                            };

                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        } else {
                            btn.removeAttribute('disabled');
                            ACC.globalmessages.callErrorGlobalMessage(message);
                        }
                        if (userHasRedeemedSubscription || userHasReactivatedSubscription) {
                            $modal.close();
                        }
                    },
                });
            });
    },

    showAddTitleSinglePlaneRateModal() {
        document.querySelector('.js-add-title-single-rate-plan')
            .addEventListener('click', function () {
                $modal.open(this, {
                    closeButton: false,
                });
            });
    },

    processAgreeToSwitchTittle() {
        const targetButton = ACC.subscriptionTitle.getSwitchTargetButton();

        if (targetButton) {
            targetButton.addEventListener('click', ACC.subscriptionTitle.handleSwitchTitle);
        }
    },

    getSwitchTargetButton() {
        const button = document.querySelector('.js-agree-switch-title-button');
        const swapTitleButton = document.querySelector('.js-swap-title-button');
        let isSwapTitle;
        if (swapTitleButton) {
            isSwapTitle = swapTitleButton.getAttribute('data-swap-title') === 'true';
        }
        return isSwapTitle ? swapTitleButton : button;
    },

    handleSwitchTitle() {
        const targetButton = ACC.subscriptionTitle.getSwitchTargetButton();
        const productCode = targetButton.dataset.mojoSubscriptionTier;
        const currentSubscriptionNumber = targetButton.dataset.subscriptionNumber;
        const selectedProductCode = targetButton.dataset.selectedProduct;

        const switchTittleUrl = `${ACC.config.encodedContextPath}/subscription/switch-tittle`;
        ACC.buttonhelper.disable(targetButton)
            .withSpinner();

        $.post({
            url: switchTittleUrl,
            data: {
                currentSubscriptionNumber,
                productCode,
                selectedProductCode,
            },
            success(data) {
                if (data.tax) {
                    document.querySelector('#popup_switch_etext_thank #tax-amount-value').innerHTML = data.tax;
                    document.querySelector('#popup_switch_etext_thank #next-bill-amount-value')
                        .innerHTML = data.nextBillAmount;
                    if (data.discount) {
                        document.querySelector('#popup_switch_etext_thank #discount-value-right')
                            .innerHTML = `-${data.discount}`;
                        document.querySelector('#popup_switch_etext_thank #discount-value-left')
                            .innerHTML = data.discount;
                    } else {
                        document.querySelectorAll('.popup_switch_subscription__info-list .discount_info')
                            .forEach((item) => item.remove());
                    }
                } else {
                    document.querySelectorAll('.popup_switch_subscription__info-list .price_total_info')
                        .forEach((item) => item.remove());
                }

                if (document.querySelector('#popup_switch_etext_thank #next-billing-date-value')) {
                    document.querySelector('#popup_switch_etext_thank #next-billing-date-value')
                        .innerHTML = data.nextBillDate;
                }

                if (document.querySelector('#popup_switch_etext_thank #payment-details-value')) {
                    document.querySelector('#popup_switch_etext_thank #payment-details-value')
                        .innerHTML = data.paymentDetails;
                }

                if (document.querySelector('#popup_switch_etext_thank #legal-copy')) {
                    document.querySelector('#popup_switch_etext_thank #legal-copy')
                        .innerHTML = data.legalCopy;
                }
                ACC.subscriptionTitle.addSwitchStatusDataLayer('success');
                ACC.subscriptionTitle.handleSwitchEtextThankModal();
            },
            error() {
                $modal.close();
                const messageKey = 'text.switch.title.esb.error.banner';
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                ACC.subscriptionTitle.addSwitchStatusDataLayer('failure');
            },
        });
    },

    handleSwitchEtextThankModal() {
        const swapTitleButton = document.querySelector('.js-swap-title-button');
        if (ACC.subscriptionTitle.isMultiRemovalFlow(swapTitleButton)
            && ACC.subscriptionTitle.isSwapTitleFlow(swapTitleButton)) {
            window.location = ACC.config.homePageUrl;
        } else {
            $modal.open(null, {
                html: $('#popup_switch_etext_thank'),
                onClosed: ACC.subscriptionTitle.handleClosedSwitchEtextThankModal,
            });
        }
    },

    handleClosedSwitchEtextThankModal() {
        const agreeButton = document.querySelector('.js-agree-switch-title-button');
        if (ACC.subscriptionTitle.isApplicableForNewSwapTitleFlow()
            || ACC.subscriptionTitle.isMultiRemovalFlow(agreeButton)) {
            window.location = ACC.config.homePageUrl;
        } else {
            window.location.reload();
        }
    },

    isApplicableForNewSwapTitleFlow() {
        const swapTitleButton = document.querySelector('.js-swap-title-button');
        return swapTitleButton && swapTitleButton.getAttribute('data-swap-title-flow') === 'new';
    },

    isMultiRemovalFlow(button) {
        return button && button.getAttribute('data-multi-removal-flow') === 'true';
    },

    isSwapTitleFlow(button) {
        return button && button.getAttribute('data-swap-title') === 'true';
    },

    addSwitchStatusDataLayer(status) {
        const swapTitleButtonNew = document.querySelector('.analytics-addToLibrary');
        if (swapTitleButtonNew) {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: 'switchTitleStatus',
                status,
            });
        }
    },

    showSwitchSubscriptionModal(switchSubscriptionBtn) {
        const btn = $('#popup_switch_subscription_title');

        btn.on('click', () => {
            const params = ACC.subscriptionHelpers.getParams(switchSubscriptionBtn);
            const { subscriptionCode } = params;

            const targetContainer = document.getElementById(
                `popup_switch_subscription_title_${subscriptionCode}`,
            );
            const template = document.getElementById(
                `switchSubscriptionTitleTemplate_${subscriptionCode}`,
            ).innerHTML;

            if (!targetContainer && !template) {
                return;
            }

            $modal.open(null, {
                className: 'colorbox--upgrade-modal',
                inline: true,
                href: targetContainer,
                onLoad() {
                    targetContainer.innerHTML = template;
                    const cancelButton = document.querySelector('.js-close-agree-switch-title-modal');
                    if (cancelButton) {
                        cancelButton.addEventListener('click', () => {
                            $modal.close();
                        });
                    }
                    ACC.subscriptionTitle.processAgreeToSwitchTittle();
                },
                onClosed() {
                    const switchTitlePDPButton = document.querySelector('.js-switch-tittle-subscription-button');
                    if (switchTitlePDPButton) {
                        switchTitlePDPButton.focus();
                    }
                },
            });
        });
    },

    showSwitchSubscriptionConfirmation() {
        const switchSubscriptionBtn = document.querySelector('.js-switch-tittle-subscription-button');

        if (switchSubscriptionBtn) {
            switchSubscriptionBtn.addEventListener('click', (e) => {
                e.preventDefault();
                $modal.open(switchSubscriptionBtn, {
                    closeButton: false,
                    inline: true,
                    href: '#etext-confirmation-modal',
                    onLoad() {
                        const switchTitlePopupButton = document.querySelector('#popup_switch_subscription_title');
                        ACC.subscriptionTitle.showSwitchSubscriptionModal(switchSubscriptionBtn);
                        ACC.subscriptionTitle.processAgreeToSwitchTittle();
                        if (switchTitlePopupButton) {
                            switchTitlePopupButton.focus();
                        }
                    },
                });
                const dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: 'switchTitleButtonClicked',
                    userId: piSession ? piSession.userId() : '',
                });
            });
        }
    },
};
