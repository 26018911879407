/** ###########################################################################################################
 ###########################################################################################################

 Name: Pearson KeyboardTrap Component
 Description: Plugin made focus only inside {element}
 Dependency: none

 // Example of options object ( minimum required settings )
 new PearsonKeyboardTrap(document.querySelector('#cboxContent'))
 */
(function (root, factory) {
    const pluginName = 'PearsonKeyboardTrap';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    /**
     * Get focusable elements inside element
     * @public
     * @param {HTMLElement} element
     */
    function getFocusableEls(element) {
        return element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]),'
            + ' textarea:not([disabled]), input[type="text"]:not([disabled]),'
            + ' input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    }

    /**
     * Trap focus inside element
     * @param {HTMLElement} element
     */
    function trapFocus(element) {
        const focusableEls = getFocusableEls(element);
        const firstFocusableEl = focusableEls[0];
        const lastFocusableEl = focusableEls[focusableEls.length - 1];
        const KEYCODE_TAB = '9';

        element.addEventListener('keydown', (e) => {
            const isTabPressed = (e.key === 'Tab' || e.code === KEYCODE_TAB);

            if (!isTabPressed) { return; }

            if (e.shiftKey) { /* shift + tab */
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === lastFocusableEl) { /* tab */
                firstFocusableEl.focus();
                e.preventDefault();
            }
        });
    }
    /**
     * Plugin Object
     @constructor
     * @param {HTMLElement} element
     */
    function Plugin(element) {
        this.options = element;
        // Initialize show more component
        this.init(element);
    }

    /**
     * Plugin prototype
     * @public
     * @constructor
     */
    Plugin.prototype = {
        init(element) {
            trapFocus(element);
        },
        getFocusableEls,
    };

    return Plugin;
}));
