ACC.orderPlaceAdditionalDataProcessor = {
    _autoload: ['initFunction'],

    initFunction() {
        if (ACC.config.isCheckoutSummaryAdditionalAataEnabled !== 'true') {
            return;
        }

        $(document).on(ACC.config.allJsInitializedEvent, () => {
            if (ACC.secureacceptance) {
                ACC.secureacceptance.handleOrderPlaceAdditionalData = ACC.orderPlaceAdditionalDataProcessor
                    .sendOrderPlaceAdditionalData;
            }

            if (ACC.paypal) {
                ACC.paypal.handleOrderPlaceAdditionalData = ACC.orderPlaceAdditionalDataProcessor
                    .sendOrderPlaceAdditionalData;
            }

            if (ACC.zippay) {
                ACC.zippay.handleOrderPlaceAdditionalData = ACC.zippay.sendOrderPlaceAdditionalData;
            }
        });
    },

    sendOrderPlaceAdditionalData() {
        const isConsentCheckboxChecked = $('#consentCheckBox').is(':checked');
        const ioBbValue = $('#io_blackbox').length ? $('#io_blackbox').val() : '';
        ACC.silentorderpost.sendPaymentIdBeforeSubmit();
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/sendOrderPlaceAdditionalData`,
            data: {
                consent: isConsentCheckboxChecked,
                deviceId: ioBbValue,
            },
            cache: false,
        });
    },
};
