(function (root, factory) {
    const pluginName = 'AccordionPlugin';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    const toggleExpanded = function (heading) {
        const panels = heading.nextElementSibling;
        const button = heading.firstElementChild;

        button.setAttribute('aria-expanded', 'true');

        if (panels.classList.contains('c-accordion-panel-hide')) {
            panels.setAttribute('aria-hidden', 'false');
            panels.classList.remove('c-accordion-panel-hide');
            panels.classList.add('c-accordion-panel-show');
        } else {
            button.setAttribute('aria-expanded', 'false');
            panels.setAttribute('aria-hidden', 'true');
            panels.classList.remove('c-accordion-panel-show');
            panels.classList.add('c-accordion-panel-hide');
        }
        heading.firstElementChild.classList.toggle('isActive');
        if (panels.style.maxHeight) {
            panels.style.maxHeight = null;
        } else {
            panels.style.maxHeight = `${panels.scrollHeight}px`;
        }

        if (panels.classList.contains('hasLinks')) {
            handleTabIndexForLinksInPanelA11Y(panels);
        }
    };

    const initAccordion = function () {
        const heading = document.querySelectorAll('.js-accordion-header');

        heading.forEach((item) => {
            if (item.getAttribute('aria-expanded') === 'true') {
                toggleExpanded(item);
            } else {
                item.nextElementSibling.setAttribute('aria-hidden', 'true');
                handleTabIndexForLinksInPanelA11Y(item.nextElementSibling);
            }
            item.addEventListener('click', function (e) {
                e.preventDefault();
                toggleExpanded(this);
            });
        });
    };

    const handleTabIndexForLinksInPanelA11Y = function (panel) {
        const links = panel.querySelectorAll('a');

        if (!links.length) {
            return;
        }

        links.forEach((link) => {
            if (link.hasAttribute('tabindex') && link.getAttribute('tabindex') === '-1') {
                link.removeAttribute('tabindex');
            } else {
                link.setAttribute('tabindex', '-1');
            }
        });

        if (!panel.classList.contains('hasLinks')) {
            panel.classList.add('hasLinks');
        }
    };

    /**
     * Plugin Object
     * @constructor
     */
    function Plugin() {
        // Initialize component
        this.init();
    }

    /**
     * Plugin prototype
     * @public
     * @constructor
     */
    Plugin.prototype = {
        init() {
            initAccordion();
        },
    };

    return Plugin;
}));
