ACC.partnership = {
    _autoload: [
        ['getPartnershipContent', document.querySelector('.partnership-block')],
        ['initFragment', document.querySelector('.partnership') && !document.querySelector('.partnership-block')],
        ['updatePerksLinks', !document.querySelector('.order-confirmation')],
    ],
    url: ACC.config.partnershipContentCarousel,
    isEligible: ACC.config.isUserEligibleForMarketing === 'true',
    requestURL: '/store/en-us/pearsonplus/partnership/offers',
    objects: {
        loader: document.querySelector('.partnership-block__loader'),
    },

    updatePerksLinks() {
        const offersLink = document.getElementById('perks-footer-link');
        const lanLink = document.getElementById('lan-footer-link');
        if (!ACC.partnership.isEligible) {
            if (offersLink) {
                offersLink.parentNode.remove();
            }

            if (lanLink) {
                lanLink.parentNode.remove();
            }
        }
    },

    initFragment() {
        if (!ACC.partnership.url) {
            return;
        }
        const combinedUrl = ACC.partnership.updateUrl(ACC.partnership.url);
        ACC.partnership.requestToFragment(combinedUrl);
    },

    getPartnershipContent() {
        const partnershipContainer = document.querySelector('.partnership');
        const orderCode = partnershipContainer.dataset.orderCode || '';
        const apiUrl = orderCode ? `${ACC.partnership.requestURL}?orderCode=${orderCode}`
            : `${ACC.partnership.requestURL}`;

        ACC.partnership.showLoader();

        $.ajax({
            type: 'GET',
            url: apiUrl,
        }).always((data, textStatus, jqXHR) => {
            if (jqXHR.status !== 200) { // Customer is not eligible or an error occurred
                ACC.partnership.hideLoader();

                const offersLink = document.getElementById('perks-footer-link');
                const lanLink = document.getElementById('lan-footer-link');

                if (partnershipContainer) {
                    partnershipContainer.parentNode.remove();
                }

                if (offersLink) {
                    offersLink.parentNode.remove();
                }

                if (lanLink) {
                    lanLink.parentNode.remove();
                }
            } else {
                ACC.partnership.handleOnLoad(data);
            }
        });
    },

    handleOnLoad(data) {
        const label = document.querySelector('.offers-label-container');
        if (data) {
            label?.removeAttribute('hidden');
            ACC.partnership.url = data;
            ACC.partnership.hideLoader();
            ACC.partnership.initFragment();
        } else {
            ACC.partnership.hideLoader();
        }
    },

    showLoader() {
        const { loader } = ACC.partnership.objects;
        if (loader) {
            loader.classList.add('loading');
        }
    },

    hideLoader() {
        const { loader } = ACC.partnership.objects;
        if (loader) {
            loader.classList.remove('loading');
        }
    },

    updateUrl(url) {
        return url.toLowerCase().replace(/html/, 'combined-content.html');
    },

    requestToFragment(url) {
        fetch(url, {
            dataType: 'html',
        })
            .then((response) => response.text())
            .then((response) => {
                ACC.partnership.responseHandler(response);
            });
    },

    responseHandler(res) {
        const container = document.querySelector('.partnership');
        const src = ACC.partnership.getScriptSrc(res);
        const tmpl = ACC.partnership.removeScriptTag(res);
        const scriptTag = ACC.partnership.createScriptTag(src);

        container.innerHTML = tmpl;
        ACC.partnership.addLoadListener(container);
        container.appendChild(scriptTag);
    },

    createScriptTag(src) {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', src);
        return script;
    },

    addLoadListener(container) {
        container.addEventListener('load', (event) => {
            if (event.target.nodeName === 'SCRIPT') {
                ACC.partnership.trigger();
            }
        }, true);
    },

    removeScriptTag(tmpl) {
        const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        return tmpl.replace(regex, '');
    },

    getScriptSrc(tmpl) {
        const regex = /<script.*?src="(.*?)"/g;
        const source = regex.exec(tmpl);
        return source[1];
    },

    trigger() {
        const $row = $('.js-c-collection');
        let resizeDebouncing = false;
        let viewUpdatedDebouncing = false;

        if (!$row.length) {
            return;
        }

        const settings = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            accessibility: true,
            dots: true,
            regionLabel: $row.attr('data-aria-label'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                }, {
                    breakpoint: 768,
                    settings: 'unslick',
                },
            ],
        };

        function triggerEvent($elements) {
            if (viewUpdatedDebouncing) {
                clearTimeout(viewUpdatedDebouncing);
            }

            viewUpdatedDebouncing = setTimeout(() => {
                // Informing other components that we just updated the DOM model
                $('body').trigger('ViewUpdated', [$elements]);
                viewUpdatedDebouncing = false;
            }, 30);
        }

        function setSlickEvents($elements) {
            $elements.off('init').on('init', () => {
                triggerEvent($elements);
            });

            $elements.off('reInit').on('reInit', () => {
                triggerEvent($elements);
            });

            $elements.off('destroy').on('destroy', () => {
                triggerEvent($elements);
            });

            $elements.off('breakpoint').on('breakpoint', () => {
                triggerEvent($elements);
            });
        }

        $row.slick(settings);

        setSlickEvents($row);

        $('.slick-slide').each(function () {
            const ariaLabel = $(this).find('a.c-collection__banner').attr('data-aria-label');
            if (ariaLabel) {
                $(this).find('a.c-collection__banner').removeAttr('data-aria-label');
                $(this).attr('aria-label', ariaLabel);
            }
        });

        $(window).on('resize', () => {
            if (resizeDebouncing) {
                clearTimeout(resizeDebouncing);
            }

            // debouncing resize event
            resizeDebouncing = setTimeout(() => {
                if ($(window).width() > 768 && !$row.hasClass('slick-initialized')) {
                    $row.slick(settings);
                    setSlickEvents($row);
                }

                // resizeDebouncing = false;
            }, 30);
        });
    },
};
