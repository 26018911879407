ACC.phonemasking = {

    _autoload: ['bindAll'],

    accessibilityOptions: {
        comboboxLabel: '',
    },

    DEFAULT_PHONE_NUMBER_MIN_LENGTH: 10,
    DEFAULT_PHONE_NUMBER_MAX_LENGTH: 15,
    COUNTRY_CODE_PREFIX: '+',
    DEFAULT_US_PHONE_NUMBER_PLACEHOLDER: '555-555-5555',
    IS_COUNTRY_CHANGED_FROM_DROPDOWN: false,
    IS_COUNTRY_DATA_UPDATED: false,

    INITIAL_ELEMENTS: [$('#address\\.phone'), $('#phoneNumber'), $('#contactPhone')],

    ERROR_MAP: [ACC.config.phoneNumberMaskingInvalidNumberMessage, '',
        ACC.config.phoneNumberMaskingShortNumberMessage, '', ACC.config.phoneNumberMaskingInvalidNumberMessage],

    INPUT_PHONE_LANG_DATA: [
        ['Afghanistan (‫افغانستان‬‎)', 'Pashto', 'ps', 'rtl', 'af'],
        ['Albania (Shqipëri)', 'Albanian', 'sq', 'ltr', 'al'],
        ['Algeria (‫الجزائر‬‎)', 'Arabic', 'ar', 'rtl', 'dz'],
        ['American Samoa', 'English', 'en', 'ltr', 'as'],
        ['Andorra', 'Catalan', 'ca', 'ltr', 'ad'],
        ['Angola', 'Portuguese', 'pt', 'ltr', 'ao'],
        ['Anguilla', 'English', 'en', 'ltr', 'ai'],
        ['Antigua and Barbuda', 'English', 'en', 'ltr', 'ag'],
        ['Argentina', 'English', 'en', 'ltr', 'ar'],
        ['Armenia (Հայաստան)', 'Armenian', 'hy', 'ltr', 'am'],
        ['Aruba', 'English', 'en', 'ltr', 'aw'],
        ['Ascension Island', 'English', 'en', 'ltr', 'sh'],
        ['Australia', 'English', 'en', 'ltr', 'au'],
        ['Austria (Österreich)', 'German', 'de', 'ltr', 'at'],
        ['Azerbaijan (Azərbaycan)', 'Azerbaijani', 'az', 'ltr', 'az'],
        ['Bahamas', 'English', 'en', 'ltr', 'bs'],
        ['Bahrain (‫البحرين‬‎)', 'Arabic', 'ar', 'rtl', 'bh'],
        ['Bangladesh (বাংলাদেশ)', 'Bangla', 'bn', 'ltr', 'bd'],
        ['Barbados', 'English', 'en', 'ltr', 'bb'],
        ['Belarus (Беларусь)', 'Belarusian', 'be', 'ltr', 'by'],
        ['Belgium (België)', 'Dutch', 'nl', 'ltr', 'be'],
        ['Belize', 'English', 'en', 'ltr', 'bz'],
        ['Benin (Bénin)', 'French', 'fr', 'ltr', 'bj'],
        ['Bermuda', 'English', 'en', 'ltr', 'bm'],
        ['Bhutan (འབྲུག)', 'Dzongkha', 'dz', 'ltr', 'bt'],
        ['Bolivia', 'English', 'en', 'ltr', 'bo'],
        ['Bosnia and Herzegovina (Босна и Херцеговина)', 'Bosnian', 'bs', 'ltr', 'ba'],
        ['Botswana', 'English', 'en', 'ltr', 'bw'],
        ['Brazil (Brasil)', 'Portuguese', 'pt-br', 'ltr', 'br'],
        ['British Indian Ocean Territory', 'English', 'en', 'ltr', 'io'],
        ['British Virgin Islands', 'English', 'en', 'ltr', 'vg'],
        ['Brunei', 'English', 'en', 'ltr', 'bn'],
        ['Bulgaria (България)', 'Bulgarian', 'bg', 'ltr', 'bg'],
        ['Burkina Faso', 'English', 'en', 'ltr', 'bf'],
        ['Burundi (Uburundi)', 'Rundi', 'rn', 'ltr', 'bi'],
        ['Cambodia (កម្ពុជា)', 'Khmer', 'km', 'ltr', 'kh'],
        ['Cameroon (Cameroun)', 'French', 'fr', 'ltr', 'cm'],
        ['Canada', 'English', 'en-ca', 'ltr', 'ca'],
        ['Cape Verde (Kabu Verdi)', 'Portuguese', 'pt', 'ltr', 'cv'],
        ['Caribbean Netherlands', 'English', 'en', 'ltr', 'bq'],
        ['Cayman Islands', 'English', 'en', 'ltr', 'ky'],
        ['Central African Republic (République centrafricaine)', 'French', 'fr', 'ltr', 'cf'],
        ['Chad (Tchad)', 'French', 'fr', 'ltr', 'td'],
        ['Chile', 'English', 'en', 'ltr', 'cl'],
        ['China (中国)', 'Chinese', 'zh', 'cn'],
        ['Christmas Island', 'English', 'en', 'ltr', 'cx'],
        ['Cocos (Keeling) Islands', 'English', 'en', 'ltr', 'cc'],
        ['Colombia', 'English', 'en', 'ltr', 'ci'],
        ['Comoros (‫جزر القمر‬‎)', 'Arabic', 'ar', 'rtl', 'km'],
        ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'Swahili', 'sw', 'ltr', 'cd'],
        ['Congo (Republic) (Congo-Brazzaville)', 'English', 'en', 'ltr', 'cg'],
        ['Cook Islands', 'English', 'en', 'ltr', 'ck'],
        ['Costa Rica', 'English', 'en', 'ltr', 'cr'],
        ['Côte d’Ivoire', 'English', 'en', 'ltr', 'ci'],
        ['Croatia (Hrvatska)', 'Croatian', 'cn', 'ltr', 'hr'],
        ['Cuba', 'English', 'en', 'ltr', 'cu'],
        ['Curaçao', 'English', 'en', 'ltr', 'cw'],
        ['Cyprus (Κύπρος)', 'Greek', 'el', 'cy'],
        ['Czech Republic (Česká republika)', 'Czech', 'cs', 'ltr', 'cz'],
        ['Denmark (Danmark)', 'Danish', 'da', 'ltr', 'dk'],
        ['Djibouti', 'English', 'en', 'ltr', 'dj'],
        ['Dominica', 'English', 'en', 'ltr', 'dm'],
        ['Dominican Republic (República Dominicana)', 'Spanish', 'es', 'ltr', 'do'],
        ['Ecuador', 'English', 'en', 'ltr', 'ec'],
        ['Egypt (‫مصر‬‎)', 'Arabic', 'ar', 'rtl', 'eg'],
        ['El Salvador', 'English', 'en', 'ltr', 'sv'],
        ['Equatorial Guinea (Guinea Ecuatorial)', 'Spanish', 'es', 'ltr', 'gq'],
        ['Eritrea', 'English', 'en', 'ltr', 'er'],
        ['Estonia (Eesti)', 'Estonian', 'et', 'ltr', 'ee'],
        ['Eswatini', 'English', 'en', 'ltr', 'sz'],
        ['Ethiopia', 'English', 'en', 'ltr', 'et'],
        ['Falkland Islands (Islas Malvinas)', 'Spanish', 'es', 'ltr', 'fk'],
        ['Faroe Islands (Føroyar)', 'Danish', 'da', 'ltr', 'fo'],
        ['Fiji', 'English', 'en', 'ltr', 'fj'],
        ['Finland (Suomi)', 'Finnish', 'smn', 'ltr', 'fi'],
        ['France', 'English', 'en', 'ltr', 'fr'],
        ['French Guiana (Guyane française)', 'French', 'fr', 'ltr', 'gf'],
        ['French Polynesia (Polynésie française)', 'French', 'fr', 'ltr', 'pf'],
        ['Gabon', 'English', 'en', 'ltr', 'ga'],
        ['Gambia', 'English', 'en', 'ltr', 'gm'],
        ['Georgia (საქართველო)', 'Georgian', 'ka', 'ltr', 'ge'],
        ['Germany (Deutschland)', 'German', 'de', 'ltr', 'de'],
        ['Ghana (Gaana)', 'Akan', 'ak', 'ltr', 'gh'],
        ['Gibraltar', 'English', 'en', 'ltr', 'gi'],
        ['Greece (Ελλάδα)', 'Greek', 'el', 'ltr', 'gr'],
        ['Greenland (Kalaallit Nunaat)', 'Danish', 'da', 'ltr', 'gl'],
        ['Grenada', 'English', 'en', 'ltr', 'gd'],
        ['Guadeloupe', 'English', 'en', 'ltr', 'gp'],
        ['Guam', 'English', 'en', 'ltr', 'gu'],
        ['Guatemala', 'English', 'en', 'ltr', 'gt'],
        ['Guernsey', 'English', 'en', 'ltr', 'gg'],
        ['Guinea (Guinée)', 'French', 'fr', 'ltr', 'gn'],
        ['Guinea-Bissau (Guiné Bissau)', 'Portuguese', 'pt', 'ltr', 'gw'],
        ['Guyana', 'English', 'en', 'ltr', 'gy'],
        ['Haiti', 'English', 'en', 'ltr', 'ht'],
        ['Honduras', 'English', 'en', 'ltr', 'hn'],
        ['Hong Kong (香港)', 'Chinese', 'zh', 'ltr', 'hk'],
        ['Hungary (Magyarország)', 'Hungarian', 'hu', 'ltr', 'hu'],
        ['Iceland (Ísland)', 'Icelandic', 'is', 'ltr', 'is'],
        ['India (भारत)', 'Hindi', 'hi', 'ltr', 'in'],
        ['Indonesia', 'English', 'en', 'ltr', 'id'],
        ['Iran (‫ایران‬‎)', 'Persian', 'fa', 'rtl', 'ir'],
        ['Iraq (‫العراق‬‎)', 'Arabic', 'ar', 'rtl', 'iq'],
        ['Ireland', 'English', 'en', 'ltr', 'ie'],
        ['Isle of Man', 'English', 'en', 'ltr', 'im'],
        ['Israel (‫ישראל‬‎)', 'Hebrew', 'he', 'rtl', 'il'],
        ['Italy (Italia)', 'Italian', 'it', 'ltr', 'it'],
        ['Jamaica', 'English', 'en', 'ltr', 'jm'],
        ['Japan (日本)', 'Japanese', 'ja', 'ltr', 'jp'],
        ['Jersey', 'English', 'en', 'ltr', 'je'],
        ['Jordan (‫الأردن‬‎)', 'Arabic', 'ar', 'rtl', 'jo'],
        ['Kazakhstan (Казахстан)', 'Kazakh', 'kk', 'ltr', 'kz'],
        ['Kenya', 'English', 'en', 'ltr', 'ke'],
        ['Kiribati', 'English', 'en', 'ltr', 'ki'],
        ['Kosovo', 'English', 'en', 'ltr', 'xk'],
        ['Kuwait (‫الكويت‬‎)', 'Arabic', 'ar', 'rtl', 'kw'],
        ['Kyrgyzstan (Кыргызстан)', 'Kyrgyz', 'ky', 'ltr', 'kg'],
        ['Laos (ລາວ)', 'Lao', 'lo', 'ltr', 'la'],
        ['Latvia (Latvija)', 'Latvian', 'lv', 'ltr', 'lv'],
        ['Lebanon (‫لبنان‬‎)', 'Arabic', 'ar', 'rtl', 'lb'],
        ['Lesotho', 'English', 'en', 'ltr', 'ls'],
        ['Liberia', 'English', 'en', 'ltr', 'lr'],
        ['Libya (‫ليبيا‬‎)', 'Arabic', 'ar', 'rtl', 'ly'],
        ['Liechtenstein', 'English', 'en', 'ltr', 'li'],
        ['Lithuania (Lietuva)', 'Lithuanian', 'lt', 'ltr', 'lt'],
        ['Luxembourg', 'English', 'en', 'ltr', 'lx'],
        ['Macau (澳門)', 'Chinese', 'zh', 'ltr', 'mo'],
        ['Macedonia (FYROM) (Македонија)', 'Greek', 'el', 'ltr', 'mk'],
        ['Madagascar (Madagasikara)', 'Malagasy', 'mg', 'ltr', 'mg'],
        ['Malawi', 'English', 'en', 'ltr', 'mw'],
        ['Malaysia', 'English', 'en', 'ltr', 'my'],
        ['Maldives', 'English', 'en', 'ltr', 'mv'],
        ['Mali', 'English', 'en', 'ltr', 'ml'],
        ['Malta', 'English', 'en', 'ltr', 'mt'],
        ['Marshall Islands', 'English', 'en', 'ltr', 'mh'],
        ['Martinique', 'English', 'en', 'ltr', 'mq'],
        ['Mauritania (‫موريتانيا‬‎)', 'Fulah', 'ff', 'rtl', 'mr'],
        ['Mauritius (Moris)', 'Morisyen', 'mfe', 'ltr', 'mu'],
        ['Mayotte', 'English', 'en', 'ltr', 'yt'],
        ['Mexico (México)', 'Spanish', 'es', 'ltr', 'mx'],
        ['Micronesia', 'English', 'en', 'ltr', 'fm'],
        ['Moldova (Republica Moldova)', 'Romanian', 'ro', 'ltr', 'md'],
        ['Monaco', 'English', 'en', 'ltr', 'mc'],
        ['Mongolia (Монгол)', 'Mongolian', 'mn', 'ltr', 'mn'],
        ['Montenegro (Crna Gora)', 'Serbian', 'sr', 'ltr', 'me'],
        ['Montserrat', 'English', 'en', 'ltr', 'ms'],
        ['Morocco (‫المغرب‬‎)', 'Arabic', 'ar', 'rtl', 'ma'],
        ['Mozambique (Moçambique)', 'Portuguese', 'pt', 'ltr', 'mz'],
        ['Myanmar (Burma) (မြန်မာ)', 'Burmese', 'my', 'ltr', 'mm'],
        ['Namibia (Namibië)', 'Afrikaans', 'af', 'ltr', 'na'],
        ['Nauru', 'English', 'en', 'ltr', 'nr'],
        ['Nepal (नेपाल)', 'Nepali', 'ne', 'ltr', 'np'],
        ['Netherlands (Nederland)', 'Dutch', 'nl', 'ltr', 'nl'],
        ['New Caledonia (Nouvelle-Calédonie)', 'French', 'fr', 'ltr', 'nc'],
        ['New Zealand', 'English', 'en', 'ltr', 'nz'],
        ['Nicaragua', 'English', 'en', 'ltr', 'ni'],
        ['Niger (Nijar)', 'Hausa', 'ha', 'ltr', 'ne'],
        ['Nigeria', 'English', 'en', 'ltr', 'ng'],
        ['Niue', 'English', 'en', 'ltr', 'nu'],
        ['Norfolk Island', 'English', 'en', 'ltr', 'nf'],
        ['North Korea (조선 민주주의 인민 공화국)', 'Korean', 'ko', 'ltr', 'kp'],
        ['Northern Mariana Islands', 'English', 'en', 'ltr', 'mp'],
        ['Norway (Norge)', 'Norwegian Bokmål', 'nb', 'ltr', 'no'],
        ['Oman (‫عُمان‬‎)', 'Arabic', 'ar', 'rtl', 'om'],
        ['Pakistan (‫پاکستان‬‎)', 'Urdu', 'ur', 'rtl', 'pk'],
        ['Palau', 'English', 'en', 'ltr', 'pw'],
        ['Palestine (‫فلسطين‬‎)', 'Arabic', 'ar', 'rtl', 'ps'],
        ['Panama (Panamá)', 'Spanish', 'es', 'ltr', 'pa'],
        ['Papua New Guinea', 'English', 'en', 'ltr', 'pg'],
        ['Paraguay', 'English', 'en', 'ltr', 'py'],
        ['Peru (Perú)', 'Spanish', 'es', 'ltr', 'pe'],
        ['Philippines', 'English', 'en', 'ltr', 'ph'],
        ['Poland (Polska)', 'Polish', 'pl', 'ltr', 'pl'],
        ['Portugal', 'English', 'en', 'ltr', 'pt'],
        ['Puerto Rico', 'English', 'en', 'ltr', 'pr'],
        ['Qatar (‫قطر‬‎)', 'Arabic', 'ar', 'rtl', 'qa'],
        ['Réunion (La Réunion)', 'French', 'fr', 'ltr', 're'],
        ['Romania (România)', 'Romanian', 'ro', 'ltr', 'ro'],
        ['Russia (Россия)', 'Russian', 'ru', 'ltr', 'ru'],
        ['Rwanda', 'English', 'en', 'ltr', 'rw'],
        ['Saint Barthélemy', 'English', 'en', 'ltr', 'bl'],
        ['Saint Helena', 'English', 'en', 'ltr', 'sh'],
        ['Saint Kitts and Nevis', 'English', 'en', 'ltr', 'kn'],
        ['Saint Lucia', 'English', 'en', 'ltr', 'lc'],
        ['Saint Martin (Saint-Martin (partie française))', 'French', 'fr', 'ltr', 'mf'],
        ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'French', 'fr', 'ltr', 'pm'],
        ['Saint Vincent and the Grenadines', 'English', 'en', 'ltr', 'vc'],
        ['Samoa', 'English', 'en', 'ltr', 'ws'],
        ['San Marino', 'English', 'en', 'ltr', 'sm'],
        ['São Tomé and Príncipe (São Tomé e Príncipe)', 'Portuguese', 'pt', 'ltr', 'st'],
        ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'Arabic', 'ar', 'rtl', 'sa'],
        ['Senegal (Sénégal)', 'French', 'fr', 'ltr', 'sn'],
        ['Serbia (Србија)', 'Serbian', 'sr', 'ltr', 'rs'],
        ['Seychelles', 'English', 'en', 'ltr', 'sc'],
        ['Sierra Leone', 'English', 'en', 'ltr', 'sl'],
        ['Singapore', 'English', 'en', 'ltr', 'sg'],
        ['Sint Maarten', 'English', 'en', 'ltr', 'sx'],
        ['Slovakia (Slovensko)', 'Slovak', 'sk', 'ltr', 'sk'],
        ['Slovenia (Slovenija)', 'Slovenian', 'sl', 'ltr', 'si'],
        ['Solomon Islands', 'English', 'en', 'ltr', 'sb'],
        ['Somalia (Soomaaliya)', 'Somali', 'so', 'ltr', 'so'],
        ['South Africa', 'English', 'en', 'ltr', 'za'],
        ['South Korea (대한민국)', 'Korean', 'ko', 'ltr', 'kr'],
        ['South Sudan (‫جنوب السودان‬‎)', 'Arabic', 'ar', 'rtl', 'ss'],
        ['Spain (España)', 'Spanish', 'es', 'ltr', 'es'],
        ['Sri Lanka (ශ්‍රී ලංකාව)', 'Sinhala', 'si', 'ltr', 'lk'],
        ['Sudan (‫السودان‬‎)', 'Arabic', 'ar', 'ltr', 'sd'],
        ['Suriname', 'English', 'en', 'ltr', 'sr'],
        ['Svalbard and Jan Mayen', 'English', 'en', 'ltr', 'sj'],
        ['Sweden (Sverige)', 'Swedish', 'sv', 'ltr', 'se'],
        ['Switzerland (Schweiz)', 'Swiss German', 'gsw', 'ltr', 'ch'],
        ['Syria (‫سوريا‬‎)', 'Arabic', 'ar', 'rtl', 'sy'],
        ['Taiwan (台灣)', 'Chinese', 'zh', 'ltr', 'tw'],
        ['Tajikistan', 'English', 'en', 'ltr', 'tj'],
        ['Tanzania', 'English', 'en', 'ltr', 'tz'],
        ['Thailand (ไทย)', 'Thai', 'th', 'ltr', 'th'],
        ['Timor-Leste', 'English', 'en', 'ltr', 'tl'],
        ['Togo', 'English', 'en', 'ltr', 'tg'],
        ['Tokelau', 'English', 'en', 'ltr', 'tk'],
        ['Tonga', 'English', 'en', 'ltr', 'to'],
        ['Trinidad and Tobago', 'English', 'en', 'ltr', 'tt'],
        ['Tunisia (‫تونس‬‎)', 'Arabic', 'ar', 'rtl', 'tn'],
        ['Turkey (Türkiye)', 'Turkish', 'tr', 'ltr', 'tr'],
        ['Turkmenistan', 'English', 'en', 'ltr', 'tm'],
        ['Turks and Caicos Islands', 'English', 'en', 'ltr', 'tc'],
        ['Tuvalu', 'English', 'en', 'ltr', 'tv'],
        ['U.S. Virgin Islands', 'English', 'en', 'ltr', 'vi'],
        ['Uganda', 'English', 'en', 'ltr', 'ug'],
        ['Ukraine (Україна)', 'Ukrainian', 'uk', 'ltr', 'ua'],
        ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'Arabic', 'ar', 'rtl', 'ae'],
        ['United Kingdom', 'English', 'en', 'ltr', 'gb'],
        ['United States', 'English', 'en', 'ltr', 'us'],
        ['Uruguay', 'English', 'en', 'ltr', 'uy'],
        ['Uzbekistan (Oʻzbekiston)', 'Uzbek', 'uz', 'ltr', 'uz'],
        ['Vanuatu', 'English', 'en', 'ltr', 'vu'],
        ['Vatican City (Città del Vaticano)', 'Italian', 'it', 'ltr', 'va'],
        ['Venezuela', 'English', 'en', 'ltr', 've'],
        ['Vietnam (Việt Nam)', 'Vietnamese', 'vi', 'ltr', 'vn'],
        ['Wallis and Futuna (Wallis-et-Futuna)', 'French', 'fr', 'ltr', 'wf'],
        ['Western Sahara (‫الصحراء الغربية‬‎)', 'Arabic', 'ar', 'rtl', 'eh'],
        ['Yemen (‫اليمن‬‎)', 'Arabic', 'ar', 'rtl', 'ye'],
        ['Zambia', 'English', 'en', 'ltr', 'zm'],
        ['Zimbabwe', 'English', 'en', 'ltr', 'zw'],
        ['Åland Islands', 'English', 'en', 'ltr', 'ax'],
    ],
    PHONE_LANG_REGEX: /(.+)\((.+)\)/,

    bindAll() {
        $.each(ACC.phonemasking.INITIAL_ELEMENTS, (index, input) => {
            if (input.is(':visible')) {
                const countrySelector = $('#address\\.country');
                if (countrySelector !== undefined) {
                    ACC.phonemasking.bind(input, countrySelector.val());
                } else {
                    ACC.phonemasking.bind(input);
                }
            }
        });
    },

    bind(input, initialValue) {
        if (ACC.config.isIntlTelInputEnabled) {
            const options = {
                preferredCountries: ACC.phonemasking.getPreferredCountries(),
                initialCountry: initialValue,
                separateDialCode: true,
                customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
                    return ACC.phonemasking.isUs(selectedCountryData)
                        ? ACC.phonemasking.DEFAULT_US_PHONE_NUMBER_PLACEHOLDER : selectedCountryPlaceholder;
                },
            };
            ACC.phonemasking.init(input, options);
        }
    },

    init(input, options) {
        if (input.length) {
            ACC.phonemasking.fillCountryCode(input);

            const iti = window.intlTelInputGlobals.getInstance(input.get(0));

            if (!ACC.phonemasking.IS_COUNTRY_DATA_UPDATED) {
                ACC.phonemasking.updateCountryDataByName();
                ACC.phonemasking.IS_COUNTRY_DATA_UPDATED = true;
            }

            if (!iti) {
                const hiddenInputName = ACC.phonemasking.addHiddenInput(input, options);
                window.intlTelInput(input.get(0), options);
                const hiddenInput = $(input.closest('form').find(`[name="${hiddenInputName}"]`));

                ACC.phonemasking.bindValueChange(input, hiddenInput);
                ACC.phonemasking.bindValidation(input);
                ACC.phonemasking.dynamicInputMaskChange(input, hiddenInput);
                ACC.phonemasking.addAccessibilityRules(input);
                ACC.phonemasking.bindReachedMaxLength(input);
            }
        }
    },

    updateCountryDataByName() {
        const countryData = window.intlTelInputGlobals.getCountryData();

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < countryData.length; i++) {
            const country = countryData[i];

            if (country.name.match(ACC.phonemasking.PHONE_LANG_REGEX)) {
                country.name = ACC.phonemasking.getCountryName(country);
            }
        }

        return countryData;
    },

    getCountryName(country) {
        const countryLangData = ACC.phonemasking.getCountryData(country.name);
        const lang = countryLangData[2];
        const dir = countryLangData[3];

        function replacer(match, p1, p2) {
            return [p1, `<span lang="${lang}" dir="${dir}">(${p2})</span>`].join(' ');
        }

        return country.name.replace(ACC.phonemasking.PHONE_LANG_REGEX, replacer);
    },

    getCountryData(name) {
        return ACC.phonemasking.INPUT_PHONE_LANG_DATA.find((country) => country[0] === name);
    },

    setFocusOnItiSelectedFlag(input) {
        const itiContainer = input.get(0).closest('.iti');
        const itiSelectedFlag = itiContainer.querySelector('.iti__selected-flag');

        itiSelectedFlag.focus();
    },

    addAccessibilityRules(input) {
        if (!input) {
            return;
        }

        const inputEl = input.get(0);
        const container = inputEl.parentElement;
        const combobox = container.querySelector('.iti__selected-flag');
        if (!combobox) {
            return;
        }
        const codeLabel = document.getElementById('countryCodeLabel');

        combobox.id = 'selectedFlagButton';
        combobox.setAttribute('aria-labelledby', `${codeLabel.id} ${combobox.id}`);
        combobox.setAttribute('role', 'button');
        combobox.setAttribute('aria-haspopup', 'listbox');
    },

    bindValueChange(input, hiddenInput) {
        ACC.phonemasking.updateValues(input, hiddenInput);

        input.on('change', (event) => {
            // eslint-disable-next-line no-unused-expressions,no-param-reassign
            event.preventDefault ? event.preventDefault() : (event.returnValue = false);

            ACC.phonemasking.updateValues(input, hiddenInput);
        });
    },

    bindValidation(input) {
        const form = input.closest('form');

        input.on('blur', () => {
            ACC.phonemasking.validate(input);
        });

        form.on('submit', (event) => {
            if (ACC.phonemasking.validate(input)) { return; }
            /**
             * If there are errors in fields other than the field phoneNumber, then use jQuery Validation
             * */
            const isPhoneError = document.querySelector('.has-error').contains(input[0]);
            if (!isPhoneError) { return; }

            event.preventDefault();
            input.focus();
            ACC.phonemasking.scrollTo(input);
            ACC.buttonhelper.enable(form.find('button[type="submit"]'));
        });
    },

    dynamicInputMaskChange(input, hiddenInput) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));
        const countryList = $('.iti__country-list');

        ACC.phonemasking.initDynamicMask(input);
        ACC.phonemasking.setMaxLength(input);

        input.on('countrychange', () => {
            input.val('');
            ACC.phonemasking.updateValues(input, hiddenInput);
            ACC.phonemasking.initDynamicMask(input);
            if (!ACC.phonemasking.IS_COUNTRY_CHANGED_FROM_DROPDOWN) {
                ACC.phonemasking.IS_COUNTRY_CHANGED_FROM_DROPDOWN = true;
            } else {
                const country = iti.getSelectedCountryData();
                ACC.phonemasking.handleCountryChangeA11y(input, country.name);
            }
            ACC.phonemasking.setMaxLength(input);
        });

        /* fix the issue when user can not navigate and select values
        from the country list box from NVDA and Firefox combination
         */
        input.on('open:countrydropdown', () => {
            const list = input[0].parentElement.querySelector('.iti__country-list');

            if (list) {
                list.querySelector('.iti__country.iti__active').focus();
            }
        });

        countryList.on('click keydown', (event) => {
            if (event.type === 'click' || event.key === 'Enter' || event.key === 'Escape') {
                // fix incorrect focus behaviour after closing country dropdown
                setTimeout(() => {
                    ACC.phonemasking.setFocusOnItiSelectedFlag(input);
                }, 0);
                // change country from dropdown list
                if (event.type === 'click' || event.key === 'Enter') {
                    ACC.phonemasking.IS_COUNTRY_CHANGED_FROM_DROPDOWN = true;
                }
            }
        });
    },

    handleCountryChangeA11y(input, country) {
        const elementContainer = input.closest('.phone-number-fieldset');
        const countryChangeContainer = elementContainer.find('#countryChange');
        const prefix = countryChangeContainer[0].getAttribute('data-country-prefix');

        if (!countryChangeContainer.length) {
            return;
        }

        countryChangeContainer.html(`${prefix} ${country}`);
    },

    initDynamicMask(input) {
        input.inputmask({
            mask: ACC.phonemasking.createMask(input),
            onBeforeMask(pastedValue) {
                return ACC.phonemasking.onBeforeMaskHandler(input, pastedValue);
            },
        });
    },

    onBeforeMaskHandler(input, pastedValue) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));
        const selectedCountryData = iti.getSelectedCountryData();
        const exampleNumber = ACC.phonemasking.cleanValue(
            intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, 0, 0),
            selectedCountryData.dialCode,
        );

        return ACC.phonemasking.shouldAddCountryCodeMark(pastedValue, exampleNumber)
            ? ACC.phonemasking.format(input, `+${pastedValue}`)
            : ACC.phonemasking.format(input, pastedValue);
    },

    shouldAddCountryCodeMark(value, example) {
        return !ACC.phonemasking.hasCountryCodeMark(value) && ACC.phonemasking.containsCountryCode(value, example);
    },

    hasCountryCodeMark(value) {
        return value.match('^\\+');
    },

    containsCountryCode(value, example) {
        return ACC.phonemasking.cleanValue(value).length > example.length;
    },

    getPreferredCountries() {
        const preferredCountries = ACC.config.intlTelInputTopCountries;
        if (preferredCountries !== undefined) {
            return preferredCountries.split(',');
        }
        return ['us', 'gb', 'ca', 'au', 'nz'];
    },

    createMask(input) {
        const placeholder = input.attr('placeholder');
        return placeholder ? placeholder.replace(/[0-9]/g, 9) : '';
    },

    setMaxLength(input) {
        const elementContainer = input.get(0).closest('.phone-number-fieldset');
        const maxLengthContainer = elementContainer.querySelector('#maxlengthNumber');

        if (!maxLengthContainer) {
            return;
        }

        const number = ACC.phonemasking.getClearMaxLength(input);

        maxLengthContainer.innerHTML = (`${number} digits`);
    },

    bindReachedMaxLength(input) {
        const max = document.getElementById('maxlengthPhoneNumberReached');

        if (!max) {
            return;
        }

        input.get(0).addEventListener('keyup', () => {
            if (ACC.phonemasking.isReachedMaxLength(input)) {
                max.textContent = 'Limit reached';
            } else {
                max.textContent = '';
            }
        });
    },

    isReachedMaxLength(input) {
        const mask = ACC.phonemasking.removeSpecialSymbolsFromValue(input[0].inputmask.getmetadata());
        const value = ACC.phonemasking.removeSpecialSymbolsFromValue(input[0].value);
        return value.length === mask.length;
    },

    removeSpecialSymbolsFromValue(mask) {
        return mask.replace(/\D/g, '');
    },

    getClearMaxLength(input) {
        const mask = input[0].inputmask.getmetadata();
        const digit = mask.replace(/\D/g, '');

        return digit.length;
    },

    format(input, pastedValue) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));
        const selectedCountryData = iti.getSelectedCountryData();

        if (selectedCountryData) {
            const { dialCode } = selectedCountryData;
            const value = pastedValue || iti.getNumber();

            const cleanedValue = ACC.phonemasking.cleanValue(value, dialCode);

            return ACC.phonemasking.isUk(selectedCountryData)
                ? ACC.phonemasking.dropLeadingZero(cleanedValue) : cleanedValue;
        }

        return pastedValue;
    },

    isUk(selectedCountryData) {
        return selectedCountryData.dialCode === '44';
    },

    isUs(selectedCountryData) {
        return selectedCountryData.iso2 === 'us';
    },

    dropLeadingZero(value) {
        return value.replace(/^0/, '');
    },

    validate(input) {
        let iti = {};
        let message = '';
        let isValid = true;
        ACC.phonemasking.hideErrors(input);

        if (ACC.config.isIntlTelInputEnabled) {
            iti = window.intlTelInputGlobals.getInstance(input.get(0));
            message = ACC.phonemasking.getValidationMessage(iti, input.get(0));
            isValid = (iti.isValidNumber() || !message);

            // eslint-disable-next-line no-unused-expressions
            isValid ? ACC.phonemasking.hideErrors(input) : ACC.phonemasking.showErrors(input, message);

            return isValid;
        }

        return isValid;
    },

    getValidationMessage(iti, input) {
        const selectedCountryData = iti.getSelectedCountryData();
        const isRequiredInput = ACC.phonemasking.isRequiredInput(input);

        /* override default length validation for uk,
           if the number has fewer than 7 digits (plus sign "+" and 2 digits country code are not included)
           display number is too short error message */
        return (ACC.phonemasking.isUk(selectedCountryData) && iti.getNumber().length < 10 && isRequiredInput)
            ? ACC.phonemasking.ERROR_MAP[2] : ACC.phonemasking.ERROR_MAP[iti.getValidationError()];
    },

    isRequiredInput(input) {
        return input.getAttribute('aria-required') === 'true';
    },

    addHiddenInput(input, options) {
        const hiddenInputName = input.attr('name');
        options.hiddenInput = hiddenInputName;
        input.attr('name', '');

        return hiddenInputName;
    },

    cleanValue(value, dialCode) {
        return value.replace(new RegExp(`^\\+${dialCode}`), '')
            .replace(new RegExp(`^00${dialCode}`), '')
            .replace(/[^0-9]/g, '');
    },

    hideErrors(input) {
        const inputEl = input.get(0);
        const elementContainer = inputEl.closest('.form-group');
        const errorContainer = elementContainer.querySelector('.help-block');

        elementContainer.classList.remove('has-error');
        if (errorContainer) {
            elementContainer.querySelector('.help-block').remove();
        }
    },

    showErrors(input, message) {
        const inputEl = input.get(0);
        const error = `<div class='help-block'><span id='phone.errors'>${message}</span></div>`;
        const elementContainer = inputEl.closest('.form-group');
        const errorContainer = elementContainer.querySelector('.help-block');
        if (errorContainer) {
            elementContainer.querySelector('.help-block').remove();
        }

        if (!elementContainer.classList.contains('has-error')) {
            elementContainer.classList.add('has-error');
        }
        elementContainer.insertAdjacentHTML('beforeend', error);
    },

    collectPhoneNumber(input) {
        if (ACC.config.isIntlTelInputEnabled && input.length !== 0) {
            const iti = window.intlTelInputGlobals.getInstance(input.get(0));
            return iti ? iti.getNumber() : input.val();
        }

        return input.val();
    },

    updateValues(input, hiddenInput) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));

        if (iti) {
            hiddenInput.val(iti.getNumber());
            ACC.phonemasking.splitCountryCode(iti, hiddenInput);
        }
    },

    fillCountryCode(input) {
        const countryCode = $('#phoneCountryCode').val();
        const phoneNumber = input.val();

        if (countryCode) input.val(ACC.phonemasking.COUNTRY_CODE_PREFIX + countryCode + phoneNumber);
    },

    splitCountryCode(iti, hiddenInput) {
        const hiddenPhoneCountryCode = $('#phoneCountryCode');
        if (hiddenPhoneCountryCode.length) {
            const countryData = iti.getSelectedCountryData();

            if (countryData) {
                const { dialCode } = countryData;

                if (dialCode) {
                    hiddenPhoneCountryCode.val(dialCode);
                    const plusSignLength = 1;
                    hiddenInput.val(iti.getNumber().slice(dialCode.length + plusSignLength));
                }
            }
        }
    },

    scrollTo(input) {
        $('html, body').animate({ scrollTop: input.offset().top }, 500);
    },
};
