ACC.payment = {
    _autoload: [
        ['activateSavedPaymentButton', $('.js-saved-payments').length !== 0],
        ['bindPaymentCardTypeSelect', $('#card_cardType').length !== 0],
    ],

    activateSavedPaymentButton() {
        $('.js-saved-payments').on('click', (e) => {
            e.preventDefault();

            const title = $('#savedpaymentstitle').html();
            $.colorbox({
                href: '#savedpaymentsbody',
                inline: true,
                opacity: 0.7,
                title,
                close: '<span class="glyphicon glyphicon-remove"></span>',
                onComplete() {
                },
            });
        });
    },
    bindPaymentCardTypeSelect() {
        ACC.payment.filterCardInformationDisplayed();
        $('#card_cardType').change(function () {
            const cardType = $(this).val();
            if (cardType === '024') {
                $('#startDate, #issueNum').show();
            } else {
                $('#startDate, #issueNum').hide();
            }
        });
    },
    filterCardInformationDisplayed() {
        const cardType = $('#card_cardType').val();
        if (cardType === '024') {
            $('#startDate, #issueNum').show();
        } else {
            $('#startDate, #issueNum').hide();
        }
    },
};
