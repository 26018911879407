ACC.updatePayment = {

    _autoload: [
        'scrollToFirstInvalidField',
        ['start', $('#updatePaymentSOP').length !== 0],
    ],

    requiredInputFields: [
        '#address\\.townCity',
        '#address\\.postcode',
        '#address\\.firstName',
        '#address\\.surname',
        '#address\\.line1',
        '#card_accountNumber',
        '#ExpiryMonth',
        '#ExpiryYear',
        '#card_cvNumber',
    ],

    sopFormSrc() {
        return $('#sopFormAction').val();
    },

    start() {
        ACC.updatePayment.attachHandlerForUpdatePaymentBtn();
        ACC.updatePayment.initSOPIframeDialog();
        ACC.updatePayment.attachBillingAddressForm();
        ACC.updatePayment.bindAddressTrim();
        ACC.updatePayment.loadSopFormFragment();
        ACC.updatePayment.addCvvHandler();
        ACC.updatePayment.hideSecondErrorMessage();
        ACC.updatePayment.autoSelectPaypal();
    },

    initSOPIframeDialog() {
        const $sopIframeCbox = $('#sopIframeCbox');
        const $body = $('body');
        let $width = $(window).width();
        let $height = $(window).height();

        if ($width > 767) {
            $width = 570;
            $height = 500;
        }

        if ($sopIframeCbox) {
            $sopIframeCbox.dialog({
                title: 'Payment Details',
                autoOpen: false,
                width: $width,
                height: $height,
                draggable: false,
                resizable: false,
                dialogClass: 'no-close',
                modal: true,
                open() {
                    $body.addClass('stop-scrolling');
                },
                close() {
                    $body.removeClass('stop-scrolling');
                },
            });
        }
    },

    startSOPIframeTimeout($sopRequestIframe) {
        let previousSOPIframeTimeout = null;
        $sopRequestIframe.on('load', () => {
            if (previousSOPIframeTimeout) {
                clearTimeout(previousSOPIframeTimeout);
            }

            previousSOPIframeTimeout = setTimeout(() => {
                $('#sopIframeCbox').dialog('open');
            }, 3000);
        });
    },

    performTrimBeforeSubmit(component) {
        component.find($('input[id^="address"]')).each(function () {
            this.value = this.value.trim();
        });
        return true;
    },

    scrollToFirstInvalidField() {
        const errorElements = $('.has-error');
        const errorVisible = $('.has-error').is(':visible');
        if (errorElements.length && errorVisible) {
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top,
            }, 500);
        }
    },

    validateForm() {
        const formSubmit = $('.place-order-form #updatePaymentBtn');
        ACC.updatePayment.requiredInputFields.forEach((selector) => {
            $(selector).on('focusout', () => {
                let isValid = true;

                ACC.updatePayment.requiredInputFields.forEach((item) => {
                    if (!isValid) { return; }

                    if (!ACC.updatePayment.validateInput($(item))) {
                        isValid = false;
                        formSubmit.attr('aria-disabled', 'true').addClass('button-disabled');
                    } else {
                        isValid = true;
                        formSubmit.attr('aria-disabled', 'false').removeClass('button-disabled');
                    }
                });
            });
        });
    },

    submitSOPForm(shouldValidate) {
        if (ACC.updatePayment.isValidCardData(shouldValidate) && ACC.updatePayment.isValidBillingAddressData()) {
            const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');

            ACC.silentorderpost.sendPaymentIdBeforeSubmit();

            if (savedCardId) {
                const sop = document.querySelector('#sopRequestIframe');
                const card = cardData.find((el) => el.paymentId === savedCardId);
                const subscriptionNumber = sop.dataset.subNumber;
                const url = '/paymentmethod/update/payment/sa/sop';
                const token = card.paymentToken;
                ACC.secureacceptance.reloadSopIframe(url, { subscriptionNumber, token }, true);
            } else {
                const sopForm = ACC.updatePayment.getSopForm();

                sopForm.find('#card_type').val(ACC.updatePayment.getCardType());
                sopForm.find('#card_number').val(ACC.updatePayment.getCardNumber());
                sopForm.find('#card_expiry_date').val(ACC.updatePayment.getCardExpireDate());
                sopForm.find('#card_cvn').val(ACC.updatePayment.getCardCvnNumber());
                sopForm.find('#bill_to_forename').val(ACC.updatePayment.getBillToFieldValue('firstName'));
                sopForm.find('#bill_to_surname').val(ACC.updatePayment.getBillToFieldValue('lastName'));
                sopForm.find('#bill_to_address_line1').val(ACC.updatePayment.getBillToFieldValue('street1'));
                sopForm.find('#bill_to_address_line2').val(ACC.updatePayment.getBillToFieldValue('street2'));
                sopForm.find('#bill_to_address_city').val(ACC.updatePayment.getBillToFieldValue('city'));
                sopForm.find('#bill_to_address_country').val(ACC.updatePayment.getBillToSelectValue('country'));
                sopForm.find('#bill_to_address_postal_code').val(ACC.updatePayment.getBillToFieldValue('postalCode'));
                sopForm.find('#bill_to_address_state').val(ACC.updatePayment.getBillToSelectValue('state'));
                sopForm
                    .find('#bill_to_phone')
                    .val(ACC.phonemasking.collectPhoneNumber($('input[name=billTo_phoneNumber]')));

                const billToEmail = ACC.updatePayment.getBillToFieldValue('email');
                if (billToEmail) {
                    sopForm.find('#bill_to_email').val(billToEmail);
                }

                const savePaymentInfoCheckbox = $('#card_save_payment_info');

                if (savePaymentInfoCheckbox.length) {
                    sopForm.find('#merchant_defined_data98').val(savePaymentInfoCheckbox.prop('checked'));
                }

                ACC.updatePayment.submitForm(sopForm);
                return;
            }
        }

        ACC.buttonhelper.enable('.place-order-form #updatePaymentBtn');
    },

    updateForSavedCard() {
        const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
        const cardCvv = $(`#savedCardCvvNumber-${savedCardId}`).val();
        const sopForm = ACC.secureacceptance.getSopForm();
        const cardCvnField = $('#sopRequestIframe').contents().find('#card_cvn')[0];
        const preferredCardField = sopForm.find('#merchant_defined_data98');
        const isSavedCardPreferred = $(`#preferredCard-${savedCardId}`).val();

        preferredCardField.val(isSavedCardPreferred);
        cardCvnField.value = cardCvv;
        ACC.updatePayment.submitForm(sopForm);
    },

    updateBillingAddressForm(data) {
        $('#billingAddressFormContainer').html(data);
        ACC.updatePayment.validateForm();
        ACC.updatePayment.updateTopErrorMessage(data);
        ACC.accessibilityHelpers.setAriaInvalidAttributes();
    },

    updateTopErrorMessage(data) {
        const $errorMessage = $(data).filter('#topErrorMessage');
        if ($errorMessage.length) {
            const message = $errorMessage.val();
            const globalMessageRequest = {
                globalMessages: [{
                    messageHolder: 'accErrorMsgsInline',
                    messages: [message],
                }],
            };
            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
        }
    },

    submitForm(sopForm) {
        sopForm.submit();
        const sopRequestIframe = $('#sopRequestIframe');
        if (ACC.config.isSopIframeEnabled) {
            ACC.updatePayment.startSOPIframeTimeout(sopRequestIframe);
        }
    },

    isValidBillingAddressData() {
        ACC.secureacceptance.scrollToFirstInvalidField();
        return $('#isSuccess').val() === 'true';
    },

    getBillToFieldValue(fieldName) {
        return $(`input[name=billTo_${fieldName}]`).val();
    },

    getBillToSelectValue(fieldName) {
        return $(`select[name=billTo_${fieldName}] option:selected`).val();
    },

    attachHandlerForUpdatePaymentBtn() {
        const btn = document.querySelector('#updatePaymentBtn');
        btn.addEventListener('click', (e) => {
            if (!btn.offsetParent.classList.contains('unifiedWallet')) {
                e.preventDefault();
                ACC.buttonhelper.disable('.place-order-form #updatePaymentBtn').withSpinner();

                const submitFormFunc = () => ACC.updatePayment.submitSOPForm(true);
                const failValidationFunc = ACC.updatePayment.showUpdatePaymentError;

                ACC.updatePayment.updatePaymentAddress(
                    () => ACC.updatePayment.validatePaymentMethod(submitFormFunc, failValidationFunc),
                    ACC.updatePayment.updateBillingAddressForm,
                    true,
                );
            }
        });
    },

    showUpdatePaymentError(data) {
        const placeOrderButton = $('.place-order-form #updatePaymentBtn');
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: data.errorHolder,
                messages: [data.errorCode],
                attributes: [data.supportLink],
            }],
        };
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
        ACC.buttonhelper.enable(placeOrderButton);
        placeOrderButton.blur();
    },

    collectUpdatePaymentAddressForm() {
        const form = {};
        const component = $('#updatePaymentSOP');
        ACC.secureacceptance.performTrimBeforeSubmit(component);
        form.billTo_country = component.find($('#address\\.country option:selected')).val();
        form.billTo_firstName = component.find($('#address\\.firstName')).val();
        form.billTo_lastName = component.find($('#address\\.surname')).val();
        form.billTo_street1 = component.find($('#address\\.line1')).val();
        form.billTo_street2 = component.find($('#address\\.line2')).val();
        form.billTo_city = component.find($('#address\\.townCity')).val();
        form.billTo_postalCode = component.find($('#address\\.postcode')).val();
        form.billTo_titleCode = component.find($('#address\\.title option:selected')).val();
        form.billTo_phoneNumber = ACC.phonemasking.collectPhoneNumber(component.find($('#address\\.phone')));
        form.billTo_state = component.find($('#address\\.region option:selected')).val();
        form.billTo_email = component.find($('#address\\.email')).val();
        return form;
    },

    collectUpdatePaymentMethodForm() {
        const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
        let form;
        if (savedCardId) {
            form = ACC.unifiedWallet.setBillingAddressForSavedCard(GLOBAL_PAYMENT_DATA, savedCardId);
            const card = cardData.find((el) => el.paymentId === savedCardId);
            form.cartNumberMasked = card.paymentCardMasked;
        } else {
            form = ACC.updatePayment.collectUpdatePaymentAddressForm();
            form.cartNumberMasked = ACC.secureacceptance.encryptCardNumber(ACC.secureacceptance.getCardNumber());
        }
        return form;
    },

    getUpdateBillingFormForOfacOrderCheck() {
        let form = {};
        const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
        ACC.buttonhelper.ariaDisable('.place-order-form #updatePaymentBtn').withSpinner();
        if (ACC.secureacceptance.isSavedCard()) {
            form = ACC.config.isUnifiedWalletEnabled
                ? ACC.unifiedWallet.setBillingAddressForSavedCard(GLOBAL_PAYMENT_DATA, savedCardId)
                : ACC.updatePayment.collectUpdatePaymentAddressForm();
            const card = cardData.find((el) => el.paymentId === savedCardId);
            form.cartNumberMasked = card.paymentCardMasked;
        } else {
            form = ACC.updatePayment.collectUpdatePaymentAddressForm();
            form.cartNumberMasked = ACC.secureacceptance.encryptCardNumber(ACC.secureacceptance.getCardNumber());
        }
        return form;
    },

    validatePaymentMethod(successCallback, failValidationCallback) {
        const siftValidatePaymentMethodUrl = '/paymentmethod/validatePaymentMethod';
        const form = ACC.updatePayment.collectUpdatePaymentMethodForm();

        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + siftValidatePaymentMethodUrl,
            data: form,
            async: false,
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        }).done((data) => {
            if (data.decision) {
                successCallback();
            } else {
                failValidationCallback(data);
            }
        });
    },

    updatePaymentAddress(onDoneFunction, onSuccessFunction, reloadOnError) {
        if (ACC.config.billingAddressUrl === null || ACC.config.billingAddressUrl === '') {
            return;
        }

        const form = ACC.updatePayment.collectUpdatePaymentAddressForm();

        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + ACC.config.billingAddressUrl,
            data: form,
            dataType: 'html',
            async: false,
            success: onSuccessFunction,
            error(xhr, status, error) {
                console.error(error.message);
                if (reloadOnError) {
                    window.location.reload();
                }
            },
        }).done(onDoneFunction);
    },

    // Visa Checkout handlers
    vcSuccess(payment) {
        const tmpl = `
            <form id="visaCheckoutPlaceOrder" action="${ACC.config.encodedContextPath}/checkout/payment/vc/success"
            method="post">
                <input type="hidden" name="callId" value="${payment.callid}" />
                <input type="hidden" name="CSRFToken" value="${ACC.config.CSRFToken}" />
            </form>
        `;
        $('.checkout-paymentmethod').after(tmpl);
        $('#visaCheckoutPlaceOrder').submit();
    },

    vcCancel() {
        window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview`;
    },

    vcError() {
        window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview/payment/error`;
    },

    attachBillingAddressForm() {
        $('#billingAddressFormContainer').on('change', '#billingCountrySelector :input', function () {
            const countrySelection = $(this).val();
            const options = {
                countryIsoCode: countrySelection,
                useDeliveryAddress: false,
            };
            $('#billingCountrySelector .form-group').removeClass('has-error');
            $('#billingCountrySelector .form-group .help-block').hide();
            ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.updatePayment.validateForm);
        });
    },

    bindAddressTrim() {
        const component = $('#addressForm');
        if (!component.length) return;
        component.submit(() => {
            ACC.updatePayment.performTrimBeforeSubmit(component);
        });
    },

    showErrorMessage(messageKey) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    useDeliveryAddress() {
        return $('#useDeliveryAddress').prop('checked');
    },

    addCvvHandler() {
        // Allow type only numbers and backspace for cvv input
        const cvvNumbers = document.querySelectorAll('input[name=card_cvNumber]');
        cvvNumbers.forEach((cvv) => {
            cvv.addEventListener('keypress', (e) => {
                // 0 for null values
                const isNotNull = e.which !== 0;

                // 8 for backspace
                const isNotBackspace = e.which !== 8;

                // 48-57 for 0-9 numbers
                const isNotNumber = e.which < 48 || e.which > 57;

                if ((isNotNull && isNotBackspace && isNotNumber) || e.target.value.length === 4) {
                    e.preventDefault();
                }
            });
        });
    },

    getSopForm() {
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            return $('#sopRequestForm');
        }
        const $sopRequestIframe = $('#sopRequestIframe');
        return $sopRequestIframe.contents().find('#sopRequestForm');
    },

    loadSopFormFragment() {
        $.ajax({
            type: 'GET',
            url: `${ACC.config.encodedContextPath}${ACC.config.sopFragmentUrl}`
            + `?subscriptionNumber=${ACC.config.currentSubscriptionNumber}`,
            sync: true,
            success(data) {
                $('#sopDirectCyberSourceBox').html(data);
            },
            error(xhr, status, error) {
                console.error(error.message);
                const messageKey = 'account.error.generic';
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                ACC.buttonhelper.enable('.place-order-form #updatePaymentBtn');
            },
        });
    },

    validateInput($input) {
        if ($input.is('select')) {
            return $input.val();
        }
        return !!$input.val().length;
    },

    isValidCardData(shouldValidateCardType = true) {
        let isValid = true;

        if (shouldValidateCardType) {
            const fieldsToValidate = [
                { value: ACC.updatePayment.getCardType(), ctrl: '#card_cardType' },
                { value: ACC.updatePayment.getCardNumber(), ctrl: '#card_accountNumber' },
                { value: ACC.updatePayment.getCardExpireYear(), ctrl: '#ExpiryYear' },
                { value: ACC.updatePayment.getCardExpireMonth(), ctrl: '#ExpiryMonth' },
                { value: ACC.updatePayment.getCardCvnNumber(), ctrl: '#card_cvNumber' },
            ];

            fieldsToValidate.forEach((field) => {
                ACC.updatePayment.hide($(field.ctrl));
                if (!field.value) {
                    ACC.updatePayment.show($(field.ctrl), ACC.updatePayment.getErrorMessage($(field.ctrl)));
                    ACC.updatePayment.scrollToFirstInvalidField();
                    isValid = false;
                }
            });
        }

        return isValid;
    },

    show(control, message) {
        ACC.updatePayment.getOrCreateErrorContainer(control, message).removeClass('collapse');
        control.closest('.form-group').addClass('has-error');
    },

    hide(control) {
        control.next('.help-block').remove();
        control.closest('.form-group').removeClass('has-error');
    },

    getErrorMessage(control) {
        return control.closest('[data-error-msg]').data('error-msg');
    },

    getOrCreateErrorContainer(forControl, message) {
        const sibling = forControl.next('.help-block');
        if (sibling.length !== 0) {
            return sibling;
        }
        if (message != null) {
            forControl.after(`<div class="help-block">${message}</div>`);
        }
        return forControl.next('.help-block');
    },

    validateCardType() {
        if ($('#card_accountNumber').length && $('#card_cardType').val() === 'invalid') {
            if (ACC.config.showInvalidCardBannerMessage === 'true') {
                ACC.updatePayment.showErrorMessage('checkout.multi.card.type.invalid.message');
            } else {
                ACC.updatePayment.show($('#card_accountNumber'), ACC.config.cardTypeNotSupportedMessage);
            }
            return false;
        }
        return true;
    },

    hideSecondErrorMessage() {
        document.querySelectorAll('.input').forEach((input) => {
            input.addEventListener('blur', (e) => {
                const errorBlock = e.target.closest('.has-error');
                if (errorBlock && errorBlock.querySelectorAll('.help-block').length > 1) {
                    errorBlock.querySelectorAll('.help-block')[1].remove();
                }
            });
        });
    },

    getCardType() {
        return $('#card_cardType option:selected').val() || $('#card_cardType').val();
    },

    getCardNumber() {
        return $('#card_accountNumber').val().replace(/\s/g, '');
    },

    getCardCvnNumber() {
        return $('#card_cvNumber').val();
    },

    getCardExpireDate() {
        return `${$('#ExpiryMonth option:selected').text()}-${$('#ExpiryYear option:selected').text()}`;
    },

    getCardExpireYear() {
        return $('#ExpiryYear option:selected').val();
    },

    getCardExpireMonth() {
        return $('#ExpiryMonth option:selected').val();
    },

    autoSelectPaypal() {
        const paypalOption = document.querySelector('.paypal-validation');

        if (paypalOption) {
            paypalOption.click();
            ACC.updatePayment.validatePaypalSubmit();
        }
    },

    validatePaypalSubmit() {
        const billingAddressInvalid = document.querySelector('#incorrectBillingAddressAnchor');
        const savePaypalButton = document.querySelector('#subscriptionBillingAddressValidationBtn');

        if (billingAddressInvalid) {
            savePaypalButton.classList.add('button-disabled');
            savePaypalButton.setAttribute('aria-disabled', true);

            savePaypalButton.addEventListener('click', () => {
                ACC.buttonhelper.ariaDisable(savePaypalButton).withSpinner();
            });
        }

        const addressFields = ACC.updatePayment.requiredInputFields.slice(0, 5);

        addressFields.forEach((selector) => {
            document.querySelectorAll(`#incorrectBillingAddressAnchor ${selector}`).forEach((element) => {
                element.addEventListener('input', () => {
                    if (savePaypalButton) {
                        savePaypalButton.classList.remove('button-disabled');
                        savePaypalButton.removeAttribute('aria-disabled');
                    }
                });
            });
        });
    },
};
