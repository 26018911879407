ACC.pausedSubscription = {

    _autoload: [
        ['showPausedSubscriptionModal', $('.js-popup_pausing_subscription').length !== 0],
        ['triggerSubscriptionModal', $('.popup_pausing_subscription').length !== 0],
        ['switchAlertMessage', $('.page-accountSubscriptionsLayout').length !== 0],
    ],

    showPausedSubscriptionModal() {
        const targetContainer = $('#popup_pausing_subscription');
        const template = $('#pausingSubscriptionTemplate').html();

        $modal.open(null, {
            escKey: false,
            overlayClose: false,
            html: template,
            keyboardInteraction: false,
            onOpen() {
                if (targetContainer.children().length === 0) {
                    targetContainer.html(template);
                }
            },
            onClosed() {
                ACC.accessibilityHelpers.showContent();
                ACC.pausedSubscription.toggleWarningMessageVisibility();
            },
        });
    },

    toggleWarningMessageVisibility() {
        const messageWarning = $('.js-global-alerts-container .alert-warning-message');
        const isHidden = 'isHiddenAlert';
        const animationClass = 'alert-warning-animation';
        const animDur = 800;

        if (messageWarning.length === 0) {
            return;
        }

        ACC.pausedSubscription.scrollTo();
        messageWarning.removeClass(isHidden);
        messageWarning.removeClass(animationClass);

        setTimeout(() => {
            messageWarning.addClass(animationClass);
        }, animDur);
    },

    triggerSubscriptionModal() {
        $('.js-paused-subscription').on('click', () => {
            ACC.pausedSubscription.showPausedSubscriptionModal();
        });
    },

    scrollTo() {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
    },

    switchAlertMessage() {
        const messageWarning = $('.js-global-alerts-container .alert-warning-message.isHiddenAlert');
        const messageSuccess = messageWarning.prev();
        const messageSuccessVisibility = 3000;
        const animDur = 800;

        if (messageWarning.length && messageSuccess.length) {
            const button = messageSuccess.find('.c-alert__close');
            button.css({
                display: 'none',
            });
            messageSuccess.addClass('alert-on-absolute');

            setTimeout(() => {
                $(button).closest('.getAccAlert').remove();
                messageWarning.removeClass('isHiddenAlert');

                setTimeout(() => {
                    messageWarning.addClass('alert-warning-animation');
                }, animDur);
            }, messageSuccessVisibility);
        }
    },
};
