ACC.institutionAutocomplete = {

    _autoload: [
        ['institutionAutocomplete', document.querySelector('#profile-institution-id')],
    ],

    institutionAutocomplete() {
        const institutionInputId = $('#profile-institution-id');
        const institutionInputName = $('#profile-institution-name');
        const institutionInput = $('#profile-institution-component');
        const inputResetBtn = $('.profile-institution-reset-btn');
        let institutionInputInitValue = institutionInput.val();
        const institutionUrl = institutionInput.data('institution-url');
        let noResultMessage;
        let errorMessage;
        let selectLabel;
        let inputCurrentValue;
        function institutionInputOnLoad(inputId, input) { // Fix for FF, on page reload
            input.prop('value', input.attr('value'));
            if (!input.prop('value')) {
                inputId.val('');
            }
        }

        institutionInputOnLoad(institutionInputId, institutionInput);

        function institutionInputStr(input, event) { // Check if input value is empty
            inputCurrentValue = $.trim(input.val());
            if (inputCurrentValue.length === 0) {
                event.preventDefault();
                return false;
            }
            const inputEncodedValue = encodeURIComponent(inputCurrentValue); // Encode input value before request
            return inputEncodedValue.replaceAll('-', '&#45;'); // Replace special symbol
        }

        institutionInput.autocomplete({
            source(request, response) {
                $.ajax({
                    dataType: 'json',
                    type: 'GET',
                    headers: { 'X-Failover-Testing': 'PMC_No_Fail_Over' },
                    // eslint-disable-next-line max-len
                    url: `${ACC.config.encodedContextPath}/${(institutionUrl) || 'my-account'}/institutions?institution=${institutionInputStr(institutionInput)}`,
                    success(data) {
                        if (!data.length) {
                            noResultMessage = ACC.institutionMismatchingError;
                            const result = [{ label: noResultMessage, value: response.term }];
                            institutionInput.autocomplete('option', 'autoFocus', false);
                            response(result);
                            const resultItems = $('.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper');
                            $(resultItems[0]).attr('role', 'alert');
                        } else {
                            institutionInput.autocomplete('option', 'autoFocus', false);
                            response($.map(data, (item) => ({
                                label: item.aggregateName,
                                value: item.id,
                            })));
                        }
                    },
                    error(jqXHR) {
                        errorMessage = jqXHR.responseJSON;
                        const result = [{ label: errorMessage, value: response.term }];
                        institutionInput.autocomplete('option', 'autoFocus', false);
                        response(result);
                        const resultItems = $('.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper');
                        $(resultItems[0]).attr('role', 'alert');
                    },
                });
            },
            appendTo: '.profile-institution-wrapper',
            delay: 500,
            minLength: 3,
            focus(event, ui) {
                event.preventDefault();
                let id;
                const { label } = ui.item;
                const items = $(this).siblings('.ui-autocomplete').find('.ui-menu-item');

                $.each(items, (i, li) => {
                    const text = $(li).text();
                    if (text === label) {
                        items.removeAttr('aria-selected');
                        id = $(li).attr('aria-selected', 'true').attr('id');
                    }
                });

                if (id) {
                    $(this).attr('aria-activedescendant', id);
                }

                return false;
            },
            select(event, ui) {
                selectLabel = ui.item.label;
                if (selectLabel === noResultMessage || selectLabel === errorMessage) {
                    event.preventDefault();
                    institutionInput.val(institutionInputInitValue);
                    inputResetBtn.removeClass('show');
                    return false;
                }
                institutionInputId.val(ui.item.value);
                institutionInputName.val(ui.item.label);
                institutionInput.val(selectLabel);
                return false;
            },
            create() {
                const listbox = $(this).siblings('.ui-autocomplete');
                const id = listbox.attr('id');

                listbox
                    .attr('role', 'listbox')
                    .attr('aria-label', 'Institutions');
                $(this)
                    .attr('aria-controls', id)
                    .attr('aria-expanded', 'false');
            },
            open() {
                const items = $(this).siblings('.ui-autocomplete').find('.ui-menu-item');
                $.each(items, (i, li) => {
                    const text = $(li).text();
                    const element = $(li).find('.ui-menu-item-wrapper');
                    const id = element.attr('id');

                    $(li)
                        .attr('role', 'option')
                        .attr('aria-label', text)
                        .attr('id', id);

                    element.removeAttr('id');
                });
                $(this).attr('aria-expanded', 'true');
            },
            close() {
                $(this).attr('aria-expanded', 'false');
            },
        });

        function addAttribute(input) {
            input.attr('autocomplete', 'organization');
        }

        addAttribute(institutionInput);

        function inputClear() { // Input clear value function
            institutionInput.val('');
            institutionInputId.val('');
            institutionInputInitValue = '';
        }

        function inputResetBtnShowing(input) { // Handler for showing input reset button
            input.on('input', function () {
                inputCurrentValue = $(this).val();
                if (inputCurrentValue) {
                    inputResetBtn.addClass('show');
                } else {
                    inputClear();
                    inputResetBtn.removeClass('show');
                }
            });
            input.focus(function () {
                inputCurrentValue = $(this).val();
                if (inputCurrentValue) {
                    inputResetBtn.addClass('show');
                } else {
                    inputResetBtn.removeClass('show');
                }
            });
        }

        inputResetBtnShowing(institutionInput);
        institutionInput.blur(function () { // Focus out handler for institution input field
            inputCurrentValue = $(this).val();
            if (inputCurrentValue !== institutionInputInitValue) { // Check if input value is changed
                // Check if autosudgest is available and no error case
                // Fix for reset button click on focus out
                inputResetBtn.removeClass('show');
                if (!!selectLabel && !(selectLabel === noResultMessage || selectLabel === errorMessage)) {
                    institutionInputInitValue = selectLabel; // Populate value from autosudgest in initial state
                    institutionInput.val(institutionInputInitValue); // Populate value from autosudgest in input value
                    inputResetBtn.addClass('show');
                    return;
                }
                // Check if input value not empty or changed, when wasn't chosen from autosudgest list
                if (!!inputCurrentValue && inputCurrentValue !== institutionInputInitValue) {
                    const institutionItems = $('.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper');
                    let isEqualValue;
                    let isBreak;
                    if (institutionItems.length) {
                        institutionItems.each((i, item) => {
                            if ($(item).text() === inputCurrentValue) {
                                isEqualValue = true;
                                isBreak = true;
                                item.click();
                                inputResetBtn.addClass('show');
                            } else if (!isBreak) {
                                institutionInput.val(institutionInputInitValue);
                                institutionInput.focus();
                            }
                        });
                    } else if (!isEqualValue) {
                        institutionInput.val(institutionInputInitValue); // Revert to initial input state
                        institutionInput.focus();
                    }
                } else {
                    inputClear(); // Clear input value on delete value
                    institutionInputInitValue = inputCurrentValue; // Update initial input state to empty value
                }
            }
        });

        function inputResetBtnClick(button) { // On click handler for input reset button
            button.click(() => {
                inputClear();
                button.removeClass('show');
            });
        }

        inputResetBtnClick(inputResetBtn);
    },
};
