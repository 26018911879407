ACC.globalmessages = {

    _autoload: [
        'showDelayedGlobalMessage',
    ],

    showDelayedGlobalMessage() {
        const globalMessageRequest = $.cookie('delayedGlobalMessage');
        if (globalMessageRequest) {
            ACC.globalmessages.createGlobalMessage(JSON.parse(globalMessageRequest));
        }
    },

    createGlobalMessage(globalMessageRequest, targetPage, isAsync = true) {
        $.ajax({
            async: isAsync,
            url: `${ACC.config.encodedContextPath}/global-messages`,
            type: 'POST',
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            data: JSON.stringify(globalMessageRequest),
            success(data) {
                // eslint-disable-next-line no-undef
                ACC.globalmessages.globalMessageResponse(DOMPurify.sanitize(data), targetPage);
            },
        });
    },

    globalMessageResponse(data, targetPage) {
        const wrapper = $('<div class="global-alerts_wrap"></div>').append(data);
        const form = document.querySelector('#pearsonLoginForm');

        if (targetPage) {
            $(`${targetPage} .global-alerts-container`).prepend(wrapper);
        } else {
            $('.global-alerts-container').prepend(wrapper);
        }
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        $('.closeAccAlert').on('click', function () {
            $(this).parents('.global-alerts_wrap').remove();
        });

        if (form) {
            const inputError = form.querySelector('input[type="text"]');
            inputError.focus();
        }
    },

    callErrorGlobalMessage(messageKey) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
            }],
        };
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    callErrorGlobalMessageWithAttributes(messageKey, attributes) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
                attributes: [attributes],
            }],
        };
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    removeGlobalMessages() {
        $('.closeAccAlert').click();
    },
};
