ACC.learnerlogin = {

    _autoload: [
        ['init', $('#loginFormHolder').length !== 0],
        ['bindSubmitLink', $('#pearsonLoginForm').length !== 0],
        ['handleSecondStepRegistration', $('#pearsonRegistrationSecondStepForm').length !== 0],
    ],
    options: {
        defaultForm: 'login',
        hiddenClassName: 'hidden',
        pageRegisterClassName: 'isRegister',
        showPasswordClassName: 'on',
        isRedeemLogin: ACC.shouldUseRedeemLogin,
        loginProcess: '',
        targetAlertPage: '',
        formParameter: 'form',
        registrationParameter: 'registration',
        successAction: 'success',
        failureAction: 'failure',
        titleLogin: 'Login | Pearson+',
        titleRegistration: 'Create account | Pearson+',
        isAsync: true,
    },

    objects: {
        loginMojoContainer: document.getElementsByClassName('login-mojo-container'),
        globalAlerts: document.getElementsByClassName('global-alerts'),
        registerMojoContainer: document.getElementsByClassName('register-mojo-container'),
        gotoButtons: document.getElementsByClassName('goto'),
        passwordIconButtons: document.getElementsByClassName('js-handle-password-button'),
        alertsContainer: document.getElementsByClassName('global-alerts-container'),
        loginPage: document.querySelector('.page-login'),
        progressiveSignIn: document.querySelector('.page-signin') || document.querySelector('.page-checkout-signin'),
    },

    init() {
        ACC.learnerlogin.handleFormOnInit();
        ACC.learnerlogin.bindRegistrationSubmitLink();
        ACC.learnerlogin.bindGoToButtons();
    },

    handleFormOnInit() {
        const form = ACC.learnerlogin.getSearchFormParam();
        ACC.learnerlogin.handleVisibleForms(form);
        localStorage.setItem('refererUrl', $('#loginReferer').val());
    },

    getSearchFormParam() {
        const opt = ACC.learnerlogin.options;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get('form') || opt.defaultForm;
    },

    handleVisibleForms(form) {
        const obj = ACC.learnerlogin.objects;
        const hidden = ACC.learnerlogin.options.hiddenClassName;
        const pageRegister = ACC.learnerlogin.options.pageRegisterClassName;
        const loginContainer = obj.loginMojoContainer;
        const registerContainer = obj.registerMojoContainer;
        const { body } = document;
        const registerTitle = document.querySelector('#registerFormTitle');

        switch (form) {
        case 'login':
            loginContainer[0].classList.remove(hidden);
            registerContainer[0].classList.add(hidden);
            body.classList.remove(pageRegister);
            ACC.learnerlogin.removeRegistrationParameter();
            ACC.learnerlogin.addPageTitle(ACC.learnerlogin.options.titleLogin);
            break;
        case 'registration':
            registerContainer[0].classList.remove(hidden);
            loginContainer[0].classList.add(hidden);
            body.classList.add(pageRegister);
            if (registerTitle) {
                registerTitle.focus();
            }
            ACC.learnerlogin.addRegistrationParameter();
            ACC.learnerlogin.addPageTitle(ACC.learnerlogin.options.titleRegistration);
            break;
        default:
            loginContainer[0].classList.remove(hidden);
            registerContainer[0].classList.add(hidden);
            body.classList.remove(pageRegister);
        }

        window.onpopstate = () => {
            const url = new URL(window.location.href);
            if (url.searchParams.has(ACC.learnerlogin.options.formParameter)) {
                loginContainer[0].classList.add(hidden);
                registerContainer[0].classList.remove(hidden);
            } else {
                loginContainer[0].classList.remove(hidden);
                registerContainer[0].classList.add(hidden);
            }
        };
    },

    addPageTitle(text) {
        document.title = text;
    },

    addRegistrationParameter() {
        const urlString = new URL(window.location.href);
        if (!urlString.searchParams.has(ACC.learnerlogin.options.formParameter)) {
            urlString.searchParams
                .append(ACC.learnerlogin.options.formParameter, ACC.learnerlogin.options.registrationParameter);
            window.history.pushState({}, '', urlString);
        }
    },

    removeRegistrationParameter() {
        const urlString = new URL(window.location.href);
        if (urlString.searchParams.has(ACC.learnerlogin.options.formParameter)) {
            urlString.searchParams.delete(ACC.learnerlogin.options.formParameter);
            window.history.pushState({}, '', urlString);
        }
    },

    bindGoToButtons() {
        const buttons = [].slice.call(ACC.learnerlogin.objects.gotoButtons);
        buttons.forEach((btn) => {
            btn.addEventListener('click', function () {
                const form = this.dataset.goto;
                ACC.learnerlogin.handleVisibleForms(form);
                $(window).scrollTop(0);
            });
        });
    },

    signIn(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        if (!piSession) {
            const messageHolder = 'accErrorMsgsInline';
            ACC.learnerlogin.callGlobalMessage('account.error.generic', messageHolder);
        }

        if (ACC.config.isSsoEnabled === 'true') {
            ACC.learnerlogin.signInWithSso(
                username,
                password,
                rememberMe,
                originalReferer,
                successMessage,
                orderGUID,
            );
        } else {
            ACC.learnerlogin.signInWithoutSso(
                username,
                password,
                rememberMe,
                originalReferer,
                successMessage,
                orderGUID,
            );
        }
    },

    signInWithSso(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        function onSuccess(jqXHR) {
            $.ajax({
                async: false,
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/login/setToken`,
                data: { username, password },
            });
            if (orderGUID) {
                $.ajax({
                    type: 'POST',
                    url: `${ACC.config.encodedContextPath}/login/order/link`,
                    data: { userId: jqXHR.data.userId, orderGUID },
                });
            }
            if (jqXHR.mock && piSession.Mock) {
                console.warn('Mock piSession usage');
                piSession.mockInitialize(jqXHR.data);
            }
        }

        function onError(jqXHR) {
            const form = document.querySelector('#pearsonLoginForm');
            const btn = form.querySelector('button[type="submit"]');
            ACC.learnerlogin.hideDisabledProcessingState(btn);
            let messageKey;
            let messageHolder;
            let attributes;
            if (document.querySelector('.login-mojo-container').classList
                .contains(ACC.learnerlogin.options.hiddenClassName)) {
                ACC.learnerlogin.options.targetAlertPage = '.register-mojo-container';
            } else {
                ACC.learnerlogin.options.targetAlertPage = '.login-mojo-container';
            }

            if (jqXHR.status === 401) {
                messageKey = `account.error.${jqXHR.responseJSON.fault.detail.errorcode}`;
                if (messageKey === 'account.error.piui.accountIsLocked') {
                    messageHolder = 'accErrorMsgsInline';
                    attributes = [`${ACC.config.encodedContextPath}/login/pw/reset`];
                } else if (messageKey === 'account.error.piui.authFailure') {
                    messageHolder = 'accErrorMsgsInline';
                } else {
                    messageHolder = 'accErrorMsgsInline';
                }
                ACC.learnerlogin.callGlobalMessage(messageKey, messageHolder, attributes);
            } else if (jqXHR.status === 403) {
                const escrowTicket = jqXHR.responseJSON.message;
                ACC.learnerconsents.showSsoAcceptConsents(
                    username,
                    password,
                    escrowTicket,
                    rememberMe,
                    originalReferer,
                    successMessage,
                    orderGUID,
                );
            } else {
                messageKey = 'account.error.generic';
                messageHolder = 'accErrorMsgsInline';
                ACC.learnerlogin.callGlobalMessage(messageKey, messageHolder);
            }
        }

        ACC.iamActions.triggerIamLoginFromEmbeddedPage(
            username,
            password,
            rememberMe,
            originalReferer,
            successMessage,
            onSuccess,
            onError,
        );
    },

    signInWithoutSso(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        const order = orderGUID || '';
        ACC.forms.createAndSubmitForm(`${ACC.config.encodedContextPath}/login/signin`, {
            username,
            password,
            rememberMe,
            loginReferer: originalReferer,
            successMessage,
            order,
            formURL: window.location.href,
            CSRFToken: ACC.config.CSRFToken,
        });
    },

    bindSubmitLink() {
        $(document).on('submit', '#pearsonLoginForm', function (e) {
            e.preventDefault();
            const btn = this.querySelector('button[type="submit"]');
            ACC.learnerlogin.showDisabledProcessingState(btn);
            ACC.learnerlogin.options.loginProcess = 'isLogin';

            ACC.globalmessages.removeGlobalMessages();

            const referer = $('#loginReferer').val() || document.referrer;

            ACC.learnerlogin.signIn(
                $('#username').val(),
                $('#password').val(),
                $('#rememberMeLogin').is(':checked'),
                ACC.learnerlogin.isAllowedReferer(referer) ? referer : '',
                '',
                $('#orderGUID').val(),
            );
        });
    },

    handleSecondStepRegistration() {
        $(document).on('submit', '#pearsonRegistrationSecondStepForm', function (e) {
            e.preventDefault();
            const form = $(this);
            const url = form.attr('action');
            const submitBtn = form[0].querySelector('button[type="submit"]');
            const customizeExperiencePageReferer = $('#customizeExperiencePageReferer').val();
            piSession.getToken((status, token) => {
                $('#userToken').val(token);
                $.ajax({
                    type: 'POST',
                    url,
                    data: form.serialize(),
                    beforeSend() {
                        ACC.learnerlogin.showDisabledProcessingState(submitBtn);
                    },
                    success() {
                        if (customizeExperiencePageReferer) {
                            window.location.href = customizeExperiencePageReferer;
                            return;
                        }

                        if (localStorage.getItem('refererUrl')) {
                            window.location.href = ACC.learnerlogin.getTargetUrl(localStorage.getItem('refererUrl'));
                        } else {
                            window.location.href = ACC.config.encodedContextPath;
                        }
                    },
                    error(jqXHR) {
                        if (jqXHR.responseJSON) {
                            ACC.learnerlogin.callGlobalMessage(jqXHR.responseJSON, 'accErrorMsgsInline');
                        } else {
                            ACC.learnerlogin.callGlobalMessage('account.error.generic', 'accErrorMsgsInline');
                        }
                        ACC.learnerlogin.hideDisabledProcessingState(submitBtn);
                    },
                });
            });
        });
    },

    getTargetUrl(refererUrl) {
        if (refererUrl.includes('/redeem')) {
            return ACC.config.homePageUrl;
        }
        return refererUrl;
    },

    handleRegistration(form, formData, uid, password, rememberMe, referer, successMessage) {
        const url = form.attr('action');
        const submitBtn = form[0].querySelector('button[type="submit"]');

        $.ajax({
            type: 'POST',
            url,
            data: formData, // serializes the form's elements.
            beforeSend() {
                ACC.learnerlogin.showDisabledProcessingState(submitBtn);
            },
            success() {
                ACC.learnerlogin.dataLayerMessages(ACC.learnerlogin.options.successAction);
                ACC.learnerlogin.signIn(uid, password, rememberMe, referer, successMessage);
            },
            error(jqXHR) {
                console.log(jqXHR);
                ACC.learnerlogin.hideDisabledProcessingState(submitBtn);
                let messageHolder;
                ACC.learnerlogin.options.isAsync = false;
                if (document.querySelector('.login-mojo-container').classList
                    .contains(ACC.learnerlogin.options.hiddenClassName)) {
                    ACC.learnerlogin.options.targetAlertPage = '.register-mojo-container';
                } else {
                    ACC.learnerlogin.options.targetAlertPage = '.login-mojo-container';
                }

                if (jqXHR.responseJSON) {
                    if (jqXHR.responseJSON === 'register.user.exists.error'
                        || jqXHR.responseJSON === 'register.email.exists.error') {
                        $('#username').val(uid);
                        messageHolder = 'accErrorMsgsInline';
                        ACC.learnerlogin.callGlobalMessage('register.profiling.user.exists.error', messageHolder);
                    } else {
                        ACC.learnerlogin.showDefaultAccountErrorMessage();
                    }
                } else {
                    ACC.learnerlogin.showDefaultErrorMessage();
                }
                ACC.learnerlogin.dataLayerMessages(ACC.learnerlogin.options.failureAction);
                if ($('[data-recaptcha-control="getRegisterInvisibleRecaptcha"]').length) {
                    grecaptcha.reset();
                }
            },
        });
    },

    showDefaultErrorMessage() {
        $.ajax({
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/customer/current/loginstatus`,
        }).always((data, textStatus, jqXHR) => {
            const hybrisSessionIsAlive = jqXHR.status !== undefined && jqXHR.status !== 401;
            if (hybrisSessionIsAlive) {
                ACC.learnerlogin.callGlobalMessage('user.already.authenticated.error', 'accErrorMsgsInline');
            } else {
                ACC.learnerlogin.showDefaultAccountErrorMessage();
            }
        });
    },

    showDefaultAccountErrorMessage() {
        const messageHolder = 'accErrorMsgsInline';
        ACC.learnerlogin.callGlobalMessage('account.error.generic', messageHolder);
    },

    bindRegistrationSubmitLink() {
        $(document).on('submit', '#pearsonRegistrationForm', (e) => {
            e.preventDefault(); // avoid to execute the actual submit of the form.
            ACC.learnerlogin.options.loginProcess = 'isRegistration';

            if ($('[data-recaptcha-control="getRegisterInvisibleRecaptcha"]').length) {
                grecaptcha.execute(); // invoke the reCAPTCHA check.
            } else {
                ACC.learnerlogin.callHandleRegistration();
            }
        });
    },

    callHandleRegistration() {
        const form = $('#pearsonRegistrationForm');

        ACC.globalmessages.removeGlobalMessages();

        const referer = ACC.config.registrationSecondStep;

        ACC.learnerlogin.handleRegistration(
            form,
            form.serialize(),
            $('#userID').val(),
            $('#passwordID').val(),
            $('#rememberMeRegistration').is(':checked'),
            referer,
            '',
            $(),
        );
    },

    callGlobalMessage(messageKey, messageHolder, attributes) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder,
                messages: [messageKey],
                attributes,
            }],
        };
        ACC.globalmessages.createGlobalMessage(
            globalMessageRequest,
            ACC.learnerlogin.options.targetAlertPage,
            ACC.learnerlogin.options.isAsync,
        );
    },

    handleInputType() {
        const buttons = [].slice.call(ACC.learnerlogin.objects.passwordIconButtons);
        buttons.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                ACC.learnerlogin.togglePasswordIcon(e.target);
                ACC.learnerlogin.togglePasswordInputType(e.target);
            });
        });
    },

    togglePasswordIcon(target) {
        const opt = ACC.learnerlogin.options;
        const hasClassShow = target.classList.contains(opt.showPasswordClassName);

        if (hasClassShow) {
            target.classList.remove(opt.showPasswordClassName);
        } else {
            target.classList.add(opt.showPasswordClassName);
        }
    },

    togglePasswordInputType(target) {
        const opt = ACC.learnerlogin.options;
        const isShow = target.classList.contains(opt.showPasswordClassName);
        const input = target.parentElement.getElementsByClassName('input')[0];
        const isAriaPressed = target.getAttribute('aria-pressed') === 'true';
        if (input) {
            target.setAttribute('aria-pressed', isAriaPressed ? 'false' : 'true');
            input.setAttribute('type', isShow ? 'text' : 'password');
        }
    },

    showDisabledProcessingState(btn) {
        const shouldUseRedeemLogin = ACC.learnerlogin.options.isRedeemLogin;

        if (shouldUseRedeemLogin === 'true') {
            ACC.buttonhelper.disable(btn).withSpinner();
        } else {
            btn.setAttribute('disabled', 'disabled');
        }
    },

    hideDisabledProcessingState(btn) {
        const shouldUseRedeemLogin = ACC.learnerlogin.options.isRedeemLogin;

        if (shouldUseRedeemLogin === 'true') {
            ACC.buttonhelper.enable(btn);
        } else {
            btn.removeAttribute('disabled');
        }
    },

    dataLayerMessages(status) {
        const alertMessage = document.querySelector('.c-alert__text');
        let reason = '';
        if (alertMessage) {
            reason = alertMessage.innerText;
        }
        dataLayer.push({
            event: 'registrationResult',
            status,
            reason,
        });
    },

    isAllowedReferer(referer) {
        return referer !== ACC.config.unauthenticatedHomePageUrl
        && !referer.includes(ACC.config.reedeemPageUrl);
    },

};

// eslint-disable-next-line no-unused-vars
const recaptchBindRegistrationSubmitCallback = () => {
    ACC.learnerlogin.callHandleRegistration();
};
