ACC.paymentHistory = {
    _autoload: [
        ['init', document.getElementById('accountPaymentHistory')],
    ],
    options: {
        buttonText: {
            show: 'Show history',
            hide: 'Hide history',
        },
        expandedClass: 'expanded',
        activePageClass: 'isActive',
        template: {
            head: 'payment-history__head',
            body: 'payment-history__body',
            footer: 'payment-history__footer',
            row: 'payment-history__row',
        },
        requestURL: '/store/en-us/pearsonplus/billing/history',
        defaultPage: '0',
        wrapperHeight: null,
        speed: 300,
        errorMsg: 'account.billing.payment.history.error',
    },
    selectors: {
        wrapperId: '#wrapperPaymentHistory',
        spinner: '.page-spinner',
    },
    objects: {
        toggleButton: document.getElementById('toggleCollapse'),
        historyWrapper: document.getElementById('wrapperPaymentHistory'),
        spinner: document.querySelector('.page-spinner'),
        accountHistoryWrapper: document.getElementById('accountPaymentHistory'),
    },
    paginationInstance: null,
    isFirstTimeLoad: true,
    monthsMap: {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December',
    },

    setFocusOnActivePage() {
        const that = ACC.paymentHistory;
        const pages = document.querySelectorAll(`${that.selectors.wrapperId} .c-paging-p__page`);

        pages.forEach((page) => {
            if (page.classList.contains(that.options.activePageClass)) {
                page.focus();
            }
        });
    },

    getPaymentHistory(pageNumber, firstTime) {
        const that = ACC.paymentHistory;
        const page = pageNumber || that.options.defaultPage;
        const subscriptionNumber = document.getElementById('subscription-number').value;
        const requestURL = `${that.options.requestURL}?page=${page}&subscriptionNumber=${subscriptionNumber}`;
        const xhr = new XMLHttpRequest();

        xhr.open('GET', requestURL);
        xhr.setRequestHeader('Content-Type', 'application/json');

        that.isFirstTimeLoad = firstTime || false;
        that.updateButtonCopy('hide');
        that.updateA11Attributes('hide');
        that.disableButton();

        if (that.isFirstTimeLoad) {
            that.setWrapperHeight(80);
        } else {
            that.setWrapperHeight(that.getWrapperHeight());
        }

        that.showSpinner();

        xhr.send();

        xhr.onload = function () {
            that.handleOnLoad(xhr);
        };

        xhr.onerror = function () {
            that.handleError(xhr);
        };
    },

    handleOnLoad(xhr) {
        const that = ACC.paymentHistory;

        if (xhr.status !== 200) {
            that.handleError(xhr);
        } else {
            const data = JSON.parse(xhr.response);

            that.enableButton();

            if (that.isFirstTimeLoad) {
                that.buildTemplatesOnLoad(data);
            } else {
                that.buildTemplatesOnUpdate(data);
            }

            that.hideSpinner();
        }
    },

    handleError() {
        const that = ACC.paymentHistory;
        that.hideSpinner();
        that.disableButton();
        that.destroy();
        ACC.globalmessages.callErrorGlobalMessage(that.options.errorMsg);
    },

    buildTemplatesOnLoad(data) {
        const that = ACC.paymentHistory;
        const billingHistoryItems = data.billingHistoryItems || [];
        const pageInfoData = data.pageInfoData || {};

        const initTemplates = [
            that.buildHeadTemplate(),
            that.buildBodyTemplate(billingHistoryItems),
        ];

        that.buildTemplates(initTemplates);

        if (pageInfoData.pageAmount > 1) {
            that.buildTemplates([that.buildFooterTemplate()]);
            that.buildPagination(pageInfoData);
        }

        that.showPaymentHistory();
        that.setWrapperHeight(that.getWrapperHeight());
    },

    buildTemplatesOnUpdate(data) {
        const that = ACC.paymentHistory;
        that.updateBodyTemplate(that.buildBodyTemplate(data.billingHistoryItems));
        that.paginationInstance.update(data.pageInfoData.pageNumber);
        that.setWrapperHeight(that.getWrapperHeight());
    },

    collapse(action) {
        const that = ACC.paymentHistory;
        const wrapper = that.objects.historyWrapper;
        if (action === 'expand') {
            ACC.collapse.expand(wrapper);
            that.setActiveClassToWrapper();
        }

        if (action === 'collapse') {
            ACC.collapse.collapse(wrapper);
            that.removeActiveClassFromWrapper();
        }
    },

    isHistoryExpanded() {
        const wrapper = ACC.paymentHistory.objects.accountHistoryWrapper;
        const { expandedClass } = ACC.paymentHistory.options;

        return wrapper.classList.contains(expandedClass);
    },

    showPaymentHistory() {
        const that = ACC.paymentHistory;
        that.collapse('expand');
        that.setActiveClassToWrapper();
        that.updateButtonCopy('hide');
        that.updateA11Attributes('hide');
    },

    hidePaymentHistory() {
        const that = ACC.paymentHistory;
        that.collapse('collapse');
        that.removeActiveClassFromWrapper();
        that.updateButtonCopy('show');
        that.updateA11Attributes('show');
    },

    setActiveClassToWrapper() {
        const that = ACC.paymentHistory;
        that.objects.accountHistoryWrapper.classList.add(that.options.expandedClass);
    },

    removeActiveClassFromWrapper() {
        const that = ACC.paymentHistory;
        that.objects.accountHistoryWrapper.classList.remove(that.options.expandedClass);
    },

    updateButtonCopy(action) {
        const that = ACC.paymentHistory;

        if (action === 'hide') {
            that.objects.toggleButton.innerText = that.options.buttonText.hide;
        } else {
            that.objects.toggleButton.innerText = that.options.buttonText.show;
        }
    },

    updateA11Attributes(action) {
        const button = ACC.paymentHistory.objects.toggleButton;
        const { buttonText } = ACC.paymentHistory.options;

        if (action === 'hide') {
            button.setAttribute('aria-label', buttonText.hide);
            button.setAttribute('aria-expanded', 'true');
        }

        if (action === 'show') {
            button.setAttribute('aria-label', buttonText.show);
            button.setAttribute('aria-expanded', 'false');
        }

        ACC.paymentHistory.setFocusOnButton();
    },

    setFocusOnButton() {
        const button = ACC.paymentHistory.objects.toggleButton;
        if (button) {
            button.focus();
        }
    },

    disableButton() {
        const button = ACC.paymentHistory.objects.toggleButton;
        button.setAttribute('disabled', 'true');
    },

    enableButton() {
        const button = ACC.paymentHistory.objects.toggleButton;
        button.removeAttribute('disabled');
    },

    setWrapperHeight(height) {
        const that = ACC.paymentHistory;
        that.objects.historyWrapper.style.height = `${height}px`;
        that.options.wrapperHeight = height;
    },

    getWrapperHeight() {
        const that = ACC.paymentHistory;
        const wrapper = document.querySelector(that.selectors.wrapperId);
        const children = [].slice.call(wrapper.children);
        let childrenHeight = 0;
        children.forEach(() => {
            childrenHeight = 'fit-content';
        });
        return childrenHeight;
    },

    buildTemplates(templates) {
        const that = ACC.paymentHistory;
        const fragment = document.createDocumentFragment();

        templates.forEach((template) => {
            fragment.appendChild(template);
        });

        that.insertTemplateToDOM(fragment, that.selectors.wrapperId);
    },

    updateBodyTemplate({ innerHTML }) {
        const that = ACC.paymentHistory;

        const body = that.objects.historyWrapper
            .querySelector(`.${that.options.template.body}`);

        body.innerHTML = innerHTML;
    },

    buildHeadTemplate() {
        const that = ACC.paymentHistory;
        const header = document.createElement('thead');
        const head = document.createElement('tr');

        header.classList.add(that.options.template.head);
        header.appendChild(head);

        if (document.getElementById('internalPayment')) {
            head.innerHTML = `
                <div class ='payment-history-internal'>
                    There is no payment history to show for internal customer when no payment option was selected.
                </div>
            `;
        } else {
            head.innerHTML = `
                <th class="payment-history__col">Payment date</th>
                <th class="payment-history__col">Description</th>
                <th class="payment-history__col">Amount</th>
            `;
        }
        return header;
    },

    buildBodyTemplate(data) {
        const that = ACC.paymentHistory;
        const body = document.createElement('tbody');
        body.classList.add(that.options.template.body);

        const getReadableDate = (date) => {
            const shortenMonth = date.substr(0, 3);
            return date.replace(shortenMonth, this.monthsMap[shortenMonth]);
        };

        const getReadableAmount = (amount) => {
            const splitAmount = amount.toString().split('.');

            if (splitAmount && splitAmount.length > 1) {
                const cents = Number(splitAmount[1]);
                return `${splitAmount.join(' dollars and ')}${cents === 1 ? ' cent' : ' cents'}`;
            }
            return `${amount} ${amount === 1 ? ' dollar' : ' dollars'}`;
        };

        data.forEach((item) => {
            const paymentData = item.paymentDate ? getReadableDate(item.paymentDate) : '';
            const amountData = item.amount ? getReadableAmount(item.amount) : '';
            const row = document.createElement('tr');
            row.classList.add('payment-history__row');

            row.innerHTML = `
                <th class="payment-history__col" data-payment="date">
                    <span class="sr-only">${paymentData}</span>
                    <span aria-hidden="true">${item.paymentDate || ''}</span>
                </th>
                <td class="payment-history__col">
                    <div class="payment-history__body__plan" data-payment="desc">${item.paymentDescription}</div>
                    <div class="payment-history__body__card" data-payment="card">${item.card}</div>
                </td>
                <td class="payment-history__col" data-payment="amount">
                    <span class="sr-only">${amountData}</span>
                    <span aria-hidden="true">${item.amountFormatted || ''}</span>
                </td>
            `;
            body.appendChild(row);
        });

        return body;
    },

    buildFooterTemplate() {
        const that = ACC.paymentHistory;
        const footer = document.createElement('tfoot');

        footer.classList.add(that.options.template.footer);

        return footer;
    },

    buildPagination(data) {
        const that = ACC.paymentHistory;

        // eslint-disable-next-line no-undef
        that.paginationInstance = new PearsonPaginationAjax({
            pageCount: data.pageAmount,
            itemsCount: data.itemsCount,
            pageNumber: data.pageNumber + 1,
            pageAmount: data.pageAmount,
            current: data.pageNumber,
            holderClass: that.options.template.footer,
            getData: that.getPaymentHistory,
        });
    },

    showSpinner() {
        const { spinner } = ACC.paymentHistory.objects;
        if (spinner) {
            spinner.classList.add('loading');
        }
    },

    hideSpinner() {
        const that = ACC.paymentHistory;
        const { spinner } = that.objects;
        if (spinner) {
            spinner.classList.remove('loading');
        }
    },

    _createWrapperTemplate(selector) {
        const wrapper = document.createElement('div');
        wrapper.classList.add(selector);
        return wrapper;
    },

    insertTemplateToDOM(template, selector) {
        const holder = document.querySelector(selector);
        holder.appendChild(template);
    },

    debounce(f, ms) {
        let isCooldown = false;
        let timerId;

        return function (...args) {
            if (isCooldown) {
                return;
            }

            f.apply(this, args);

            isCooldown = true;

            timerId = setTimeout(() => {
                clearTimeout(timerId);
                isCooldown = false;
                return isCooldown;
            }, ms);
        };
    },

    destroy() {
        const that = ACC.paymentHistory;
        that.hidePaymentHistory();

        const timerID = setTimeout(() => {
            that.objects.historyWrapper.innerHTML = '';
            clearTimeout(timerID);
        }, that.options.speed); // collaps transtion speed
    },

    handleShowActionClick() {
        const that = ACC.paymentHistory;
        const { accountHistoryWrapper } = that.objects;
        const isExpanded = accountHistoryWrapper.classList.contains(that.options.expandedClass);

        if (isExpanded) {
            that.destroy();
        } else {
            that.getPaymentHistory(that.options.defaultPage, true);
        }
    },

    handleResize() {
        const that = ACC.paymentHistory;
        const newWrapperHeight = that.getWrapperHeight();
        const isWrapperHeightChanged = that.options.wrapperHeight !== newWrapperHeight;

        if (that.isHistoryExpanded() && isWrapperHeightChanged) {
            that.setWrapperHeight(newWrapperHeight);
        }
    },

    bind() {
        const that = ACC.paymentHistory;
        const button = that.objects.toggleButton;
        const toggleActionWithDebounce = that.debounce(that.handleShowActionClick, that.options.speed);

        button.addEventListener('click', toggleActionWithDebounce);

        window.addEventListener('resize', that.handleResize);
    },

    init() {
        const that = ACC.paymentHistory;
        if (document.getElementById('internalPayment')) {
            that.handleShowActionClick();
        }
        that.bind();
    },
};
