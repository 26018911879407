ACC.common = {

    currentCurrency: $('main').data('currencyIsoCode') || 'USD',
    processingMessage: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif'/>`),

    blockFormAndShowProcessingMessage(submitButton) {
        const form = submitButton.parents('form:first');
        form.block({ message: ACC.common.processingMessage });
    },

    refreshScreenReaderBuffer() {
        // changes a value in a hidden form field in order
        // to trigger a buffer update in a screen reader
        $('#accesibility_refreshScreenReaderBufferField').attr('value', new Date().getTime());
    },

    checkAuthenticationStatusBeforeAction(actionCallback) {
        $.ajax({
            url: ACC.config.authenticationStatusUrl,
            statusCode: {
                401() {
                    window.location.href = ACC.config.loginUrl;
                },
            },
            success(data) {
                if (data === 'authenticated') {
                    if (typeof actionCallback === 'function') { actionCallback(); }
                }
            },
        });
    },

};

/* Add a class to body if user is using keyboard to tab */

function handleFirstTab(e) {
    if (e.keyCode === 9) { // keyboard tab key
        document.body.classList.add('tab--user');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

/* Extend jquery with a postJSON method */
jQuery.extend({
    postJSON(url, data, callback) {
        return jQuery.post(url, data, callback, 'json');
    },
});

// add a CSRF request token to POST ajax request if its not available
$.ajaxPrefilter((options, originalOptions, jqXHR) => {
    // Modify options, control originalOptions, store jqXHR, etc
    if (options.type === 'post' || options.type === 'POST') {
        const noData = (typeof options.data === 'undefined');
        if (noData) {
            options.data = `CSRFToken=${ACC.config.CSRFToken}`;
        } else {
            const patt1 = /application\/json/i;
            if (options.data instanceof window.FormData) {
                options.data.append('CSRFToken', ACC.config.CSRFToken);
            } else if (patt1.test(options.contentType)) { // if its a json post, then append CSRF to the header.
                jqXHR.setRequestHeader('CSRFToken', ACC.config.CSRFToken);
            } else if (options.data.indexOf('CSRFToken') === -1) {
                options.data = `${options.data}&CSRFToken=${ACC.config.CSRFToken}`;
            }
        }
    }
});
