ACC.preferencesSPA = {

    _autoload: [
        ['init', document.getElementById('preference-wrapper')],
    ],

    wrapperId: 'preference-wrapper',
    init() {
        const wrapperElement = document.getElementById(ACC.preferencesSPA.wrapperId);
        const host = wrapperElement.getAttribute('data-env');
        const isMinor = wrapperElement.getAttribute('data-is-minor-user') === 'true';
        const props = {
            host,
            onSuccess: ACC.preferencesSPA.successHandler,
            onError: ACC.preferencesSPA.errorHandler,
            minorFlag: isMinor,
        };
        window.createPreferenceOptions(ACC.preferencesSPA.wrapperId, props);
    },

    successHandler() {
        const globalMessageRequestSuccess = {
            globalMessages: [{
                messageHolder: 'accConfMsgs',
                messages: ['text.preferences.successes'],
            }],
        };

        ACC.globalmessages.createGlobalMessage(globalMessageRequestSuccess);
    },

    errorHandler() {
        const globalMessageRequestError = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: ['text.preferences.error'],
            }],
        };

        ACC.globalmessages.createGlobalMessage(globalMessageRequestError);
    },
};
