ACC.subscriptionHelpers = {

    params: {},
    urlParam: null,
    lastLoadedPromo: null,

    getParams(element) {
        const params = JSON.stringify({
            subscriptionCode: element.dataset.mojoSubscriptionTier,
            existingSubscriptionCode: element.dataset.subscriptionNumber,
            productIsbn13: element.dataset.productIsbn13,
        });
        const data = JSON.parse(params);
        ACC.subscriptionHelpers.params = data;
        return data;
    },

    getUrlParam() {
        const queryString = window.location.search;
        ACC.subscriptionHelpers.urlParam = new URLSearchParams(queryString);

        return ACC.subscriptionHelpers.urlParam;
    },

    deleteUrlParam(urlParams, nameParam) {
        urlParams.delete(nameParam);
        const urlSearch = urlParams.toString() ? `?${urlParams.toString()}` : '';
        const host = window.location.href.split('?')[0];
        window.history.replaceState({}, '', `${host}${urlSearch}`);
    },

    validateVoucherCodeTextInput() {
        $('#js-voucher-code-text, #js-voucher-code-text-renew').keyup(function () {
            const emptyInput = $(this).val().length === 0;

            if (emptyInput) {
                ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(true);
            } else {
                ACC.subscriptionHelpers.handleVoucherApplyActionDisabledState(false);
            }
        });
    },

    handleVoucherApplyActionDisabledState(disabledState) {
        const applyBtn = $('.c-cart-voucher__btn');
        const disabled = 'disabled';

        if (!applyBtn.length) {
            return;
        }

        if (disabledState) {
            applyBtn.attr(disabled, disabled);
        } else {
            applyBtn.removeAttr(disabled);
        }
    },

    handleVoucherRemoveActionDisabledState(disabledState) {
        const applyBtn = $('.c-cart-voucher__btn');
        const cancel = $('.js-release-voucher-remove-btn');
        const disabled = 'disabled';

        if (!applyBtn.length) {
            return;
        }

        if (cancel) {
            if (disabledState) {
                cancel.attr(disabled, disabled);
            } else {
                cancel.removeAttr(disabled);
            }
        }
    },
};
