ACC.customlogin = {

    doCustomLogin(originalReferer, successMessage, rememberMe, defaultLogin) { // eslint-disable-line
        const { shouldUseRedeemLogin } = ACC;
        const { encodedContextPath } = ACC.config;

        function onRedeemLogin() {
            piSession.getToken((status, token) => {
                $.ajax({
                    url: `${encodedContextPath}/redeemCode`,
                    type: 'POST',
                    headers: {
                        'X-UserId': piSession.userId(),
                        'X-Authorization': token,
                    },
                    success(orderCode) {
                        if (orderCode) {
                            window.location = `${encodedContextPath}/checkout/orderConfirmation/${orderCode}`;
                        }
                    },
                    error(jqXHR) {
                        if (jqXHR.responseJSON) {
                            window.location = encodedContextPath + jqXHR.responseJSON;
                        } else {
                            console.error('Unexpected code redemption error');
                        }
                    },
                });
            });
        }

        if (shouldUseRedeemLogin === 'true') {
            onRedeemLogin();
            return;
        }

        defaultLogin(ACC.customlogin.getTargetUrl(originalReferer), successMessage, rememberMe);
    },

    getTargetUrl(originalReferer) {
        if (originalReferer === '' || originalReferer.includes('/redeem')) {
            return ACC.config.homePageUrl;
        }
        return originalReferer;
    },

    triggerUpcConsentPageAndLogin(onDone) {
        if (ACC.upcConsents) {
            ACC.upcConsents.showUpcMarketingConsents(onDone);
        } else {
            onDone();
        }
    },
};
