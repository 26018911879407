ACC.unifiedWalletSorting = {
    _autoload: [
        [document.querySelector('.account-section-content__profile.billing-info')
        && document.querySelector('.account-section-content__profile.billing-info').length !== 0],
    ],

    getSavedCards: (data) => {
        const cards = data.slice();
        ACC.unifiedWalletSorting.processSavedCardExpiration(cards);
        return cards.filter((item) => !item.expired);
    },

    isPayPalPayment: (item) => item.paymentType && item.paymentType.toUpperCase() === 'PayPal'.toUpperCase(),

    processSavedCardExpiration: (cards) => {
        cards.forEach((item) => {
            const today = new Date();
            const expiryDate = new Date();
            expiryDate.setFullYear(+item.validToYear, +item.validToMonth, 1);
            item.expired = !(expiryDate > today || ACC.unifiedWalletSorting.isPayPalPayment(item));
        });
    },

    filterSavedPaymentCards: (cards, isPreferred) => {
        const isUpdatePaymentPage = document.querySelector('.page-update-payment-method');

        const filteredCards = cards.filter((item) => item.paymentType !== 'PayPal'
            && item.preferredPayment === isPreferred)
            .filter(ACC.unifiedWalletSorting.filterCardForCurrentCountry);

        if (isUpdatePaymentPage) {
            return filteredCards.filter((item) => !item.externalProducts.length);
        }
        // eslint-disable-next-line no-prototype-builtins
        return filteredCards.filter((item) => item.hasOwnProperty('preferredPayment'));
    },

    filterCardForCurrentCountry(item) {
        const currentCountryIso = document.querySelector('#placeHolderCurrentCountryIso');

        if (!currentCountryIso) {
            return true;
        }

        return item.billingAddress !== null && item.billingAddress.countryISO === currentCountryIso.innerHTML.trim();
    },
};
