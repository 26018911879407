ACC.additionalCustomerInfo = {

    _autoload: [
        ['bindRoleValueChange', document.querySelector('#studyLevel') && document.querySelector('#dobID')],
    ],

    educatorRole: false,

    bindRoleValueChange() {
        const roleFields = document.querySelectorAll('.js-study-level-select');

        roleFields.forEach((element) => {
            const currentForm = element.closest('form');
            const dateOfBirthGroup = currentForm.querySelector('.js-user-date-of-birth');
            const profileInstitution = currentForm.querySelector('.js-profile-institution');
            if (!dateOfBirthGroup) {
                return;
            }
            const dateOfBirthField = dateOfBirthGroup.querySelector('.js-age-getting-field');

            element.addEventListener('change', (e) => {
                if (e.target.selectedOptions[0].getAttribute('data-is-educator') === 'true') {
                    ACC.additionalCustomerInfo.educatorRole = true;
                    if (currentForm.id === 'pearsonRegistrationForm') {
                        ACC.additionalCustomerInfo.handleDateOfBirthVisibility({
                            formGroup: dateOfBirthGroup,
                            inputField: dateOfBirthField,
                            visibility: false,
                        });
                        ACC.additionalCustomerInfo.triggerChangeEvent(dateOfBirthField);
                    } else {
                        ACC.additionalCustomerInfo.handleDateOfBirthVisibility({
                            formGroup: dateOfBirthGroup,
                            inputField: dateOfBirthField,
                            visibility: false,
                        });
                    }
                } else {
                    ACC.additionalCustomerInfo.educatorRole = false;
                    ACC.additionalCustomerInfo.handleDateOfBirthVisibility({
                        formGroup: dateOfBirthGroup,
                        inputField: dateOfBirthField,
                        institutionGroup: profileInstitution,
                        visibility: true,
                    });
                }
            });
        });
    },

    handleDateOfBirthVisibility(data) {
        const {
            formGroup, inputField, institutionGroup, visibility,
        } = data;
        const monthField = document.querySelector('#dobMonthID');
        const yearField = document.querySelector('#dobYearID');

        if (visibility) {
            inputField.setAttribute('aria-required', 'true');
            if (monthField && yearField) {
                monthField.removeAttribute('disabled');
                yearField.removeAttribute('disabled');
            }
            inputField.removeAttribute('disabled');
            formGroup.classList.remove('display-none');
            if (institutionGroup) {
                institutionGroup.classList.remove('display-none');
            }
        } else {
            if (monthField && yearField) {
                ACC.additionalCustomerInfo.disableHiddenInputs(monthField);
                ACC.additionalCustomerInfo.disableHiddenInputs(yearField);
            }
            ACC.additionalCustomerInfo.disableHiddenInputs(inputField);
            formGroup.classList.remove('has-error');
            formGroup.classList.add('display-none');
        }
    },

    disableHiddenInputs(input) {
        input.setAttribute('disabled', 'disabled');
        input.setAttribute('aria-required', 'false');
        input.removeAttribute('aria-invalid');
    },

    triggerChangeEvent(input) {
        input.dispatchEvent(new Event('input'));
    },
};
