ACC.bundleABTesting = {
    _autoload: ['init'],

    init() {
        if (ACC.config?.isABTestingScriptEnabled) {
            ACC.bundleABTesting.handleBundles();
        }
    },

    handleBundles() {
        const { handleABFlag } = ACC.abtesting;
        const { hideBundleProduct, showBundleProduct } = ACC.bundleABTesting;

        handleABFlag('bundles', (value) => {
            console.info(`AB Flag name: 'bundles' changed to ${value}`);
            value ? hideBundleProduct() : showBundleProduct();
        });
    },

    hideBundleProduct() {
        ACC.bundleABTesting.toggleBodyClassAndVisibility('ab-testing-bundles', 'hide');
    },

    showBundleProduct() {
        ACC.bundleABTesting.toggleBodyClassAndVisibility('ab-testing-bundles', 'show');
    },

    /**
     * @param {string} className
     * @param {string} visibility - 'hide' or 'show'
     */
    toggleBodyClassAndVisibility(className, visibility) {
        document.body.classList.toggle(className, visibility === 'hide');
        ACC.bundleABTesting.handleVisibilityBundle(visibility);
    },

    /**
     * @param {string} visibility - 'hide' or 'show'
     */
    handleVisibilityBundle(visibility) {
        const items = document.querySelectorAll('[data-abtesting-bundles]');

        if (!items) return;

        items.forEach((item) => {
            item.style.display = item.dataset.abtestingBundles === (visibility === 'hide').toString() ? 'grid' : 'none';
        });
    },
};
