(function (root, factory) {
    const pluginName = 'ValidateAccessCode';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
    // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    /**
   * Default options
   */
    const defaults = {
        input: '',
        group: '',
        button: '',
        container: '',
    };

    /**
   * RegExp allow 24 characters(letters and numbers) divided by "-"
   */
    const REDEEM_CODE_REGEXP = /^([A-Z\d]{4}-){5}([A-Z\d]{4}){1}$/i;

    /**
   * Merge defaults with user options
   @param {Object} defaults Default settings
   @param {Object} options User options
   */
    // eslint-disable-next-line no-shadow
    const extend = function (defaults, options) {
        let prop;
        const extended = {};
        // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    const checkAccessCodeHasFormatError = function (value) {
        const hasError = !REDEEM_CODE_REGEXP.test(value);

        return hasError;
    };

    const toggleErrorLabelError = function (hasError, element) {
        element.classList.toggle('has-error', hasError);
    };

    const setAccessibility = function (hasError, element) {
        element.setAttribute('aria-invalid', hasError);
    };

    const runErrorAnnounce = (options) => {
        const { container, input } = options;
        const alert = container.querySelector('.getAccAlert .c-alert__text');

        if (!alert) {
            return;
        }

        window.onload = () => {
            const hintId = input.getAttribute('aria-describedby');
            alert.setAttribute('id', 'redeem-error-block');
            input.setAttribute('aria-describedby', `redeem-error-block ${hintId}`);

            setTimeout(() => {
                input.focus();
            }, 100);
        };
    };

    const runProgram = function (options) {
        const { input } = options;

        /**
     * 1) Split given value by the "-" symbol
     * 2) Join the result to get the string without "-" symbols
     * 3) Add a dash after each 4th symbol
     */
        const splitValueByDashes = (value) => {
            let formattedValue = value.split('-').join('');

            if (formattedValue.length > 0) {
                const matched = formattedValue.match(/.{1,4}/g);

                if (matched) {
                    formattedValue = matched.join('-');
                }
            }

            return formattedValue.toUpperCase();
        };

        // Need to preserve previous input value to be able
        // to correctly set cursor after each change
        let previousInputValue = input.value;

        input.addEventListener('input', ({ target }) => {
            if (input.checkValidity()) {
                previousInputValue = input.value;
            } else {
                input.value = previousInputValue;
            }

            const { value, selectionStart } = target;

            const isAddToTheEndOfInput = selectionStart === value.length;
            const isAddNewSymbol = value.length > previousInputValue.length;
            const isNextSymbolDash = value[selectionStart] === '-';

            const dashedValue = splitValueByDashes(value);

            input.value = dashedValue;
            previousInputValue = dashedValue;

            if (!isAddToTheEndOfInput) {
                const selectionPosition = isNextSymbolDash && isAddNewSymbol
                    ? selectionStart + 1
                    : selectionStart;

                target.selectionStart = selectionPosition;
                target.selectionEnd = selectionPosition;
            }

            const hasError = checkAccessCodeHasFormatError(input.value);

            if (!hasError) {
                toggleErrorLabelError(false, options.group);
                setAccessibility(false, input);
                ACC.buttonhelper.enable(options.button);
            }
        });

        options.button.addEventListener('click', (e) => {
            e.preventDefault();
            options.button.setAttribute('disabled', 'disabled');

            const hasError = checkAccessCodeHasFormatError(input.value);
            const { form } = e.target;

            if (hasError) {
                toggleErrorLabelError(checkAccessCodeHasFormatError(input.value), options.group);
                setAccessibility(true, input);
            } else {
                ACC.buttonhelper.disable(options.button).withSpinner();
                form.submit();
            }
        });
    };

    /**
   * DOM Elements validation
   @private
   @param {Object} options PearsonBreadcrumbs options
   @returns {Boolean} true if all required DOM elements are present
   */
    const checkForRequiredDOMElements = function (options) {
        if (!options.input) { return false; }
        if (!options.group) { return false; }
        if (!options.container) { return false; }

        return true;
    };

    /**
   * Plugin Object
   @param {Object} options User options
   @constructor
   */
    function Plugin(options) {
        this.options = extend(defaults, options);

        this.init(this.options);
    }

    /**
   * Plugin prototype
   @public
   @constructor
   */
    Plugin.prototype.init = function (options) {
        if (!checkForRequiredDOMElements(options)) { return; }

        runProgram(options);
        runErrorAnnounce(options);
    };

    return Plugin;
}));
