ACC.forgottenpassword = {

    _autoload: [
        ['bindSubmitButton', $('.js-forgottenPwd-submit').length !== 0],
        ['bindEmailChange', $('#forgottenPwdEmail').length !== 0],
        ['bindForgottenPwdSubmitForm', $('#forgottenPwdForm').length !== 0],
    ],

    captchaIsPresent: $('[data-recaptcha-control="getForgottenPwdInvisibleRecaptcha"]').length,
    progressiveResetPasswordPage: document.querySelector('.page-resetPasswordPage'),

    bindForgottenPwdSubmitForm() {
        const form = $('#forgottenPwdForm');
        form.submit(function () {
            if ($(this).valid()) {
                if (ACC.forgottenpassword.captchaIsPresent) {
                    grecaptcha.execute();
                } else {
                    ACC.forgottenpassword.handleSubmit();
                }
            }
            return false;
        });
    },

    recaptchaHandler() {
        const button = document.querySelector('.js-forgottenPwd-submit');
        ACC.buttonhelper.enable(button);
        document.removeEventListener('recaptchaLoaded', ACC.forgottenpassword.recaptchaHandler);
    },

    bindSubmitButton() {
        document.addEventListener('recaptchaLoaded', ACC.forgottenpassword.recaptchaHandler);
    },

    bindEmailChange() {
        $('#forgottenPwdEmail').on('keyup input', ACC.forgottenpassword.enableFormSubmit);
    },

    handleSubmit() {
        const form = $('#forgottenPwdForm');
        const submitBtn = $('.js-forgottenPwd-submit');
        const actionUrl = form.attr('action');
        const formData = form.serialize();
        const successRedirectUrl = form.data('success-redirect');

        $.post({
            url: actionUrl,
            data: formData,
            beforeSend() {
                ACC.buttonhelper.disable(submitBtn).withSpinner();
            },
            success() {
                window.location = successRedirectUrl;
            },
            error(error) {
                ACC.buttonhelper.enable(submitBtn);
                submitBtn.prop('disabled', true);

                if (ACC.forgottenpassword.progressiveResetPasswordPage) {
                    const messageHolder = 'accErrorMsgsInline';
                    const inputEmail = form.find('input[type="email"]');
                    ACC.learnerlogin.callGlobalMessage(error.responseJSON, messageHolder);
                    inputEmail.focus();
                } else {
                    form.validate().showErrors({
                        email: error.responseJSON,
                    });
                }

                if (ACC.forgottenpassword.captchaIsPresent) {
                    grecaptcha.reset();
                }
            },
        });
    },

    enableFormSubmit() {
        const form = $('#forgottenPwdForm');
        const submitBtn = $('.js-forgottenPwd-submit');
        submitBtn.prop('disabled', !form.valid());
    },
};

// eslint-disable-next-line no-unused-vars
const recaptchaforgottenPasswordSubmitCallback = () => {
    ACC.forgottenpassword.handleSubmit();
};

ACC.forgottenpassword.recaptchaforgottenPasswordSubmitCallback = recaptchaforgottenPasswordSubmitCallback;
